<h2 mat-dialog-title>Czy chcesz zatwierdź dokument z błędem?</h2>
<mat-dialog-content>
    <p>Na dokumencie zostały wykryte następujące błędy:</p>
    <ol>
        <li class="form-error" *ngFor="let error of data.errors">
            <ng-container *ngTemplateOutlet="error"></ng-container>
        </li>
    </ol>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button color="primary" [mat-dialog-close]="false" class="size-normal">Anuluj</button>
    <button mat-button color="primary" [mat-dialog-close]="true" class="size-normal">Zatwierdź</button>
</mat-dialog-actions>
