import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

    public form: FormGroup = new FormGroup({
        password: new FormControl('', [Validators.required]),
        repassword: new FormControl('', [Validators.required]),
    }, { validators: [this.samePasswords] });

    public get password(): FormControl {
        return this.form.get('password') as FormControl;
    }

    public get repassword(): FormControl {
        return this.form.get('repassword') as FormControl;
    }

    public email: string;
    public token: string;
    public isSubmitted = false;
    public isVeryfing = false;
    public isTokenValid = true;

    private queryParamsSubscription: Subscription;

    constructor(
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
    ) { }

    public ngOnInit(): void {

        this.titleService.setTitle(`Zmiana hasła | ${environment.applicationTabName}`);

        this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(params => {

            this.email = params.email;
            this.token = params.token;

            this.authService.verifyResetPasswordToken(this.email, this.token).subscribe(
                _ => {
                    this.isVeryfing = false;
                    this.isTokenValid = true;
                },
                _ => {
                    this.isVeryfing = false;
                    this.isTokenValid = false;
                },
            );
        });
    }

    public ngOnDestroy(): void {

        if (this.queryParamsSubscription) {
            this.queryParamsSubscription.unsubscribe();
        }
    }

    public samePasswords(formGroup: FormGroup) {

        const password = formGroup.controls.password.value;
        const repassword = formGroup.controls.repassword.value;
        return password === repassword ? null : { notSame: true };
    }

    public submit(): void {
        if (this.form.valid) {
            const password = this.password.value;

            this.authService.resetPassword(this.email, this.token, password).subscribe(
                _ => {
                    this.isSubmitted = true;
                }
            );
        }
    }
}
