import { Component, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
    selector: 'digica-table-reload',
    templateUrl: './table-reload.component.html',
    styleUrls: ['./table-reload.component.scss'],
    animations: [
        trigger('slide', [
            state('true', style({
                height: '56px',
                'border-bottom-width': '1px',
            })),
            state('false', style({
                height: '0',
                'border-bottom-width': '0',
            })),
            transition('* => *', [
                animate('0.5s cubic-bezier(.10, .10, .25, .90)')
            ]),
        ]),
    ],
})
export class TableReloadComponent {

    @Input()
    public show = false;

    @Input()
    public content = 'Dostępne są nowe elementy';

    @Output()
    public refresh = new EventEmitter<void>();

    public refreshClicked(): void {
        this.refresh.emit();
    }
}
