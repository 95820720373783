<form [formGroup]="formGroup">

    <mat-divider></mat-divider>

    <div class="inputs-container note">
        <div class="input-wrapper noteNumber">
            <div class="label">Numer noty</div>
            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    formControlName="noteNumber"
                    autocomplete="off"
                    (focus)="detailsService.onFocusChanged($event, ['noteNumber'], noteNumber)"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <mat-error *ngIf="noteNumber.hasError('required')">
                    Wprowadź numer
                    <ng-template #errors>
                        Nie wprowadzono numeru noty
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="input-wrapper noteDate">
            <div class="label">Data wystawienia noty</div>
            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    autocomplete="off"
                    formControlName="noteDate"
                    [satDatepicker]="noteDatepicker"
                    (focus)="detailsService.onFocusChanged($event, ['noteDate'], noteDate, dateParserService);"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <sat-datepicker #noteDatepicker></sat-datepicker>
                <sat-datepicker-toggle
                    matSuffix
                    [for]="noteDatepicker"
                ></sat-datepicker-toggle>
                <mat-error *ngIf="noteDate.hasError('required')">
                    Wprowadź datę
                    <ng-template #errors>
                        Nie wprowadzono daty wystawienia noty
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="inputs-container noteTypes">
        <div class="input-wrapper noteType">
            <div class="label">Typ noty</div>
            <mat-form-field floatLabel="never" class="custom">
                <mat-select
                    matInput
                    formControlName="noteType"
                    (focus)="detailsService.onFocusChanged($event, ['noteType'], noteType, noteTypeParserService);"
                    (focusout)="detailsService.onFocusChanged()"
                >
                    <mat-option *ngFor="let accountingNoteType of availableNoteTypes" [value]="accountingNoteType.value">
                        {{ accountingNoteType.display }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="input-wrapper noteCurrency">
            <div class="label">Waluta</div>
            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    autocomplete="off"
                    formControlName="noteCurrency"
                    (focus)="detailsService.onFocusChanged($event, ['noteCurrency'], noteCurrency)"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <mat-error *ngIf="noteCurrency.hasError('required')">
                    Wprowadź walutę
                    <ng-template #errors>
                        Nie wprowadzono waluty
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="inputs-container dates">
        <div class="input-wrapper noteToPay">
            <div class="label">Do zapłaty</div>
            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    autocomplete="off"
                    formControlName="noteToPay"
                    (focus)="detailsService.onFocusChanged($event, ['noteToPay'], noteToPay)"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <mat-error *ngIf="noteToPay.hasError('required')">
                    Wprowadź kwotę
                    <ng-template #errors>
                        Nie wprowadzono kwoty
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="input-wrapper notePaymentDeadline">
            <div class="label">Termin płatności</div>
            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    autocomplete="off"
                    formControlName="notePaymentDeadline"
                    [satDatepicker]="notePaymentDeadlinepicker"
                    (focus)="detailsService.onFocusChanged($event, ['notePaymentDeadline'], notePaymentDeadline, dateParserService);"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <sat-datepicker #notePaymentDeadlinepicker></sat-datepicker>
                <sat-datepicker-toggle
                    matSuffix
                    [for]="notePaymentDeadlinepicker"
                ></sat-datepicker-toggle>
                <mat-error *ngIf="notePaymentDeadline.hasError('required')">
                    Wprowadź datę
                    <ng-template #errors>
                        Nie wprowadzono terminu płatności
                    </ng-template>
                </mat-error>
                <mat-error *ngIf="!notePaymentDeadline.hasError('required') && notePaymentDeadline.hasError('matDatepickerParse')">
                    Wprowadź datę
                    <ng-template #errors>
                        Wprowadzono niepoprawny termin płatności
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="input-wrapper notePaymentMethod">
            <div class="label">Sposób płatności</div>
            <mat-form-field floatLabel="never" class="custom">
                <mat-select
                    matInput
                    formControlName="notePaymentMethod"
                    (focus)="detailsService.onFocusChanged($event, ['notePaymentMethod'], notePaymentMethod, paymentMethodParserSErvice)"
                    (focusout)="detailsService.onFocusChanged()"
                >
                    <mat-option *ngFor="let paymentMethod of availablePaymentMethods" [value]="paymentMethod.value">
                        {{ paymentMethod.display }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</form>
