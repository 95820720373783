<div class="scan-page">
    <header>
        <div class="controls">
            <div class="control">
                <div class="button">&uarr;</div>
                <div class="shortcut">Dokument</div>
            </div>

            <div class="control">
                <div class="button">&darr;</div>
                <div class="shortcut">Załącznik</div>
            </div>

            <div class="control">
                <div class="button">&larr;</div>
                <div class="button">&rarr;</div>
                <div class="shortcut">Nawigacja</div>
            </div>

            <div class="control">
                <div class="button big">SPACJA</div>
                <div class="shortcut">Powiększenie</div>
            </div>

            <div class="control">
                <div class="button">&lArr;</div>
                <div class="shortcut">Pomiń</div>
            </div>
        </div>

        <div class="page-title">
            Podziel plik
        </div>

        <div class="actions">
            <button
                mat-stroked-button
                type="button"
                color="primary"
                class="mark-as-single-page"
                (click)="deleteDocumentGroup()"
                [disabled]="isLoading"
            >
                Usuń dokument
            </button>

            <button
                mat-stroked-button
                type="button"
                color="primary"
                class="mark-as-single-page"
                (click)="markAsSinglePage()"
                [disabled]="isLoading"
            >
                Oznacz jako dokumenty jednostronne
            </button>

            <a class="close-button" [mat-dialog-close]="false">
                <span class="icon close"></span>
            </a>
        </div>
    </header>

    <div class="pages-container" *ngIf="!isLoading">
        <div
            class="pages-collection"
            [ngStyle]="{
                transform: 'translateX(' + pagesContainerOffset + 'px)'
            }"
        >
            <div *ngFor="let page of pagesArray; " class="page-wrapper">
                <div
                    class="page"
                    [class.current]="page.num == currentPage"
                    [class.loaded]="page.isLoaded"
                    [ngStyle]="{
                        transform:
                            'translateY(' + getPageTransform(page.num) + 'px)'
                    }"
                >
                    <div class="render relative-to-page">
                        <img
                            *ngIf="page.source !== null"
                            [src]="page.source"
                            alt="#{{ id }}"
                            (load)="page.isLoaded = true"
                        />
                    </div>

                    <div class="loader relative-to-page">
                        <div class="loading-wrapper">
                            <span class="icon loading"></span>
                        </div>
                    </div>

                    <ng-container [ngSwitch]="getPageMarkerType(page.num)">
                        <div
                            class="marker-label document relative-to-page"
                            *ngSwitchCase="ScanPageMarkerTypeEnum.DOCUMENT"
                        >
                            <div class="marker-label-text">
                                Dokument
                            </div>
                        </div>

                        <div
                            class="marker-label attachment relative-to-page"
                            *ngSwitchCase="ScanPageMarkerTypeEnum.ATTACHMENT"
                        >
                            <div class="marker-label-text">
                                Załącznik
                            </div>
                        </div>
                    </ng-container>

                    <ng-container [ngSwitch]="getPageMarkerType(page.num)">
                        <div
                            class="marker-background document relative-to-page"
                            *ngSwitchCase="ScanPageMarkerTypeEnum.DOCUMENT"
                        ></div>

                        <div
                            class="marker-background attachment relative-to-page"
                            *ngSwitchCase="ScanPageMarkerTypeEnum.ATTACHMENT"
                        ></div>

                        <div
                            class="marker-background skip relative-to-page"
                            *ngSwitchCase="ScanPageMarkerTypeEnum.SKIP"
                        ></div>
                    </ng-container>

                    <a
                        class="change-page relative-to-page"
                        *ngIf="page.num != currentPage"
                        (click)="setCurrentPage(page.num)"
                    >
                    </a>

                    <a
                        class="page-mark-button document relative-to-page"
                        *ngIf="page.num == currentPage"
                        (click)="
                            markCurrentPage(ScanPageMarkerTypeEnum.DOCUMENT)
                        "
                    >
                        <div class="text">Dokument</div>
                    </a>
                    <div class="hover-effect document relative-to-page"></div>

                    <a
                        class="page-mark-button attachment relative-to-page"
                        *ngIf="page.num == currentPage"
                        (click)="
                            markCurrentPage(ScanPageMarkerTypeEnum.ATTACHMENT)
                        "
                    >
                        <div class="text">Załącznik</div>
                    </a>
                    <div class="hover-effect attachment relative-to-page"></div>
                </div>
            </div>

            <div class="confirmation-page-wrapper">
                <div
                    class="confirmation-page"
                    (click)="setCurrentPage(pagesCount + 1)"
                >
                    <div class="saved relative-to-page" *ngIf="saved">
                        <div class="result-icon">
                            <span class="icon success"></span>
                        </div>
                        <div class="title">Plik został wysłany do podziału</div>

                        <div class="actions">
                            <button
                                mat-flat-button
                                class="primary-button button-with-shortcut with-loader"
                                [class.is-loading]="isSearchingNext"
                                color="primary"
                                (click)="navigateToNext()"
                            >
                                <div class="content">
                                    <div class="text">Podziel następny</div>
                                    <div class="shortcut big">ENTER</div>
                                </div>
                                <div class="loader">
                                    <span class="icon loading"></span>
                                </div>
                            </button>

                            <button
                                mat-flat-button
                                class="secondary-button button-with-shortcut"
                                (click)="navigateToList()"
                            >
                                <div class="text">Powrót do listy</div>
                                <div class="shortcut big">ESC</div>
                            </button>
                        </div>
                    </div>
                    <div class="to-save relative-to-page" *ngIf="!saved">
                        <div class="title">Wybierz datę wpływu</div>

                        <div class="input-wrapper">
                            <mat-form-field floatLabel="never" class="custom">
                                <input
                                    matInput
                                    autocomplete="off"
                                    [formControl]="receiptDate"
                                />
                            </mat-form-field>

                            <div class="inline-calendar">
                                <sat-calendar
                                    (selectedChange)="
                                        onReceiptDateSelect($event)
                                    "
                                    [selected]="selectedReceiptDate"
                                >
                                </sat-calendar>
                            </div>
                        </div>

                        <div class="actions">
                            <mat-error *ngIf="isError">
                                Wystąpił błąd, spróbuj ponownie poźniej 
                            </mat-error>

                            <div
                                class="tooltip-wrapper"
                                [matTooltip]="getMarkersError()"
                                [matTooltipDisabled]="
                                    getMarkersError() === null
                                "
                            >
                                <button
                                    mat-flat-button
                                    class="primary-button button-with-shortcut with-loader"
                                    [class.is-loading]="isConfirming"
                                    color="primary"
                                    (click)="confirm()"
                                    [disabled]="getMarkersError() !== null"
                                >
                                    <div class="content">
                                        <div class="text">Potwierdź</div>
                                        <div class="shortcut big">ENTER</div>
                                    </div>
                                    <div class="loader">
                                        <span class="icon loading"></span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-actions-container">
            <div class="page-actions-wrapper">
                <button
                    mat-button
                    color="warn"
                    (click)="markCurrentPage(ScanPageMarkerTypeEnum.SKIP)"
                    *ngIf="currentPage != pagesCount + 1"
                >
                    Pomiń
                    <span class="icon delete"></span>
                </button>
            </div>
        </div>
    </div>

    <div class="page-navigation-container" *ngIf="!isLoading">
        <div class="page-navigation" *ngIf="currentPage != pagesCount + 1">
            <button mat-icon-button type="button" (click)="goToFirstPage()">
                &larr;
            </button>
            <span class="page"> {{ currentPage }} / {{ pagesCount }} </span>
            <button mat-icon-button type="button" (click)="goToLastPage()">
                &rarr;
            </button>
        </div>
    </div>

    <digica-split-zoom [documentGroupId]="id"></digica-split-zoom>
</div>
