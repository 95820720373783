import { Component, ElementRef, OnChanges, OnDestroy, OnInit, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { FormGroup, FormControl, Validators, Form } from '@angular/forms';
import { Input } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { DetailsService } from '../services/details/details.service';
import { IDocumentDetails } from '../document-details.interface';
import { Subscription } from 'rxjs';
import { PaymentMethodParserService } from 'src/app/services/parsers/payment-method-parser-service/payment-method-parser.service';
import { DateParserService } from 'src/app/services/parsers/date-parser-service/date-parser.service';
import { DocumentModel } from 'src/app/contract/document/document.model';

@Component({
    selector: 'digica-document-details-billing-invoice-base',
    templateUrl: './document-details-billing-invoice-base.component.html',
    styleUrls: ['./document-details-billing-invoice-base.component.scss']
})
export class DocumentDetailsBillingInvoiceBaseComponent implements IDocumentDetails, OnInit, OnDestroy, OnChanges {

    @Input() public documentModel: DocumentModel;
    @Input() public isEditable: boolean;

    @ViewChildren('errors') errors: QueryList<TemplateRef<ElementRef>>;

    public availablePaymentMethods = [
        { value: 'Karta', display: 'Karta' },
        { value: 'Przelew', display: 'Przelew' },
        { value: 'Gotówka', display: 'Gotówka' },
        { value: 'Rekompensata', display: 'Rekompensata' },
        { value: 'Przedpłata', display: 'Przedpłata'}
    ];

    public formGroup = new FormGroup({
        billingInvoiceNumber: new FormControl('', [Validators.required]),
        billingIssueDate: new FormControl('', []),
        billingCurrency: new FormControl('', []),
        billingPaymentMethod: new FormControl(''),
        billingSplitPayment: new FormControl(false),
        billingSaleDate: new FormControl('', [Validators.required]),
        billingPaymentDeadline: new FormControl('', []),
        billingPaymentDate: new FormControl('', []),
        isDuplicate: new FormControl(false),
        duplicateDate: new FormControl()
    });

    public get billingInvoiceNumber() { return this.formGroup.get('billingInvoiceNumber') as FormControl; }
    public get billingIssueDate() { return this.formGroup.get('billingIssueDate') as FormControl; }
    public get billingCurrency() { return this.formGroup.get('billingCurrency') as FormControl; }
    public get billingPaymentMethod() { return this.formGroup.get('billingPaymentMethod') as FormControl; }
    public get billingSplitPayment() { return this.formGroup.get('billingSplitPayment') as FormControl; }
    public get billingSaleDate() { return this.formGroup.get('billingSaleDate') as FormControl; }
    public get billingPaymentDeadline() { return this.formGroup.get('billingPaymentDeadline') as FormControl; }
    public get isDuplicate() { return this.formGroup.get('isDuplicate') as FormControl; }
    public get duplicateDate() { return this.formGroup.get('duplicateDate') as FormControl; }

    private paymentMethidValueChangesSubscription: Subscription;
    private isDuplicateSubscription: Subscription;

    constructor(
        public detailsService: DetailsService,
        public paymentMethodParserService: PaymentMethodParserService,
        public dateParserService: DateParserService
    ) { }

    public ngOnInit(): void {
        this.detailsService.currencyValue = this.billingCurrency.valueChanges;
        this.detailsService.paymentMethodValueChanges = this.billingPaymentMethod.valueChanges;
        this.detailsService.documentNumberControl = this.billingInvoiceNumber;

        this.isDuplicateSubscription = this.isDuplicate.valueChanges.subscribe(value => {
            this.duplicateDate.clearValidators();

            if (value) {
                this.duplicateDate.setValidators([Validators.required]);
            }

            this.duplicateDate.updateValueAndValidity();
        });
    }

    public ngOnDestroy(): void {

        if (this.paymentMethidValueChangesSubscription) {
            this.paymentMethidValueChangesSubscription.unsubscribe();
        }

        if (this.isDuplicateSubscription) {
            this.isDuplicateSubscription.unsubscribe();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {

        if (changes.documentModel && changes.documentModel.currentValue) {
            this.detailsService.updateFormControl(this.billingCurrency, this.documentModel.billingCurrency);
            this.detailsService.updateFormControl(this.billingInvoiceNumber, this.documentModel.billingInvoiceNumber);
            this.detailsService.updateFormControl(this.billingPaymentMethod, this.documentModel.billingPaymentMethod);
            this.detailsService.updateFormControl(this.billingSplitPayment, this.documentModel.billingSplitPayment);
            this.detailsService.updateFormControl(this.billingIssueDate, this.documentModel.billingIssueDate);
            this.detailsService.updateFormControl(this.billingSaleDate, this.documentModel.billingSaleDate);
            this.detailsService.updateFormControl(this.billingPaymentDeadline, this.documentModel.billingPaymentDeadline);
            this.detailsService.updateFormControl(this.isDuplicate, this.documentModel.isDuplicate);
            this.detailsService.updateFormControl(this.duplicateDate, this.documentModel.duplicateDate);
            this.detailsService.markFormGroupTouched(this.formGroup);
        }

        this.detailsService.changeEnabledFormStateBasingOnIsEditable(this.isEditable, this.formGroup);
    }

    public assignDocumentModelValues(documentModel: DocumentModel): void {

        this.detailsService.updateDocumentModelFeature(documentModel, 'billingCurrency', this.billingCurrency.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'billingInvoiceNumber', this.billingInvoiceNumber.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'billingPaymentMethod', this.billingPaymentMethod.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'billingSplitPayment', this.billingSplitPayment.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'billingIssueDate', this.billingIssueDate.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'billingSaleDate', this.billingSaleDate.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'billingPaymentDeadline', this.billingPaymentDeadline.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'isDuplicate', this.isDuplicate.value);

        if (this.isDuplicate.value) {
            this.detailsService.updateDocumentModelFeature(documentModel, 'duplicateDate', this.duplicateDate.value);
        }
    }

    public canDeactivate = () => this.formGroup.pristine;
    public canConfirm = () => true;
    public getErrors = (): TemplateRef<ElementRef<any>>[] => this.errors.toArray();
}
