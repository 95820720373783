<h2 mat-dialog-title>Lista załączników</h2>
<mat-dialog-content>

    <div class="attachment-list">
        <div class="no-attachments" *ngIf="documentModel.attachments.length === 0">
            Brak dołączonych załączników do dokumentu
        </div>

        <div *ngFor="let attachment of documentModel.attachments; trackBy: attachmentTrackBy" class="attachment">
            <div class="name">
                {{ attachment.originalFileName }}
            </div>

            <div class="actions">
                <button mat-icon-button type="button" (click)="downloadAttachment(attachment)">
                    <span class="icon download"></span>
                </button>

                <button mat-icon-button (click)="deleteAttachment(attachment)" *ngIf="isEditable()">
                    <span class="icon delete"></span>
                </button>
            </div>
        </div>
    </div>

    <div class="upload-attachment" *ngIf="isEditable()">
        <div class="actions">
            <button
                mat-raised-button
                type="button"
                color="primary"
                (click)="fileInput.click()"
                [disabled]="uploadingCount > 0"
            >
                Dodaj załączniki
            </button>

            <input
                #fileInput
                type="file"
                (change)="onFilesSelected($event)"
                accept="application/pdf, image/*"
                hidden
                multiple
            />
        </div>

        <div class="upload-status" *ngIf="uploadingCount > 0">
            <div class="loading-wrapper">
                <span class="icon loading"></span>
            </div>

            <div class="status-text">
                Trwa przesyłanie {{ uploadingCount }} plików
            </div>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button color="primary" [mat-dialog-close]="true">Zamknij</button>
</mat-dialog-actions>
