<digica-document-filters
    (filtersSelected)="onFiltersSelected($event)"
></digica-document-filters>

<div class="container">
    <div #tableWrapper class="table">
        <div class="head">
            <div class="row">
                <div class="cell documentType label">Typ dokumentu</div>
                <div class="cell contractor label">Kontrahent</div>
                <div class="cell documentNumber label">Numer dokumentu</div>
                <div class="cell receiptDate label">
                    <div class="sortable" (click)="sortChanged('receiptDate')">
                        Data wpływu

                        <span *ngIf="sortingColumn != 'receiptDate'" class="sort-arrow">&#8597;</span>
                        <span *ngIf="sortingColumn == 'receiptDate' && sortingDirection == 'asc'" class="sort-arrow">&uarr;</span>
                        <span *ngIf="sortingColumn == 'receiptDate' && sortingDirection == 'desc'" class="sort-arrow">&darr;</span>
                    </div>
                </div>
                <div class="cell actions label">&nbsp;</div>
            </div>
        </div>

        <div class="body">
            <ng-container *ngFor="let item of items; trackBy: documentExtractTrackBy">

                <ng-container *ngIf="isItemLocked(item)">
                    <a class="row locked">
                        <div class="row-lock" matTooltip="Ten dokument jest edytowany przez {{ item.editor.nickname }}">
                            <span class="icon lock"></span>
                        </div>
                        <ng-container *ngTemplateOutlet="row; context: { $implicit: item }"></ng-container>
                    </a>
                    <div class="row-separator"></div>
                </ng-container>

                <ng-container *ngIf="!isItemLocked(item)">
                    <a class="row" [routerLink]="['/documents', item.id]">
                        <ng-container *ngTemplateOutlet="row; context: { $implicit: item }"></ng-container>
                    </a>
                    <div class="row-separator"></div>
                </ng-container>

            </ng-container>
        </div>

        <div class="loading-container table-loading" *ngIf="isLoading">
            <div class="loading-content">
                <div class="icon-wrapper">
                    <span class="icon loading"></span> 
                </div>

                <div class="text-wrapper">
                    Trwa ładowanie wyników
                </div>
            </div>
        </div>

        <div *ngIf="!isLoading && items.length === 0" class="no-results">
            <div class="text">
                Brak dokumentów spełniających kryteria wyszukiwania
            </div>
        </div>
    </div>
</div>

<ng-template #row let-item>
    <div class="cell documentType">
        <digica-document-type
            [documentType]="item.documentType"
        ></digica-document-type>
    </div>
    <div class="cell contractor">
        <span *ngIf="item.contractorName">
            {{ item.contractorName }}
        </span>
        <span *ngIf="!item.contractorName" class="not-detected">
            Nie wykryto
        </span>
    </div>
    <div class="cell documentNumber">
        <span *ngIf="item.documentNumber">
            {{ item.documentNumber }}
        </span>
        <span *ngIf="!item.documentNumber" class="not-detected">
            Nie wykryto
        </span>
    </div>
    <div class="cell receiptDate">
        {{ item.receiptDate | date: "dd.LL.yyyy" }}
    </div>
    <div class="cell actions">
        <button mat-icon-button [matMenuTriggerFor]="menu" (click)="openMenu($event)">
            <span class="icon more"></span>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="download(item)">
                <span class="icon download"></span>
                <span>Pobierz</span>
            </button>
            <button mat-menu-item (click)="print(item)">
                <span class="icon print"></span>
                <span>Drukuj</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="delete(item)">
                <span class="icon delete"></span>
                <span>Usuń</span>
            </button>
        </mat-menu>
    </div>
</ng-template>

<router-outlet></router-outlet>
