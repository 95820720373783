import { Injectable } from '@angular/core';
import { CallService } from '../call-service/call.service';
import { Attachment } from 'src/app/contract/document/attachment.model';

@Injectable({
    providedIn: 'root'
})
export class AttachmentService {

    private servicePrefix = 'attachments';

    constructor(
        private callService: CallService
    ) { }

    public DeleteAttachment(attachmentId: number) {
        return this.callService.delete(`${this.callService.apiUrl}/${this.servicePrefix}/${attachmentId}`);
    }

    public GetAttachmentUrl(attachmentId: number): string {
        return `${this.callService.apiUrl}/${this.servicePrefix}/${attachmentId}`;
    }

    public GetAttachment(attachmentUrl: string) {
        return this.callService.download(attachmentUrl);
    }

    public DownloadDocumentAttachment(fileName: string, attachmentId: number): void {
        this.GetAttachment(this.GetAttachmentUrl(attachmentId)).subscribe((res) => {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(res.body);
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
        });
    }

    public UploadDocumentAttachment(file: File, documentId: number) {
        return this.callService.upload<Attachment>(`${this.servicePrefix}/upload`, file, { documentId });
    }
}
