import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { UserService } from 'src/app/services/user-service/user.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    templateUrl: './change-password-dialog.component.html',
    styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

    public isChanging = false;
    public formGroup: FormGroup = new FormGroup({
        password: new FormControl('', [Validators.required]),
        newPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
        reNewPassword: new FormControl('', [Validators.required, this.matchValues('newPassword')]),
    });

    constructor(
        public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
        private userService: UserService,
    ) { }

    private matchValues(matchTo: string): (AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): ValidationErrors | null => {
            return !!control.parent &&
                !!control.parent.value &&
                control.value === control.parent.controls[matchTo].value
                ? null : { mismatch: true };
        };
    }


    public cancelClick(): void {
        this.dialogRef.close(false);
    }

    public ngOnInit(): void {
    }

    public addUserToOrganization(): void {
        if (this.formGroup.valid) {
            const password = this.formGroup.controls.password.value;
            const newPassword = this.formGroup.controls.newPassword.value;

            this.isChanging = true;
            this.userService.changePassword(password, newPassword).subscribe(_ => {
                this.isChanging = false;
                this.dialogRef.close(true);
            }, (error: HttpErrorResponse) => {
                this.isChanging = false;

                if (error.status === 400) {
                    this.formGroup.setErrors({ invalidPassword: true });
                } else {
                    this.formGroup.setErrors({ errorOccurred: true });
                }
            });
        }
    }
}
