import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AttachmentService } from 'src/app/services/attachment-service/attachment-service.service';
import { Attachment } from 'src/app/contract/document/attachment.model';
import { DocumentStatusEnum } from 'src/app/contract/document/document-status.enum';
import { DocumentModel } from 'src/app/contract/document/document.model';

@Component({
    templateUrl: './attachment-list-dialog.component.html',
    styleUrls: ['./attachment-list-dialog.component.scss']
})
export class AttachmentListDialogComponent {

    public uploadingCount = 0;

    constructor(
        public dialogRef: MatDialogRef<AttachmentListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public documentModel: DocumentModel,
        private attachmentService: AttachmentService,
    ) { }

    public downloadAttachment(attachment: Attachment): void {

        this.attachmentService.DownloadDocumentAttachment(attachment.originalFileName, attachment.attachmentId);
    }

    public deleteAttachment(attachment: Attachment): void {

        if (!this.isEditable()) {
            return;
        }

        this.attachmentService.DeleteAttachment(attachment.attachmentId).subscribe(_ => {

            this.documentModel.attachments = this.documentModel.attachments.filter(x => x.attachmentId !== attachment.attachmentId);
        });
    }

    public onFilesSelected($event: any): void {
        const fileList: FileList = $event.target.files;
        this.uploadFiles(fileList);
    }

    public isEditable = () => this.documentModel.currentStatus.value === DocumentStatusEnum.ToConfirm;
    public attachmentTrackBy = (_: number, attachment: Attachment) => attachment.attachmentId;

    private uploadFiles(fileList: FileList): void {
        if (!this.isEditable() || !fileList.length || this.uploadingCount > 0) {
            return;
        }

        const filesCount = fileList.length;
        this.uploadingCount = filesCount;

        for (let i = 0; i < filesCount; i += 1) {
            this.uploadFile(fileList[i], this.documentModel.documentId);
        }
    }

    private uploadFile(file: File, documentId: number): void {
        this.attachmentService.UploadDocumentAttachment(file, documentId).subscribe(
            attachment => {
                this.uploadingCount -= 1;
                this.documentModel.attachments.push(attachment);
            },
            _ => {
                this.uploadingCount -= 1;
            }
        );
    }
}
