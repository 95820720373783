import { Component, Input } from '@angular/core';
import { UserRoleEnum } from 'src/app/contract/user/user-role.enum';

@Component({
    selector: 'digica-user-role',
    templateUrl: './user-role.component.html',
    styleUrls: []
})
export class UserRoleComponent {

    @Input() role: UserRoleEnum;
    public UserRoleEnum = UserRoleEnum;

    constructor() { }
}
