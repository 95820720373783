import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SplitListComponent } from './split/split-list/split-list.component';
import { ArchiveListComponent } from './archive/archive-list/archive-list.component';
import { LayoutDefaultComponent } from './shared/layout-default/layout-default.component';
import { LoginComponent } from './shared/login/login.component';
import { AnonymousGuard } from './shared/guards/anonymous.guard';
import { LoggedInGuard } from './shared/guards/logged-in.guard';
import { RoleGuard } from './shared/guards/role.guard';
import { UserRoleEnum } from './contract/user/user-role.enum';
import { SettingsPageComponent } from './settings/settings-page/settings-page.component';
import { ResetPasswordComponent } from './shared/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './shared/forgot-password/forgot-password.component';
import { LocalStorageGuard } from './shared/guards/local-storage.guard';
import { NoLocalStorageComponent } from './shared/errors/no-local-storage/no-local-storage.component';
import { RegisterComponent } from './shared/register/register.component';
import { SettingsAccountComponent } from './settings/account/settings-account/settings-account.component';
import { SettingsOrganizationComponent } from './settings/organization/settings-organization/settings-organization.component';
import { SettingsEmailsComponent } from './settings/uploading/settings-emails/settings-emails.component';
import { ActivateAccountComponent } from './shared/activate-account/activate-account.component';
import { SplitDialogContainerComponent } from './split/split-dialog-container/split-dialog-container.component';
import { DocumentListComponent } from './documents/document-list/document-list.component';
import {
    DocumentDetailsDialogContainerComponent
} from './documents/document-details-dialog-container/document-details-dialog-container.component';
import { SplitAttachmentListComponent } from './sp-attachment/sp-attachment-list/sp-attachment-list.component';
import {
    SplitAttachmentDialogContainerComponent
} from './sp-attachment/sp-attachment-container/sp-attachment-dialog-container.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'documents',
        pathMatch: 'full'
    },
    {
        path: '',
        canActivate: [LoggedInGuard],
        children: [
            {
                path: '',
                component: LayoutDefaultComponent,
                canActivate: [RoleGuard],
                data: { roles: [UserRoleEnum.User, UserRoleEnum.Admin] },
                children: [
                    {
                        path: 'split',
                        component: SplitListComponent,
                        children: [
                            {
                                path: ':id',
                                component: SplitDialogContainerComponent,
                            },
                        ],
                    },
                    {
                        path: 'sp-attachment',
                        component: SplitAttachmentListComponent,
                        children: [
                            {
                                path: ':id',
                                component: SplitAttachmentDialogContainerComponent,
                            },
                        ],
                    },
                    {
                        path: 'documents',
                        component: DocumentListComponent,
                        children: [
                            {
                                path: ':id',
                                component: DocumentDetailsDialogContainerComponent,
                            },
                        ],
                    },
                    {
                        path: 'settings',
                        component: SettingsPageComponent,
                        children: [
                            {
                                path: 'account',
                                component: SettingsAccountComponent,
                                canActivate: [RoleGuard],
                                data: { roles: [UserRoleEnum.User, UserRoleEnum.Admin] },
                            },
                            {
                                path: 'organization',
                                component: SettingsOrganizationComponent,
                                canActivate: [RoleGuard],
                                data: { roles: [UserRoleEnum.Admin] },
                            },
                            {
                                path: 'uploading',
                                component: SettingsEmailsComponent,
                                canActivate: [RoleGuard],
                                data: { roles: [UserRoleEnum.User, UserRoleEnum.Admin] },
                            },
                            {
                                path: '',
                                redirectTo: 'account',
                                pathMatch: 'full'
                            },
                        ],
                    },
                    {
                        path: 'analytics',
                        loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule),
                    },
                    {
                        path: 'automations',
                        loadChildren: () => import('./automations/automations.module').then(m => m.AutomationsModule),
                    },
                    {
                        path: 'group',
                        loadChildren: () => import('./document-group/document-group.module').then(m => m.DocumentGroupModule),
                    },
                ],
            },
            {
                path: '',
                component: LayoutDefaultComponent,
                canActivate: [RoleGuard],
                data: { roles: [UserRoleEnum.Guest, UserRoleEnum.User, UserRoleEnum.Admin] },
                children: [
                    {
                        path: 'archive',
                        loadChildren: () => import('./archive/archive.module').then(m => m.ArchiveModule),
                    },
                ],
            },
        ]
    },
    {
        path: '',
        canActivate: [AnonymousGuard, LocalStorageGuard],
        children: [
            {
                path: 'login',
                component: LoginComponent,
            },
            {
                path: 'register',
                component: RegisterComponent,
            },
            {
                path: 'activate',
                component: ActivateAccountComponent,
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent,
            },
            {
                path: 'reset-password',
                component: ResetPasswordComponent,
            },
        ]
    },
    {
        path: 'no-local-storage',
        component: NoLocalStorageComponent,
    },
    {
        path: '**',
        redirectTo: 'documents',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
