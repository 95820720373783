<div class="container">
    <header class="logo-container">
        <img src="/assets/logos/digica_wText_horizontal.svg" alt="Digica">
    </header>

    <div class="auth-container">
        <form [formGroup]="form" (ngSubmit)="submit()">
            <div class="auth-box">
                <h1>Zmiana hasła</h1>

                <ng-container *ngIf="!isSubmitted && !isVeryfing && isTokenValid">
                    <div class="form-info">Zmiana hasła dla użytkownika o adresie <b>{{ email }}</b>.</div>
                    
                    <div class="input-wrapper">
                        <div class="label">
                            Hasło
                        </div>

                        <mat-form-field floatLabel="never" class="custom">
                            <input matInput name="password" formControlName="password" type="password" autocomplete="off" />
                            <mat-error *ngIf="password.hasError('required')">
                                Hasło jest wymagane
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="input-wrapper">
                        <div class="label">
                            Powtórz hasło
                        </div>

                        <mat-form-field floatLabel="never" class="custom">
                            <input matInput name="repassword" formControlName="repassword" type="password" autocomplete="off" />
                            <mat-error *ngIf="repassword.hasError('required')">
                                Powtórzenie hasła jest wymagane
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="input-wrapper">
                        <mat-error class="default">
                            <span *ngIf="form.errors && form.errors.notSame">
                                Wprowadzone hasła muszą być identyczne
                            </span>
                        </mat-error>
                    </div>

                    <button
                        type="submit"
                        mat-flat-button
                        color="primary"
                        class="submit-button"
                    >
                        Zaktualizuj hasło
                    </button>
                </ng-container>
                <ng-container *ngIf="isVeryfing">
                    <div class="status">
                        <div class="icon-wrapper loading">
                            <span class="icon loading"></span>
                        </div>

                        <div class="text">
                            Trwa weryfikacja, proszę czekać.
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!isVeryfing && !isTokenValid">
                    <div class="status">
                        <div class="icon-wrapper error">
                            <span class="icon error"></span>
                        </div>

                        <div class="text">
                            Link jest niepoprawny lub wygasł, konieczne jest ponowne wysłanie e-maila z <a [routerLink]="['/forgot-password']">przypomnieniem hasła</a>.
                        </div>
                    </div>
                </ng-container>
                
                <ng-container *ngIf="isSubmitted">
                    <div class="status">
                        <div class="icon-wrapper success">
                            <span class="icon success"></span>
                        </div>

                        <div class="text">
                            Hasło zostało zmienione, możesz się teraz zalogować.
                        </div>
                    </div>
                </ng-container>

                <div class="links">
                    <a [routerLink]="['/login']">Przejdź do logowania</a>
                </div>
            </div>
        </form>
    </div>
</div>
