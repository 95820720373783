<ng-container [ngSwitch]="documentType">
    <ng-container *ngSwitchCase="DocumentTypeEnum.PurchaseInvoice">
        <ng-container *ngIf="multiple">
            Faktury zakupowe
        </ng-container>
        <ng-container *ngIf="!multiple">
            Faktura zakupowa
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="DocumentTypeEnum.InvoiceCorrection">
        <ng-container *ngIf="multiple">
            Faktury korygujące
        </ng-container>
        <ng-container *ngIf="!multiple">
            Faktura korygująca
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="DocumentTypeEnum.PrepaymentInvoice">
        <ng-container *ngIf="multiple">
            Faktury zaliczkowe
        </ng-container>
        <ng-container *ngIf="!multiple">
            Faktura zaliczkowa
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="DocumentTypeEnum.BillingInvoice">
        <ng-container *ngIf="multiple">
            Faktury rozliczeniowe
        </ng-container>
        <ng-container *ngIf="!multiple">
            Faktura rozliczeniowa
        </ng-container>
    </ng-container>
    <!--<ng-container *ngSwitchCase="DocumentTypeEnum.ProvisionalInvoice">
        <ng-container *ngIf="multiple">
            Faktury proforma
        </ng-container>
        <ng-container *ngIf="!multiple">
            Faktura proforma
        </ng-container>
    </ng-container>-->
    <ng-container *ngSwitchCase="DocumentTypeEnum.AccountingNote">
        <ng-container *ngIf="multiple">
            Noty księgowe
        </ng-container>
        <ng-container *ngIf="!multiple">
            Nota księgowa
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
        Nieznany typ
    </ng-container>
</ng-container>
