<form [formGroup]="formGroup">
    <ng-template #placeloader>
        <div class="placeloader"></div>
    </ng-template>

    <div class="base-information flow">
        <div class="information-box uploader padded">
            <div class="label">Dodane przez</div>
            <div class="value">
                <div class="nickname" *ngIf="documentModel; else placeloader">
                    <div class="ellipsis nickname" [matTooltip]="documentModel.uploaderNickname">
                        <span>{{ documentModel.uploaderNickname }}</span>
                    </div>
                </div>
                <div class="date" *ngIf="documentModel; else placeloader">
                    {{ documentModel.uploadDate | date: 'short' }}
                </div>
            </div>
        </div>

        <div class="information-box splitter padded">
            <div class="label">Podzielone przez</div>
            <div class="value">
                <div class="nickname" *ngIf="documentModel; else placeloader">
                    <div *ngIf="documentModel.splitterNickname !== null" class="ellipsis nickname" [matTooltip]="documentModel.splitterNickname">
                        <span>{{ documentModel.splitterNickname }}</span>
                    </div>
                    <div *ngIf="documentModel.splitterNickname === null" class="muted">
                        Niewymagane
                    </div>
                </div>
                <div class="date" *ngIf="documentModel; else placeloader">
                    {{ documentModel.splitDate | date: 'short' }}
                </div>
            </div>
        </div>

        <div class="information-box confirmer padded" *ngIf="documentModel && documentModel.currentStatus.value === DocumentStatusEnum.Archived">
            <div class="label">Potwierdzone przez</div>
            <div class="value">
                <div class="nickname">
                    <div *ngIf="documentModel.approvementType === ApprovementTypeEnum.Manual" class="ellipsis nickname" [matTooltip]="documentModel.confirmerNickname">
                        <span>{{ documentModel.confirmerNickname }}</span>
                    </div>
                    <div *ngIf="documentModel.approvementType === ApprovementTypeEnum.Automated">
                        <digica-automation-icon [automated]="true" [result]="documentModel.automationResult"></digica-automation-icon> Automatycznie
                    </div>
                </div>
                <div class="date">
                    {{ documentModel.confirmDate | date: 'short' }}
                </div>
            </div>
        </div>
    </div>

    <div class="base-information inputs">
        <div class="information-box documentType">
            <div class="label">Typ dokumentu</div>
            <div class="value">
                <ng-container *ngIf="documentModel; else placeloader">
                    <mat-form-field floatLabel="never" class="custom">
                        <mat-select formControlName="documentType" (selectionChange)="documentTypeChanged($event)">
                            <mat-option *ngFor="let enum of DocumentTypeEnum | enumToArray" [value]="enum">
                                <digica-document-type [documentType]="enum"></digica-document-type>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </div>
        </div>

        <div class="information-box receiptDate">
            <div class="label">Data wpływu</div>
            <div class="value">
                <ng-container *ngIf="documentModel; else placeloader">
                    <mat-form-field floatLabel="never" class="custom">
                        <input
                            matInput
                            autocomplete="off"
                            formControlName="receiptDate"
                            [satDatepicker]="receiptDatepicker"
                        />
                        <sat-datepicker #receiptDatepicker></sat-datepicker>
                        <sat-datepicker-toggle
                            matSuffix
                            [for]="receiptDatepicker"
                        ></sat-datepicker-toggle>
                        <mat-error *ngIf="receiptDate.hasError('required')">
                            Wprowadź datę
                            <ng-template #errors>
                                Nie wprowadzono daty wpływu
                            </ng-template>
                        </mat-error>
                    </mat-form-field>
                </ng-container>
            </div>
        </div>
    </div>
</form>
