import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AddDocumentsDialogComponent } from 'src/app/documents/add-documents-dialog/add-documents-dialog.component';

@Component({
    selector: 'digica-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {

    constructor(
        private dialog: MatDialog,
    ) { }

    public openAddDocumentsDialog(): void {

        this.dialog.open(AddDocumentsDialogComponent);
    }
}
