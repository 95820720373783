import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { SplitDialogComponent } from '../split-dialog/split-dialog.component';
import { Title } from '@angular/platform-browser';

@Component({
    template: ``,
})
export class SplitDialogContainerComponent implements OnInit, OnDestroy {

    private paramsSubscription: Subscription;
    private previousTitle: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private title: Title,
    ) { }

    public ngOnInit(): void {

        this.previousTitle = this.title.getTitle();
        this.paramsSubscription = this.activatedRoute.params.subscribe(params => {

            const dialogRef = this.dialog.open(SplitDialogComponent, {
                data: +params.id,
                maxWidth: '100vw',
                maxHeight: '100vh',
                height: '100%',
                width: '100%',
                panelClass: 'full-screen-modal',
                autoFocus: false,
            });

            dialogRef.afterClosed().subscribe(_ => {
                this.redirectBack();
            }, _ => {
                this.redirectBack();
            });
        });
    }

    public ngOnDestroy(): void {

        if (this.paramsSubscription) {
            this.paramsSubscription.unsubscribe();
        }
    }

    private redirectBack(): void {
        this.title.setTitle(this.previousTitle);
        this.router.navigate(['..'], { relativeTo: this.activatedRoute });
    }
}
