import { Component, SimpleChanges, OnChanges, Input, ElementRef, TemplateRef, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { DocumentModel } from 'src/app/contract/document/document.model';
import { IDocumentDetails } from '../document-details.interface';
import { DetailsService } from '../services/details/details.service';
import { DocumentService } from 'src/app/services/document-service/document.service';
import {  merge, Subscription } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';
import { SimiliarDocument } from 'src/app/contract/document/similar-document';

@Component({
    selector: 'digica-document-duplicates',
    templateUrl: './document-duplicates.component.html',
    styleUrls: ['./document-duplicates.component.scss']
})
export class DocumentDuplicatesComponent implements IDocumentDetails, OnChanges, OnDestroy {

    @Input() public documentModel: DocumentModel;
    @Input() public isEditable: boolean;

    @ViewChildren('errors') errors: QueryList<TemplateRef<ElementRef>>;

    public similarDocuments: Array<SimiliarDocument> = [];
    public isLoading = false;
    public isError = false;
    public hasLoaded = false;

    private duplicatesControlsSubscription: Subscription;

    constructor(
        private detailsService: DetailsService,
        private documentService: DocumentService,
    ) { }

    public ngOnDestroy(): void {

        if (this.duplicatesControlsSubscription) {
            this.duplicatesControlsSubscription.unsubscribe();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {

        if (!changes.documentModel || !changes.documentModel.currentValue) {
            return;
        }

        this.watchForFormControlsChanges();
        this.detailsService.documentTypeControl.valueChanges
            .subscribe(_ => setTimeout(() => this.watchForFormControlsChanges()));
    }

    public assignDocumentModelValues(documentModel: DocumentModel): void {
    }

    public canConfirm = () => true;
    public canDeactivate = () => true;
    public getErrors = (): TemplateRef<ElementRef<any>>[] => this.errors.toArray();
    public similarDocumentTrackBy = (_: number, similarDocument: SimiliarDocument) => similarDocument.documentId;

    private watchForFormControlsChanges(): void {

        if (this.duplicatesControlsSubscription) {
            this.duplicatesControlsSubscription.unsubscribe();
        }

        this.duplicatesControlsSubscription = merge(
            this.detailsService.documentNumberControl.valueChanges,
            this.detailsService.contractorTaxNumberControl.valueChanges,
            this.detailsService.documentTypeControl.valueChanges,
        ).pipe(
            startWith([null]),
            debounceTime(500),
        ).subscribe(_ => {

            const documentNumber = this.detailsService.documentNumberControl.value;
            const taxNumber = this.detailsService.contractorTaxNumberControl.value;
            const documentType = this.detailsService.documentTypeControl.value;

            this.isLoading = true;
            this.isError = false;
            this.documentService
                .getDuplicates(this.documentModel.documentId, documentNumber, taxNumber, documentType)
                .subscribe(duplicates => {
                    this.similarDocuments = duplicates;
                    this.isLoading = false;
                    this.hasLoaded = true;
                }, error => {
                    this.similarDocuments = [];
                    this.isLoading = false;
                    this.isError = true;
                    this.hasLoaded = true;
                });
        });
    }
}
