<form [formGroup]="formGroup">

    <mat-divider></mat-divider>

    <div class="inputs-container main">
        <div class="input-wrapper invoiceNumber">
            <div class="label">Numer faktury</div>
            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    formControlName="invoiceNumber"
                    autocomplete="off"
                    (focus)="detailsService.onFocusChanged($event, ['invoiceNumber'], invoiceNumber)"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <mat-error *ngIf="invoiceNumber.hasError('required')">
                    Wprowadź numer
                    <ng-template #errors>
                        Nie wprowadzono numeru faktury
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="input-wrapper paymentMethod">
            <div class="label">Sposób płatności</div>
            <mat-form-field floatLabel="never" class="custom">
                <mat-select
                    matInput
                    formControlName="paymentMethod"
                    (focus)="detailsService.onFocusChanged($event, ['paymentMethod'], paymentMethod, paymentMethodParserService)"
                    (focusout)="detailsService.onFocusChanged()"
                >
                    <mat-option *ngFor="let paymentMethod of availablePaymentMethods" [value]="paymentMethod.value">
                        {{ paymentMethod.display }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        

        <div class="input-wrapper duplicateDate" [style.visibility]="isDuplicate.value ? 'visible' : 'hidden'">
            <div class="label">Data duplikatu</div>

            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    autocomplete="off"
                    formControlName="duplicateDate"
                    [satDatepicker]="duplicateDatepicker"
                    (focus)="detailsService.onFocusChanged($event, ['duplicateDate'], duplicateDate, dateParserService);"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <sat-datepicker #duplicateDatepicker></sat-datepicker>
                <sat-datepicker-toggle
                    matSuffix
                    [for]="duplicateDatepicker"
                ></sat-datepicker-toggle>
                <mat-error *ngIf="duplicateDate.hasError('required')">
                    Wprowadź datę
                    <ng-template #errors>
                        Nie wprowadzono daty duplikatu
                    </ng-template>
                </mat-error>
                <mat-error *ngIf="!duplicateDate.hasError('required') && duplicateDate.hasError('matDatepickerParse')">
                    Wprowadź datę
                    <ng-template #errors>
                        Wprowadzono niepoprawną datę duplikatu
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="inputs-container dates">
        <div class="input-wrapper issueDate">
            <div class="label">Data wystawienia</div>
            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    autocomplete="off"
                    formControlName="issueDate"
                    [satDatepicker]="issueDatepicker"
                    (focus)="detailsService.onFocusChanged($event, ['issueDate'], issueDate, dateParserService);"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <sat-datepicker #issueDatepicker></sat-datepicker>
                <sat-datepicker-toggle
                    matSuffix
                    [for]="issueDatepicker"
                ></sat-datepicker-toggle>
                <mat-error *ngIf="issueDate.hasError('required')">
                    Wprowadź datę
                    <ng-template #errors>
                        Nie wprowadzono daty wystawienia
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="input-wrapper saleDate">
            <div class="label">Data sprzedaży</div>
            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    autocomplete="off"
                    formControlName="saleDate"
                    [satDatepicker]="saleDatepicker"
                    (focus)="detailsService.onFocusChanged($event, ['saleDate'], saleDate, dateParserService);"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <sat-datepicker #saleDatepicker></sat-datepicker>
                <sat-datepicker-toggle
                    matSuffix
                    [for]="saleDatepicker"
                ></sat-datepicker-toggle>
                <mat-error *ngIf="saleDate.hasError('required')">
                    Wprowadź datę
                    <ng-template #errors>
                        Nie wprowadzono daty sprzedaży
                    </ng-template>
                </mat-error>
                <mat-error *ngIf="!saleDate.hasError('required') && saleDate.hasError('matDatepickerParse')">
                    Wprowadź datę
                    <ng-template #errors>
                        Wprowadzono niepoprawną datę sprzedaży
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="input-wrapper paymentDeadline">
            <div class="label">Termin płatności</div>
            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    autocomplete="off"
                    formControlName="paymentDeadline"
                    [satDatepicker]="paymentDeadlinePicker"
                    (focus)="detailsService.onFocusChanged($event, ['paymentDeadline'], paymentDeadline, dateParserService);"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <sat-datepicker #paymentDeadlinePicker></sat-datepicker>
                <sat-datepicker-toggle
                    matSuffix
                    [for]="paymentDeadlinePicker"
                ></sat-datepicker-toggle>
                <mat-error *ngIf="paymentDeadline.hasError('matDatepickerParse')">
                    Wprowadź datę
                    <ng-template #errors>
                        Nie wprowadzono terminu płatności
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="input-wrapper currency">
            <div class="label">Waluta</div>
            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    autocomplete="off"
                    formControlName="currency"
                    (focus)="detailsService.onFocusChanged($event, ['currency'], currency)"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <mat-error *ngIf="currency.hasError('required')">
                    Wprowadź walutę
                    <ng-template #errors>
                        Nie wprowadzono waluty
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="checkboxes" [style.display]="'none'">
        <div class="input-wrapper splitPayment">
            <mat-checkbox id="splitPayment"
                          color="primary"
                          formControlName="splitPayment"
                          (focus)="detailsService.onFocusChanged($event, ['splitPayment'], splitPayment)"
                          (focusout)="detailsService.onFocusChanged()">
                Podzielona płatność
            </mat-checkbox>
        </div>

        <div class="input-wrapper isDuplicate">
            <mat-checkbox id="isDuplicate"
                          color="primary"
                          formControlName="isDuplicate"
                          (focus)="detailsService.onFocusChanged($event, ['isDuplicate'], isDuplicate)"
                          (focusout)="detailsService.onFocusChanged()">
                Duplikat
            </mat-checkbox>
        </div>
    </div>
</form>
