import { Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { IFormatter } from '../iformatter';

@Injectable({
    providedIn: 'root'
})
export class AmountFormatterService implements IFormatter {

    constructor(
        private decimalPipe: DecimalPipe,
    ) { }

    public formatInput($event: any): void {

        const input = $event.target as HTMLInputElement;
        input.value = this.format(input.value);
    }

    public format(value: string | number): string {
        if (value === null) {
            return '';
        }

        value = value.toString();
        const cleaned = value.replace(/\,/g, '.').replace(/\s/g, '');

        try {
            value = this.decimalPipe.transform(cleaned, '1.2-2');
        } catch { }

        return value;
    }

    public parse(value: string): string {

        if (!value) {
            return '';
        }

        value = value.replace(/\,/g, '.').replace(/\s/g, '');
        return value;
    }
}
