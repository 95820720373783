import { Component, ElementRef, OnChanges, OnDestroy, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { VATFeatureArea } from 'src/app/services/interactive-image-service/feature-areas.model';
import { ValidationService } from 'src/app/services/validation-service/validation.service';
import { AmountFormatterService } from 'src/app/services/formatters/amount-formatter-service/amount-formatter.service';
import { Input } from '@angular/core';
import { IFormatter } from 'src/app/services/formatters/iformatter';
import { SimpleChanges } from '@angular/core';
import { DetailsService } from '../services/details/details.service';
import { AmountParserService } from 'src/app/services/parsers/amount-parser-service/amount-parser.service';
import { VatRateParserService } from 'src/app/services/parsers/vat-rate-parser-service/vat-rate-parser.service';
import { IDocumentDetails } from '../document-details.interface';
import { DocumentModel } from 'src/app/contract/document/document.model';
import { DocumentVATFeature } from 'src/app/contract/document/document-vat-feature.model';
import { DocumentFeature } from 'src/app/contract/document/document-feature.model';
import { OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'digica-document-details-vat-table',
    templateUrl: './document-details-vat-table.component.html',
    styleUrls: ['./document-details-vat-table.component.scss']
})
export class DocumentDetailsVatTableComponent implements IDocumentDetails, OnChanges, OnInit, OnDestroy {

    @Input() public documentModel: DocumentModel;
    @Input() public isEditable: boolean;

    @Input() public amountNetFeatureName = 'amountNet';
    @Input() public amountVatFeatureName = 'amountVat';
    @Input() public amountGrossFeatureName = 'amountGross';
    @Input() public vatRowsFeatureName = 'vatRows';
    @Input() public isInputRequired = true;

    @ViewChildren('errors') errors: QueryList<TemplateRef<ElementRef>>;

    public availableRates = [
        { value: 'zw', display: 'zw', },
        { value: 'np', display: 'np', },
        { value: '0', display: '0%', },
        { value: '5', display: '5%', },
        { value: '8', display: '8%', },
        { value: '23', display: '23%', },
    ];

    public formGroup = new FormGroup({
        vatRows: new FormArray([]),
        amountNet: new FormControl('', [Validators.required, this.validationService.amountValidator()]),
        amountVat: new FormControl('', [Validators.required, this.validationService.amountValidator()]),
        amountGross: new FormControl('', [Validators.required, this.validationService.amountValidator()]),
    }, [
        this.validationService.summaryValidator('net'),
        this.validationService.summaryValidator('vat'),
        this.validationService.summaryValidator('gross'),
        this.validationService.totalValueValidator(),
    ]);

    public get vatRows() { return this.formGroup.get('vatRows') as FormArray; }
    public get amountNet() { return this.formGroup.get('amountNet') as FormControl; }
    public get amountVat() { return this.formGroup.get('amountVat') as FormControl; }
    public get amountGross() { return this.formGroup.get('amountGross') as FormControl; }

    private destroy$: Subject<boolean> = new Subject<boolean>();
    private expectedNetValue = 0;
    private expectedVatValue = 0;
    private expectedGrossValue = 0;

    public getVatRowControl(index: number, controlName: string): FormControl {
        const vatRow = this.vatRows.controls[index] as FormGroup;
        return vatRow.get(controlName) as FormControl;
    }

    constructor(
        public amountFormatterService: AmountFormatterService,
        public detailsService: DetailsService,
        private validationService: ValidationService,
        public amountParserService: AmountParserService,
        public vatRateParserService: VatRateParserService,
    ) { }

    public ngOnInit(): void {
        this.updateFormControlsValidators();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngOnChanges(changes: SimpleChanges): void {

        if (changes.documentModel && changes.documentModel.currentValue) {

            this.detailsService.updateFormControl(
                this.amountNet,
                this.documentModel[this.amountNetFeatureName],
                this.amountFormatterService
            );
            this.detailsService.updateFormControl(
                this.amountVat,
                this.documentModel[this.amountVatFeatureName],
                this.amountFormatterService
            );
            this.detailsService.updateFormControl(
                this.amountGross,
                this.documentModel[this.amountGrossFeatureName],
                this.amountFormatterService
            );

            this.documentModel[this.vatRowsFeatureName].forEach(vatRow => this.addVatRowControls(vatRow));
            if (this.documentModel[this.vatRowsFeatureName].length === 0) {
                this.addVatRowControls();
            }

            this.detailsService.markFormGroupTouched(this.formGroup);
        }

        this.calculateExpectedSummaryAmounts();
        this.detailsService.changeEnabledFormStateBasingOnIsEditable(this.isEditable, this.formGroup);
    }

    public assignDocumentModelValues(documentModel: DocumentModel): void {

        this.detailsService.updateDocumentModelFeature(
            documentModel,
            this.amountNetFeatureName,
            this.amountNet.value,
            this.amountFormatterService
        );
        this.detailsService.updateDocumentModelFeature(
            documentModel,
            this.amountVatFeatureName,
            this.amountVat.value,
            this.amountFormatterService
        );
        this.detailsService.updateDocumentModelFeature(
            documentModel,
            this.amountGrossFeatureName,
            this.amountGross.value,
            this.amountFormatterService
        );

        documentModel[this.vatRowsFeatureName] = documentModel[this.vatRowsFeatureName].filter(x => this.vatRows.controls.some(
            y => (y as FormGroup).controls.id.value === x.id
        ));

        for (const [i, vatRowAbsrtactControl] of this.vatRows.controls.entries()) {

            const vatGroup = vatRowAbsrtactControl as FormGroup;

            let vatRow: DocumentVATFeature = null;
            if (vatGroup.controls.id.value !== null && vatGroup.controls.id.value !== 0) {
                vatRow = documentModel[this.vatRowsFeatureName].find(x => x.id === vatGroup.controls.id.value);
            } else {
                vatRow = new DocumentVATFeature();
                vatRow.id = 0;
                documentModel[this.vatRowsFeatureName].push(vatRow);
            }

            this.updateVatFormGroupToVatRow(vatGroup, vatRow, i);
        }
    }

    public canConfirm = () => true;

    public canDeactivate = () => this.formGroup.pristine;

    public getErrors = (): TemplateRef<ElementRef<any>>[] => this.errors.toArray();

    public addVatRowControls(vatFeature: DocumentVATFeature = null) {

        const net = this.formatVatFeature(vatFeature?.net?.value, this.amountFormatterService);
        const rate = this.formatVatFeature(vatFeature?.rate?.value);
        const vat = this.formatVatFeature(vatFeature?.vat?.value, this.amountFormatterService);
        const gross = this.formatVatFeature(vatFeature?.gross?.value, this.amountFormatterService);
        const id = vatFeature?.id;

        const formGroup = new FormGroup({
            id: new FormControl(id),
            net: new FormControl(net, [Validators.required, this.validationService.amountValidator()]),
            rate: new FormControl(rate, [Validators.required]),
            vat: new FormControl(vat, [Validators.required, this.validationService.amountValidator()]),
            gross: new FormControl(gross, [Validators.required, this.validationService.amountValidator()]),
        }, [ this.validationService.vatRowValidator() ]);

        this.vatRows.push(formGroup);

        if (vatFeature === null) {
            this.detailsService.featureAreas[this.vatRowsFeatureName].push(new VATFeatureArea());
        }

        formGroup.get('rate').valueChanges
            .pipe(skip(rate !== null ? 1 : 0), takeUntil(this.destroy$))
            .subscribe(_ => {
                this.autoFillVatRowFormGroup(formGroup);
            });

        formGroup.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(_ => this.calculateExpectedSummaryAmounts());
    }

    public removeVatRow(rowIndex: number) {
        this.vatRows.removeAt(rowIndex);
        this.detailsService.featureAreas[this.vatRowsFeatureName].splice(rowIndex, 1);
    }

    private formatVatFeature(value: any, formatter: IFormatter = null): string {

        if (typeof value === 'undefined' || value === null) {
            return null;
        }

        return formatter !== null ? formatter.format(value) : value;
    }

    private updateVatFormGroupToVatRow(vatGroup: FormGroup, vatRow: DocumentVATFeature, i: number): void {

        vatRow.id = vatGroup.controls.id.value === null ? vatRow.id : vatGroup.controls.id.value;

        vatRow.rate = vatRow.rate ? vatRow.rate : new DocumentFeature<string>();
        vatRow.rate.value = vatGroup.controls.rate.value;

        vatRow.net = vatRow.net ? vatRow.net : new DocumentFeature<number>();
        vatRow.net.value = parseFloat(this.amountFormatterService.parse(vatGroup.controls.net.value));

        vatRow.gross = vatRow.gross ? vatRow.gross : new DocumentFeature<number>();
        vatRow.gross.value = parseFloat(this.amountFormatterService.parse(vatGroup.controls.gross.value));

        vatRow.vat = vatRow.vat ? vatRow.vat : new DocumentFeature<number>();
        vatRow.vat.value = parseFloat(this.amountFormatterService.parse(vatGroup.controls.vat.value));

        DocumentFeature.assignArea(vatRow.rate, this.detailsService.getFeatureArea([this.vatRowsFeatureName, i.toString(), 'rate']));
        DocumentFeature.assignArea(vatRow.net, this.detailsService.getFeatureArea([this.vatRowsFeatureName, i.toString(), 'net']));
        DocumentFeature.assignArea(vatRow.gross, this.detailsService.getFeatureArea([this.vatRowsFeatureName, i.toString(), 'gross']));
        DocumentFeature.assignArea(vatRow.vat, this.detailsService.getFeatureArea([this.vatRowsFeatureName, i.toString(), 'vat']));
    }

    private updateFormControlsValidators(): void {
        const validators = [this.validationService.amountValidator()];
        if (this.isInputRequired) {
            validators.push(Validators.required);
        }

        this.amountNet.setValidators([...validators]);
        this.amountVat.setValidators([...validators]);
        this.amountGross.setValidators([...validators]);

        for (const [i, vatRow] of this.vatRows.controls.entries()) {

            this.getVatRowControl(i, 'net').setValidators([...validators]);
            this.getVatRowControl(i, 'rate').setValidators([Validators.required]);
            this.getVatRowControl(i, 'vat').setValidators([...validators]);
            this.getVatRowControl(i, 'gross').setValidators([...validators]);
        }
    }

    private autoFillVatRowFormGroup(formGroup: FormGroup): void {

        const net = formGroup.get('net') as FormControl;
        const vat = formGroup.get('vat') as FormControl;
        const gross = formGroup.get('gross') as FormControl;
        const rate = formGroup.get('rate') as FormControl;

        const netValue = parseFloat(this.amountFormatterService.parse(net.value));
        const vatValue = parseFloat(this.amountFormatterService.parse(vat.value));
        const grossValue = parseFloat(this.amountFormatterService.parse(gross.value));
        let rateValue = parseFloat(this.vatRateParserService.parse(rate.value));
        rateValue = isNaN(rateValue) ? 0 : rateValue;

        if (!isNaN(netValue)) {

            const calculatedVat = netValue * (rateValue / 100);
            vat.setValue(this.amountFormatterService.format(calculatedVat));

            const calculatedGross = netValue + calculatedVat;
            gross.setValue(this.amountFormatterService.format(calculatedGross));

            return;
        }

        if (!isNaN(grossValue)) {

            const calculatedVat = (grossValue * rateValue) / (100 + rateValue);
            vat.setValue(this.amountFormatterService.format(calculatedVat));

            const calculatedNet = grossValue - calculatedVat;
            net.setValue(this.amountFormatterService.format(calculatedNet));

            return;
        }

        if (!isNaN(vatValue)) {

            const calculatedNet = (vatValue * 100) / rateValue;
            net.setValue(this.amountFormatterService.format(calculatedNet));

            const calculatedGross = calculatedNet + vatValue;
            gross.setValue(this.amountFormatterService.format(calculatedGross));

            return;
        }
    }

    public fillAmountSummary(): void {

        this.calculateExpectedSummaryAmounts();
        this.amountNet.setValue(this.amountFormatterService.format(this.expectedNetValue));
        this.amountVat.setValue(this.amountFormatterService.format(this.expectedVatValue));
        this.amountGross.setValue(this.amountFormatterService.format(this.expectedGrossValue));
    }

    public areAmountsSameAsExpected(): boolean {

        const netValue = parseFloat(this.amountFormatterService.parse(this.amountNet.value));
        const vatValue = parseFloat(this.amountFormatterService.parse(this.amountVat.value));
        const grossValue = parseFloat(this.amountFormatterService.parse(this.amountGross.value));

        return netValue === this.expectedNetValue
            && vatValue === this.expectedVatValue
            && grossValue === this.expectedGrossValue;
    }

    private calculateExpectedSummaryAmounts(): void {

        let totalNet = 0;
        let totalVat = 0;
        let totalGross = 0;

        this.vatRows.controls.forEach(vatRowFormGroup => {

            const net = vatRowFormGroup.get('net') as FormControl;
            const vat = vatRowFormGroup.get('vat') as FormControl;
            const gross = vatRowFormGroup.get('gross') as FormControl;

            const netValue = parseFloat(this.amountFormatterService.parse(net.value));
            const vatValue = parseFloat(this.amountFormatterService.parse(vat.value));
            const grossValue = parseFloat(this.amountFormatterService.parse(gross.value));

            totalNet += !isNaN(netValue) ? netValue : 0;
            totalVat += !isNaN(vatValue) ? vatValue : 0;
            totalGross += !isNaN(grossValue) ? grossValue : 0;
        });

        this.expectedNetValue = totalNet;
        this.expectedVatValue = totalVat;
        this.expectedGrossValue = totalGross;
    }
}
