import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserRoleEnum } from 'src/app/contract/user/user-role.enum';
import { OrganizationService } from 'src/app/services/organization-service/organization.service';
import { UserService } from 'src/app/services/user-service/user.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    templateUrl: './add-organization-user-dialog.component.html',
    styleUrls: ['./add-organization-user-dialog.component.scss']
})
export class AddOrganizationUserDialogComponent {

    public isAdding = false;
    public formGroup: FormGroup = new FormGroup({
        email: new FormControl('', [Validators.required]),
        nickname: new FormControl('', [Validators.required]),
        role: new FormControl(UserRoleEnum.Guest, [Validators.required]),
    });
    public UserRoleEnum = UserRoleEnum;

    constructor(
        public dialogRef: MatDialogRef<AddOrganizationUserDialogComponent>,
        private organizationService: OrganizationService,
        private userService: UserService,
        private router: Router,
    ) { }

    public addUserToOrganization(): void {

        if (this.formGroup.invalid) {
            return;
        }

        const email = this.formGroup.controls.email.value;
        const nickname = this.formGroup.controls.nickname.value;
        const role = this.formGroup.controls.role.value;
        const organizationId = this.userService.userExtract.value.organizationId;

        const urlTree = this.router.createUrlTree(['/activate']);
        const url = `${window.location.origin}${urlTree}`;

        this.isAdding = true;
        this.organizationService.AddToOrganization(organizationId, email, nickname, role, url).subscribe(
            _ => {
                this.isAdding = false;
                this.dialogRef.close(true);
            },
            (error: HttpErrorResponse) => {

                this.isAdding = false;
                if (error.status === 400) {
                    this.formGroup.controls.email.setErrors({ emailTaken: true });
                }
            }
        );
    }
}
