import { Injectable } from '@angular/core';
import { CallService } from '../call-service/call.service';
import { UserExtract } from 'src/app/contract/user/user-extract';
import { Observable } from 'rxjs';
import { UserRoleEnum } from 'src/app/contract/user/user-role.enum';
import { OrganizationExtract } from 'src/app/contract/organization/organization-extract';

@Injectable({
    providedIn: 'root'
})
export class OrganizationService {
    private servicePrefix = 'organizations';

    constructor(private callService: CallService) {
        this.callService = callService;
    }

    public GetOrganizationUsers(organizationId: number): Observable<UserExtract[]> {
        return this.callService.get<UserExtract[]>(`${this.servicePrefix}/${organizationId}/users`);
    }

    public DeleteOrganizationUser(organizationId: number, userId: number) {
        return this.callService.delete(`${this.servicePrefix}/${organizationId}/users/${userId}`);
    }

    public AddToOrganization(
        organizationId: number,
        email: string,
        nickname: string,
        role: UserRoleEnum,
        accountActivationUrl: string
    ) {
        return this.callService.postForm(`${this.servicePrefix}/${organizationId}/users`, {
            email,
            nickname,
            role,
            accountActivationUrl
        });
    }

    public CheckIfOrganizationSuffixIsAvailable(emailSuffix: string): Observable<boolean> {
        return this.callService.get(`${this.servicePrefix}/emailSuffixAvailable/${emailSuffix}`);
    }

    public ChangeOrganizationEmailSuffix(organizationId: number, emailSuffix: string) {
        return this.callService.patchForm(`${this.servicePrefix}/${organizationId}/emailSuffix`, {
            organizationId,
            emailSuffix,
        });
    }
}
