import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth-service/auth.service';
import { HubConnection, IHttpConnectionOptions, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

@Injectable({
    providedIn: 'root'
})
export class WebSocketService {

    constructor(
        private authService: AuthService,
    ) { }

    public buildConnection(path: string): HubConnection {

        const options: IHttpConnectionOptions = {
            accessTokenFactory: () => {
                return this.authService.getToken();
            }
        };

        const connection = new HubConnectionBuilder()
            .withUrl(`${environment.apiUrl}/${path}`, options)
            .withAutomaticReconnect()
            .configureLogging(environment.websocketLogLevel)
            .build();

        connection
            .start()
            .catch(err => console.error('Connection error', err));

        return connection;
    }
}
