<div class="mobile-not-supported" *ngIf="isMobile">
    <div class="alert-box">
        <div class="top-bar">
            <span class="icon warning"></span>
        </div>

        <div class="content">
            <div class="title">
                Urządzenia mobilne nie są wspierane
            </div>
            
            <div class="description">
                Aby korzystać z aplikacji uruchom ją na komputerze.
            </div>
        </div>
    </div>
</div>

<router-outlet *ngIf="!isMobile"></router-outlet>
