import { Injectable } from '@angular/core';
import { IParser } from '../iparser';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class DateParserService implements IParser {

    constructor() { }

    public parse(value: string): string {

        const parseFormats = [
            'DD.MM.YYYY',
            'YYYY-MM-DD',
        ];

        for (const format of parseFormats) {
            const date = moment(value, format).toDate();

            if (this.isValidDate(date)) {
                return date.toISOString();
            }
        }

        return null;
    }

    private isValidDate(date: Date): boolean {
        return date.getTime() === date.getTime();
    }
}
