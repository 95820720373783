<div class="container">
    <div class="background">
        <div class="section-header no-margin">Ustawienia konta</div>
    </div>
</div>

<div class="container">
    <section>
        <div class="description">
            <span class="text">
                Zmień personalne ustawienia w aplikacji.
            </span>
            <div class="call-to-action">
                <div class="status" *ngIf="isPasswordChanged">
                    <span class="icon success"></span>
                    Hasło zostało zmienione.
                </div>
                <button mat-stroked-button color="primary" (click)="openChangePasswordDialog()">Zmień hasło</button>
            </div>
        </div>

        <form [formGroup]="accountForm" (ngSubmit)="submit()">
    
            <div class="setting-wrapper">
                <div class="setting-input">
                    <div class="label">Nazwa użytkownika</div>
                    <mat-form-field
                        floatLabel="never"
                        class="custom"
                        appearance="standard"
                    >
                        <input
                            type="text"
                            matInput
                            placeholder="Jan Kowalski"
                            formControlName="nickname"
                        />
                    </mat-form-field>
                </div>
            </div>

            <div class="form-actions">
                <button
                    mat-raised-button
                    color="primary"
                    type="submit"
                    class="submit-button with-loader"
                    [class.is-loading]="isSaving"
                >
                    <div class="content">
                        Zapisz
                    </div>
                    <div class="loader">
                        <div class="loader-wrapper">
                            <span class="icon loading"></span>
                        </div>
                    </div>
                </button>

                <div class="status">
                    <span *ngIf="isSaved">Zapisano zmiany pomyślnie</span>
                </div>
            </div>
        </form>
    </section>
</div>
