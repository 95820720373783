import { Directive, HostListener, EventEmitter, Output, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
    selector: '[digicaDragDrop]'
})
export class DragDropDirective implements OnInit, OnDestroy {

    @Output() private filesDropped: EventEmitter<FileList> = new EventEmitter();
    @Output() private dragEvent: EventEmitter<boolean> = new EventEmitter();
    private debouncer = new Subject<boolean>();
    private debouncerSubscription: Subscription;
    private currentState = false;

    constructor() { }

    public ngOnInit(): void {

        this.debouncerSubscription = this.debouncer
            .pipe(debounceTime(50))
            .subscribe(state => {
                this.dragEvent.emit(state);
            });
    }

    public ngOnDestroy(): void {

        this.debouncer.unsubscribe();

        if (this.debouncerSubscription) {
            this.debouncerSubscription.unsubscribe();
        }
    }

    private queueNewState(state: boolean): void {

        if (this.currentState !== state) {
            this.currentState = state;
            this.debouncer.next(state);
        }
    }

    @HostListener('dragover', ['$event'])
    public onDragOver($event: DragEvent) {
        $event.preventDefault();
        $event.stopPropagation();

        this.queueNewState(true);
    }

    @HostListener('dragleave', ['$event'])
    public onDragLeave($event: DragEvent) {
        $event.preventDefault();
        $event.stopPropagation();

        this.queueNewState(false);
    }

    @HostListener('drop', ['$event'])
    public onDrop($event: DragEvent) {
        $event.preventDefault();
        $event.stopPropagation();

        this.queueNewState(false);

        const fileList: FileList = $event.dataTransfer.files;
        this.filesDropped.emit(fileList);
    }
}
