<div class="connected-document-overlay" *ngIf="isActive">
    <div class="header">
        <h2>Połącz dokument</h2>
        
        <div class="actions">
            <a (click)="abortConnecting()">
                <span class="icon close"></span>
            </a>
        </div>
    </div>
    <div class="description">Połącz dokumenty dla kontrahenta z NIP <b>{{ taxNumber }}</b>.</div>

    <form [formGroup]="formGroup">
        <div class="input-wrapper documentNumber">
            <div class="label">Numer dokumentu</div>
            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    placeholder="Numer dokumentu"
                    [formControl]="documentNumber"
                    autocomplete="off"
                />
            </mat-form-field>
        </div>

        <div class="input-wrapper documentType">
            <div class="label">Typ dokumentu</div>

            <mat-form-field floatLabel="never" class="custom">
                <mat-label>Wybierz typ</mat-label>
                <mat-select matInput [formControl]="documentType" multiple>
                    <mat-select-trigger>
                        <span *ngIf="documentType.value">
                            <digica-document-type [documentType]="documentType.value[0]"></digica-document-type>
                        </span>
                        <span *ngIf="documentType.value?.length > 1" class="select-trigger-other">
                            (+{{ documentType.value.length - 1 }} inne)
                        </span>
                    </mat-select-trigger>

                    <mat-option
                        *ngFor="let enum of DocumentTypeEnum | enumToArray"
                        [value]="enum"
                    >
                        <digica-document-type
                            [documentType]="enum"
                        ></digica-document-type>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>

    <div class="table">
        <div class="head">
            <div class="row">
                <div class="cell label documentNumber">
                    Numer dokumentu
                </div>
                <div class="cell label contractor">
                    Kontrahent
                </div>
                <div class="cell label receiptDate">
                    Data wpływu
                </div>
                <div class="cell label action"></div>
            </div>
            <div class="separator"></div>
        </div>
        <div class="body" *ngIf="!isLoading">
            <ng-container *ngFor="let documentExtract of documentExtracts; trackBy: documentExtractTrackBy">
                <a class="row" target="_blank" [routerLink]="['/archive', documentExtract.id]">
                    <div class="cell documentNumber">
                        {{ documentExtract.documentNumber }}
                    </div>
                    <div class="cell contractor">
                        <div class="contractorName">
                            <div class="ellipsis" [matTooltip]="documentExtract.contractorName">
                                <span>{{ documentExtract.contractorName }}</span>
                            </div>
                        </div>
                        <div class="addon taxNumber">
                            {{ documentExtract.contractorTaxNumber }}
                        </div>
                    </div>
                    <div class="cell receiptDate">
                        {{ documentExtract.receiptDate | date }}
                    </div>
                    <div class="cell action">
                        <button
                            mat-stroked-button
                            color="primary"
                            (click)="finishConnecting(documentExtract, $event)"
                        >
                            Połącz
                        </button>
                    </div>
                </a>
                <div class="separator"></div>
            </ng-container>
        </div>

        <div class="loading-status">
            <div *ngIf="isLoading">
                <div class="icon-wrapper loading">
                    <span class="icon loading"></span>
                </div>
                <span class="text-wrapper">
                    Trwa ładowanie dokumentów
                </span>
            </div>

            <div *ngIf="!isLoading">
                <span class="text-wrapper">
                    Załadowano <b>{{ documentExtracts.length }}</b> dokumentów spełniających kryteria wyszukiwania
                </span>
            </div>
        </div>
    </div>
</div>
