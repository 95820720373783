<div class="container flex">
    <div class="logo-container">
        <img src="/assets/logos/digica_wText_horizontal.svg" alt="Digica" />
    </div>

    <div class="actions">
        <div class="user-section">
            <div class="user-name" [title]="nickname">{{ nickname }}</div>
            <div class="organization-name">{{ organizationName }}</div>
        </div>

        <button mat-icon-button [matMenuTriggerFor]="menu" class="main-menu-icon">
            <span class="icon menu"></span>
        </button>
        <mat-menu #menu="matMenu" class="custom-icon-menu">
            <a mat-menu-item [routerLink]="['/settings']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
                <span class="icon settings" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"></span>
                <span>Ustawienia</span>
            </a>

            <a mat-menu-item [routerLink]="['/analytics']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
                <span class="icon analytics" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"></span>
                <span>Skuteczność</span>
            </a>

            <a mat-menu-item [routerLink]="['/automations']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
                <span class="icon automations" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"></span>
                <span>Automatyzacje</span>
            </a>

            <mat-divider></mat-divider>
            <button mat-menu-item (click)="logOut()">
                <span class="icon logout"></span>
                <span>Wyloguj</span>
            </button>
        </mat-menu>
    </div>
</div>
