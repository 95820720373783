import { Component, SimpleChanges, OnChanges, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { DocumentConnection } from 'src/app/contract/document/document-connection.model';
import { DocumentExtract } from 'src/app/contract/document/document-extract';
import { DocumentTypeEnum } from 'src/app/contract/document/document-type.enum';
import { DocumentModel } from 'src/app/contract/document/document.model';
import { ConnectedDocumentService } from '../connected-document/connected-document.service';
import { IDocumentDetails } from '../document-details.interface';
import { DetailsService } from '../services/details/details.service';

@Component({
    selector: 'digica-connected-documents-list',
    templateUrl: './connected-documents-list.component.html',
    styleUrls: ['./connected-documents-list.component.scss']
})
export class ConnectedDocumentsListComponent implements IDocumentDetails, OnChanges, OnDestroy {

    @Input() public documentModel: DocumentModel;
    @Input() public isEditable: boolean;

    public documentConnections: Array<DocumentConnection> = [];
    public DocumentTypeEnum = DocumentTypeEnum;

    private connectingDocumentSubscription: Subscription;
    private isModified = false;

    constructor(
        private connectedDocumentService: ConnectedDocumentService,
        private detailsService: DetailsService,
    ) { }

    public ngOnChanges(changes: SimpleChanges): void {

        if (!changes.documentModel || !changes.documentModel.currentValue) {
            return;
        }

        this.documentConnections = JSON.parse(JSON.stringify(this.documentModel.documentConnections));
    }

    public ngOnDestroy(): void {

        if (this.connectingDocumentSubscription) {
            this.connectingDocumentSubscription.unsubscribe();
        }
    }

    public assignDocumentModelValues(documentModel: DocumentModel): void {

        documentModel.documentConnections = this.documentConnections;
    }

    public canDeactivate = () => !this.isModified;
    public canConfirm = () => true;
    public getErrors = () => [];

    public removeConnection(index: number): void {

        this.isModified = true;
        this.documentConnections.splice(index, 1);
    }

    public createNewConnection(): void {

        this.connectingDocumentSubscription = this.connectedDocumentService
            .openConnectingDocument(this.documentModel.documentId, this.documentConnections)
            .pipe(take(1), filter(x => x !== null))
            .subscribe((documentExtract: DocumentExtract) => {

                this.isModified = true;
                this.documentConnections.push({
                    id: 0,
                    documentId: documentExtract.id,
                    documentType: documentExtract.documentType,
                    isDuplicate: documentExtract.isDuplicate,
                    documentNumber: documentExtract.documentNumber,
                });
            });
    }

    public isCreateNewConnectionDisabled(): boolean {
        return this.detailsService.contractorTaxNumberControl.value === '';
    }
}
