import {
    Component,
    Input,
    AfterViewInit,
    ViewChild,
    ElementRef,
    HostListener,
    AfterViewChecked,
    OnInit,
    OnDestroy
} from '@angular/core';
import { Point } from '../../services/interactive-image-service/point';
import { InteractiveImageService } from 'src/app/services/interactive-image-service/interactive-image.service';
import { ImageService } from 'src/app/services/image-service/image.service';
import { Area } from 'src/app/services/interactive-image-service/area';
import { Subscription } from 'rxjs';
import { OcrBox } from 'src/app/contract/document/ocr-box';
import { DocumentService } from 'src/app/services/document-service/document.service';

@Component({
    selector: 'digica-document-image',
    templateUrl: './document-image.component.html',
    styleUrls: ['./document-image.component.scss']
})
export class DocumentImageComponent implements AfterViewInit, AfterViewChecked, OnInit, OnDestroy {

    @Input() documentId: number;
    @Input() originalWidth: number;
    @Input() originalHeight: number;

    @ViewChild('image') image: ElementRef;
    @ViewChild('canvasContainer') canvasContainer: ElementRef;
    @ViewChild('canvas') canvas: ElementRef;
    public context: CanvasRenderingContext2D;

    public isLoading = true;
    public source: string | ArrayBuffer;

    private imageSourceSubscription: Subscription;
    public page: number;
    public imageWidth: number;
    public imageHeight: number;
    public drawWidth: number;
    public drawHeight: number;
    public drawOffset = new Point(0, 0);

    public zoomPosition = new Point(0, 0);
    public zoom = 1;

    private focusedArea: Area;
    private focusedAreaSubscription: Subscription;
    private ocrBoxSubscription: Subscription;
    private documentOcrBoxes: Array<OcrBox>;
    private ocrboxes: Array<OcrBox>;
    private selectStartPoint: Point = null;
    private selectEndPoint: Point;
    private pageChangedSubscription: Subscription;

    constructor(
        private documentService: DocumentService,
        private interactiveImageService: InteractiveImageService,
        private imageService: ImageService,
    ) { }

    private updateMagnifier(point: Point) {

        if (point.x < 0 || point.x > this.originalWidth || point.y < 0 || point.y > this.originalHeight) {
            return this.onMouseLeave();
        }

        this.interactiveImageService.calculateZoomPosition(this, point);
    }

    private draw() {
        const widthShrink = this.imageWidth / this.canvas.nativeElement.width;
        const heightShrink = this.imageHeight / this.canvas.nativeElement.height;

        this.drawHeight = this.canvas.nativeElement.height;
        this.drawWidth = (this.imageWidth * this.drawHeight) / this.imageHeight;
        if (widthShrink > heightShrink) {
            this.drawWidth = this.canvas.nativeElement.width;
            this.drawHeight = (this.imageHeight * this.drawWidth) / this.imageWidth;
        }

        this.drawOffset.x = (this.canvas.nativeElement.width - this.drawWidth) / 2;
        this.drawOffset.y = (this.canvas.nativeElement.height - this.drawHeight) / 2;

        this.context.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
        this.context.drawImage(
            this.image.nativeElement,
            this.zoomPosition.x,
            this.zoomPosition.y,
            this.imageWidth / this.zoom,
            this.imageHeight / this.zoom,
            this.drawOffset.x,
            this.drawOffset.y,
            this.drawWidth,
            this.drawHeight
        );

        this.drawFocusedArea();
        this.drawSelectedArea();

        requestAnimationFrame(() => this.draw());
    }

    public ngOnInit(): void {
        this.watchPageChanged();
    }

    public ngOnDestroy(): void {
        this.clearSubscriptions();

        if (this.pageChangedSubscription) {
            this.pageChangedSubscription.unsubscribe();
        }
    }

    private clearSubscriptions(): void {

        if (this.focusedAreaSubscription) {
            this.focusedAreaSubscription.unsubscribe();
        }

        if (this.imageSourceSubscription) {
            this.imageSourceSubscription.unsubscribe();
        }

        if (this.ocrBoxSubscription) {
            this.ocrBoxSubscription.unsubscribe();
        }
    }

    private drawOcrBox(ocrbox: OcrBox) {

        const p1 = this.interactiveImageService.convertImagePointToCanvas(
            this,
            ocrbox.x,
            ocrbox.y
        );
        const p2 = this.interactiveImageService.convertImagePointToCanvas(
            this,
            ocrbox.x + ocrbox.width,
            ocrbox.y + ocrbox.height
        );

        this.context.fillRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
    }

    private drawFocusedArea() {

        if (!this.focusedArea || this.focusedArea.page !== this.page) {
            return;
        }

        const p1 = this.interactiveImageService.convertImagePointToCanvas(
            this,
            this.focusedArea.x,
            this.focusedArea.y
        );
        const p2 = this.interactiveImageService.convertImagePointToCanvas(
            this,
            this.focusedArea.x + this.focusedArea.width,
            this.focusedArea.y + this.focusedArea.height
        );

        this.context.fillStyle = 'rgba(52, 152, 219, 0.3)';
        this.context.fillRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);

        this.context.fillStyle = 'rgba(230, 126, 34, 0.5)';
        const ocrboxes = this.interactiveImageService.getOcrBoxesInside(this.ocrboxes, this.focusedArea);
        for (const ocrbox of ocrboxes) {
            this.drawOcrBox(ocrbox);
        }
    }

    private drawSelectedArea(): void {

        if (!this.selectStartPoint || !this.selectEndPoint) {
            return;
        }

        const area = Area.createFromPoints(
            this.interactiveImageService.convertImagePointToCanvas(this, this.selectStartPoint.x, this.selectStartPoint.y),
            this.interactiveImageService.convertImagePointToCanvas(this, this.selectEndPoint.x, this.selectEndPoint.y),
            this.page
        );

        const selectArea = Area.createFromPoints(this.selectStartPoint, this.selectEndPoint, this.page);
        const ocrboxes = this.interactiveImageService.getOcrBoxesInside(this.ocrboxes, selectArea);

        this.context.fillStyle = 'rgba(232, 129, 141, 0.5)';
        for (const ocrbox of ocrboxes) {
            this.drawOcrBox(ocrbox);
        }

        this.context.strokeStyle = 'rgba(46, 204, 113, 1.0)';
        this.context.fillStyle = 'rgba(46, 204, 113, 0.1)';
        this.context.beginPath();
        this.context.rect(area.x, area.y, area.width, area.height);
        this.context.fill();
        this.context.stroke();
    }

    public ngAfterViewInit(): void {
        this.context = (this.canvas.nativeElement as HTMLCanvasElement).getContext('2d');
    }

    public ngAfterViewChecked(): void {
        this.interactiveImageService.resizeCanvasToParent(this);
    }

    @HostListener('mousemove', ['$event'])
    public onMouseMove(event: MouseEvent) {

        const point = this.interactiveImageService.convertCanvasPointToImage(this, event.offsetX, event.offsetY);
        this.updateMagnifier(point);

        this.selectEndPoint = this.interactiveImageService.convertCanvasPointToImage(this, event.offsetX, event.offsetY);
    }

    @HostListener('mouseleave')
    public onMouseLeave() {
        this.zoomPosition.set(0, 0);
        this.zoom = 1;
    }

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent) {

        this.selectStartPoint = this.interactiveImageService.convertCanvasPointToImage(this, event.offsetX, event.offsetY);
        this.selectEndPoint = this.interactiveImageService.convertCanvasPointToImage(this, event.offsetX, event.offsetY);
    }

    @HostListener('mouseup', ['$event'])
    onMouseUp(event: MouseEvent) {

        if (this.selectStartPoint === null) {
            return;
        }

        this.selectEndPoint = this.interactiveImageService.convertCanvasPointToImage(this, event.offsetX, event.offsetY);
        const area = Area.createFromPoints(this.selectStartPoint, this.selectEndPoint, this.page);
        const ocrboxesInside = this.interactiveImageService.getOcrBoxesInside(this.ocrboxes, area);
        const text = this.interactiveImageService.readOcrBoxes(ocrboxesInside);
        this.interactiveImageService.selectedCallback.value(text, area);

        this.selectStartPoint = null;
        this.selectEndPoint = null;
    }

    public onImageLoad(): void {

        this.isLoading = false;
        this.imageWidth = (this.image.nativeElement as HTMLImageElement).width;
        this.imageHeight = (this.image.nativeElement as HTMLImageElement).height;
        this.interactiveImageService.resizeCanvasToParent(this);
        this.draw();
    }

    public watchPageChanged(): void {

        this.pageChangedSubscription = this.interactiveImageService.currentPage.subscribe(page => {

            this.page = page;
            this.clearSubscriptions();

            this.source = '';
            this.isLoading = true;
            const url = this.documentService.GetDocumentPageImageUrl(this.documentId, this.page, 'big');
            this.imageSourceSubscription = this.imageService.getImageSource(url, result => {
                this.source = result;
            });

            this.focusedAreaSubscription = this.interactiveImageService.focusedFeatureArea.subscribe(area => {
                this.focusedArea = area;
            });

            if (this.documentOcrBoxes) {
                this.ocrboxes = this.documentOcrBoxes.filter(x => x.page === this.page);
                return;
            }

            this.ocrBoxSubscription = this.documentService.GetOcrBoxes(this.documentId).subscribe(ocrboxes => {
                this.documentOcrBoxes = ocrboxes;
                this.ocrboxes = ocrboxes.filter(x => x.page === this.page);
            });
        });
    }
}
