<div class="background gray">
    <div class="container menu">
        <div class="label-container">
            <span class="label">Wyświetl</span>
        </div>

        <div class="menu-items">
            <a (click)="setShowFilter('all')" [class.active]="isShowFilterActive('all')">
                Wszystkie pliki <span class="counter" *ngIf="counter">{{ counter.all }}</span>
            </a>

            <a (click)="setShowFilter('my')" [class.active]="isShowFilterActive('my')">
                Moje pliki <span class="counter" *ngIf="counter">{{ counter.my }}</span>
            </a>

            <a (click)="setShowFilter('e-invoices')" [class.active]="isShowFilterActive('e-invoices')">
                E-faktury <span class="counter" *ngIf="counter">{{ counter.eInvoices }}</span>
            </a>
        </div>
    </div>
</div>

<div class="container">
    <div class="search-box">
        <mat-form-field floatLabel="never" class="custom">
            <span matPrefix class="icon search"></span>
            <input
                matInput
                placeholder="Wyszukaj kontrahenta, numer dokumentu, NIP..."
                [formControl]="search"
                autocomplete="off"
            />
        </mat-form-field>
    </div>
</div>