import { Component } from '@angular/core';
import { UploadStatus } from 'src/app/contract/upload/upload-status';
import { DocumentService } from 'src/app/services/document-service/document.service';

@Component({
    selector: 'digica-document-upload',
    templateUrl: './document-upload.component.html',
    styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent {

    public allowedExtensions = ['pdf'];
    public UploadStatus = UploadStatus;
    public isDragOver = false;
    public uploadingFiles = new Array();

    constructor(
        private documentService: DocumentService,
    ) { }

    public onFilesSelected($event: any): void {
        const fileList: FileList = $event.target.files;
        this.uploadFiles(fileList);
    }

    public onFilesDropped(fileList: FileList): void {
        this.uploadFiles(fileList);
    }

    private uploadFiles(fileList: FileList): void {

        const size = fileList.length;
        for (let i = 0; i < size; i += 1) {
            this.uploadFile(fileList[i]);
        }
    }

    public uploadFile(file: File): void {

        const uploadingFile = {
            name: file.name,
            size: this.convertSizeToHumanReadable(file.size),
            isUploadFinished: false,
            uploadStatus: null,
        };
        this.uploadingFiles.push(uploadingFile);

        const extension = file.name.split('.').slice(-1)[0].toLowerCase();
        if (!this.allowedExtensions.includes(extension)) {
            uploadingFile.isUploadFinished = true;
            uploadingFile.uploadStatus = UploadStatus.InvalidExtension;
            return;
        }

        this.documentService.UploadDocument(file).subscribe(
            result => {
                uploadingFile.isUploadFinished = true;
                uploadingFile.uploadStatus = result.status;
            },
            _ => {
                uploadingFile.isUploadFinished = true;
                uploadingFile.uploadStatus = UploadStatus.Error;
            }
        );
    }

    private convertSizeToHumanReadable(size: number): string {

        if (size < 1024) {
            return `${size}b`;
        }

        size = Math.round(size / 1024);
        if (size < 1024) {
            return `${size}kb`;
        }

        size = Math.round(size / 1024);
        return `${size}mb`;
    }
}
