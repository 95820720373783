<div
    *ngIf="!automated && result === AutomationEntryDocumentResultEnum.Success"
    class="automation-icon success"
    matTooltip="Dokument został zaakceptowany bez wprowadzenia zmian"
></div>

<div
    *ngIf="!automated && result === AutomationEntryDocumentResultEnum.Error"
    class="automation-icon error"
    matTooltip="Dokument został zaakceptowany po wprowadzeniu zmian"
></div>

<div
    *ngIf="automated && result === AutomationEntryDocumentResultEnum.Warning"
    class="automation-icon automated warning"
    matTooltip="Dokument nie spełnił reguł automatyzacji, konieczne było potwierdzenie przez użytkownika"
></div>

<div
    *ngIf="automated && result === AutomationEntryDocumentResultEnum.Success"
    class="automation-icon automated success"
    matTooltip="Dokument został automatycznie potwierdzony"
></div>

<div
    *ngIf="automated && result === AutomationEntryDocumentResultEnum.Error"
    class="automation-icon automated error"
    matTooltip="Dokument został automatycznie potwierdzony, uzytkownik zmienił wykryte wartości w archiwum"
></div>
