<div class="container">
    <header class="logo-container">
        <img src="/assets/logos/digica_wText_horizontal.svg" alt="Digica">
    </header>

    <div class="auth-container">
        <form [formGroup]="form" (ngSubmit)="activate()"> 
            <div class="auth-box">
                <h1>Aktywacja konta</h1>

                <ng-container *ngIf="isActivating">
                    <div class="status">
                        <div class="icon-wrapper loading">
                            <span class="icon loading"></span>
                        </div>

                        <div class="text">
                            Trwa aktywowanie konta, to nie powinno zająć długo.
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="!isActivating && isActivated">
                    <div class="status">
                        <div class="icon-wrapper success">
                            <span class="icon success"></span>
                        </div>

                        <div class="text">
                            Konto zostało aktywowane, możesz się teraz zalogować.
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="isError">
                    <div class="status">
                        <div class="icon-wrapper error">
                            <span class="icon error"></span>
                        </div>

                        <div class="text">
                            Wystąpił błąd podczas aktywacji konta.
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="!isActivating && !isActivated && !isError">
                    <div class="text-wrapper">
                        Utwórz swoje hasło do systemu:
                    </div>
                    <div class="input-wrapper">
                        <div class="label">
                            Hasło
                        </div>
                        <mat-form-field floatLabel="never" class="custom">
                            <input
                                matInput
                                formControlName="password"
                                type="password"
                            />
                            <mat-error *ngIf="password.hasError('required')">
                                Wprowadź hasło
                            </mat-error>
                            <mat-error *ngIf="password.hasError('minlength')">
                                Hasło musi składać się minimum z {{ password.errors.minlength.requiredLength }} znaków
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <button
                        type="submit"
                        mat-flat-button
                        color="primary"
                        class="submit-button with-loader"
                        [disabled]="this.form.invalid"
                    >
                        <div class="content">
                            Zarejestruj się
                        </div>
                    </button>
                </ng-container>

                <div class="links">
                    <a [routerLink]="['/login']">Przejdź do logowania</a>
                </div>
            </div>
        </form>
    </div>
</div>
