<form [formGroup]="formGroup" (ngSubmit)="addUserToOrganization()" method="POST" action="/change-password">
    <h2 mat-dialog-title>Zmiana hasła</h2>

    <mat-dialog-content>
        <section>
            <div class="setting-wrapper">
                <div class="setting-input">
                    <label class="label" for="current-password">
                        Obecne hasło
                    </label>
                    <mat-form-field floatLabel="never" class="custom">
                        <input
                            type="password"
                            matInput
                            formControlName="password"
                            autocomplete="current-password"
                            name="current-password"
                            id="current-password"
                        />
                        <mat-error
                            *ngIf="
                                formGroup.controls['password'].hasError('required')
                            "
                        >
                            Wprowadź obecne hasło
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="setting-wrapper">
                <div class="setting-input">
                    <label class="label" for="new-password">
                        Nowe hasło
                    </label>
                    <mat-form-field floatLabel="never" class="custom">
                        <input
                            type="password"
                            matInput
                            formControlName="newPassword"
                            autocomplete="new-password"
                            name="newPassword"
                            id="new-password"
                        />
                        <mat-error *ngIf="formGroup.controls['newPassword'].hasError('required')">
                            Wprowadź nowe hasło
                        </mat-error>
                        <mat-error *ngIf="formGroup.controls['newPassword'].hasError('minlength')">
                            Hasło musi składać się z minimum {{ formGroup.controls['newPassword'].errors.minlength.requiredLength }} znaków
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="setting-wrapper">
                <div class="setting-input">
                    <label class="label" for="confirm-new-password">
                        Powtórz nowe hasło
                    </label>
                    <mat-form-field floatLabel="never" class="custom">
                        <input
                            type="password"
                            matInput
                            formControlName="reNewPassword"
                            autocomplete="new-password"
                            name="confirm-new-password"
                            id="confirm-new-password"
                        />
                        <mat-error *ngIf="formGroup.controls['reNewPassword'].hasError('required')">
                            Potwierdź nowe hasło
                        </mat-error>
                        <mat-error *ngIf="formGroup.controls['reNewPassword'].hasError('mismatch')">
                            Powtórzone hasło różni się od poprzedniego
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="input-wrapper">
                <mat-error class="default">
                    <span *ngIf="formGroup.errors && formGroup.errors.errorOccurred">
                        Wystąpił błąd podczas zmiany hasła, spróbuj ponownie później
                    </span>
                </mat-error>
            </div>

            <div class="input-wrapper">
                <mat-error class="default">
                    <span *ngIf="formGroup.errors && formGroup.errors.invalidPassword">
                        Podane obecne hasło jest nieprawidłowe
                    </span>
                </mat-error>
            </div>
        </section>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button
            mat-stroked-button
            type="button"
            class="size-normal"
            color="primary"
            (click)="cancelClick()"
        >
            Anuluj
        </button>

        <button
            mat-flat-button
            class="secondary-button with-loader"
            color="primary"
            [class.is-loading]="isChanging"
        >
            <div class="content">
                Zmień
            </div>
            <div class="loader">
                <div class="loader-wrapper">
                    <span class="icon loading"></span>
                </div>
            </div>
        </button>
    </mat-dialog-actions>
</form>
