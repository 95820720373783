import { Component, OnInit, OnDestroy, Inject, ViewChildren, HostListener } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AttachmentListDialogComponent } from '../attachment-list-dialog/attachment-list-dialog.component';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { QueryList } from '@angular/core';
import { IDocumentDetails } from 'src/app/document-details/document-details.interface';
import { DetailsService } from 'src/app/document-details/services/details/details.service';
import { InteractiveImageService } from 'src/app/services/interactive-image-service/interactive-image.service';
import { FormGroup } from '@angular/forms';
import { DocumentModel } from 'src/app/contract/document/document.model';
import { DocumentStatusEnum } from 'src/app/contract/document/document-status.enum';
import { DocumentService } from 'src/app/services/document-service/document.service';
import { DocumentDeleteDialogComponent } from '../document-delete-dialog/document-delete-dialog.component';
import { DocumentConfirmDialogData } from '../document-confirm-dialog/document-confirm-dialog.data';
import { DocumentConfirmDialogComponent } from '../document-confirm-dialog/document-confirm-dialog.component';
import { DocumentListService } from '../document-list/document-list.service';
import { DocumentTypeEnum } from 'src/app/contract/document/document-type.enum';
import { ApprovementTypeEnum } from 'src/app/contract/document/approvement-type.enum';
import { AutomationService } from 'src/app/services/automation-service/automation.service';
import {
    DisableAutomationWhenCorrectingDialogComponent
} from 'src/app/documents/disable-automation-when-correcting-dialog/disable-automation-when-correcting-dialog.component';
import {
    DisableAutomationWhenCorrectingDialogData
} from '../disable-automation-when-correcting-dialog/disable-automation-when-correcting-dialog.data';

@Component({
    templateUrl: './document-details-dialog.component.html',
    styleUrls: ['./document-details-dialog.component.scss']
})
export class DocumentDetailsDialogComponent implements OnInit, OnDestroy {

    @ViewChildren('documentDetails') documentDetails: QueryList<IDocumentDetails>;

    public isLoading = false;
    public isConfirming = false;
    public isSaving = false;
    public isEditable = false;
    public documentModel: DocumentModel;
    public formGroup = new FormGroup({});
    public DocumentTypeEnum = DocumentTypeEnum;
    public DocumentStatusEnum = DocumentStatusEnum;
    public showConfirmButton = true;

    private shouldCallTurnOffEdit = false;
    private refreshEditStatusInterval: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) public id: number,
        private documentService: DocumentService,
        private dialog: MatDialog,
        private titleService: Title,
        private documentListService: DocumentListService,
        private dialogRef: MatDialogRef<DocumentDetailsDialogComponent>,
        private detailsService: DetailsService,
        public interactiveImageService: InteractiveImageService,
        private automationService: AutomationService,
    ) { }

    public ngOnInit(): void {

        this.titleService.setTitle(`Dokument | ${environment.applicationTabName}`);
        this.refreshEditStatusInterval = timer(0, 60000).subscribe(() => this.tryTurnOnEdit());
        this.reloadDocument();
    }

    public ngOnDestroy(): void {

        if (this.refreshEditStatusInterval) {
            this.refreshEditStatusInterval.unsubscribe();
        }

        if (this.shouldCallTurnOffEdit) {
            this.documentService.TurnOffEdit(this.id).subscribe();
        }
    }

    private reloadDocument(): void {
        this.isLoading = true;

        this.documentService.GetDocumentDetails(this.id).subscribe(documentModel => {

            this.documentModel = documentModel;
            this.detailsService.generateFeatureAreas(documentModel);
            this.showConfirmButton = documentModel.currentStatus.value !== DocumentStatusEnum.Archived;
            this.isEditable = documentModel.currentStatus.value === DocumentStatusEnum.ToConfirm;
            if (documentModel.documentNumber !== null) {
                this.titleService.setTitle(`${documentModel.documentNumber} | ${environment.applicationTabName}`);
            }

            this.isLoading = false;
        });
    }

    public onPageNavigation(page: number): void {
        this.interactiveImageService.focusedFeatureArea.next(null);
        this.interactiveImageService.currentPage.next(page);
        this.interactiveImageService.featurePageSwitcher.next(null);
    }

    private tryTurnOnEdit(): void {

        this.shouldCallTurnOffEdit = true;
        this.documentService.TurnOnEdit(this.id).subscribe(
            _ => { },
            error => {
                if (error.status === 409) {
                    this.shouldCallTurnOffEdit = false;
                    this.dialogRef.close(false);
                }
            }
        );
    }

    public onSubmit(isConfirming: boolean): void {

        if (!this.isEditable || this.isSaving || this.isConfirming) {
            return;
        }

        if (!isConfirming) {
            this.isSaving = true;
            this.save(isConfirming);
            return;
        }

        if (!this.canConfirm()) {
            return;
        }

        this.isConfirming = true;

        const errors = [].concat.apply([], this.documentDetails.toArray().map(x => x.getErrors()));
        if (errors.length === 0) {
            this.save(isConfirming);
            return;
        }

        const data: DocumentConfirmDialogData = { errors };
        const dialogRef = this.dialog.open(DocumentConfirmDialogComponent, { data, autoFocus: false });

        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                this.isConfirming = false;
                return;
            }

            this.save(isConfirming);
        });
    }

    public save(isConfirming: boolean): void {

        const documentModel = JSON.parse(JSON.stringify(this.documentModel));
        documentModel.currentStatus.value = isConfirming ? DocumentStatusEnum.Archived : documentModel.currentStatus.value;
        this.documentDetails.forEach(x => x.assignDocumentModelValues(documentModel));

        if (this.documentModel.approvementType === ApprovementTypeEnum.Automated) {

            this.automationService
                .getAutomationEntryPropositions(10, 0, documentModel.documentType, documentModel.contractorTaxNumber)
                .subscribe(automationEntryExtracts => {
                    const automationEntryExtract = automationEntryExtracts
                        .find(x => x.contractorTaxNumber === documentModel.contractorTaxNumber);

                    if (!automationEntryExtract || !automationEntryExtract.isAutomated) {
                        this.submitDocumentModel(documentModel);
                        return;
                    }

                    const corrections = this.detailsService.getCorrections(this.documentModel, documentModel);
                    if (corrections.length === 0) {
                        this.submitDocumentModel(documentModel);
                        return;
                    }

                    const data: DisableAutomationWhenCorrectingDialogData = {
                        automationEntryExtract,
                        corrections,
                        documentType: documentModel.documentType,
                    };
                    const dialogRef = this.dialog.open(DisableAutomationWhenCorrectingDialogComponent, { data, autoFocus: false });

                    dialogRef.afterClosed().subscribe(result => {
                        if (!result) {
                            return;
                        }

                        this.submitDocumentModel(documentModel);
                    });
                });

            return;
        }

        this.submitDocumentModel(documentModel);
    }

    private submitDocumentModel(documentModel: DocumentModel): void {

        this.documentService.UpdateDocumentDetails(documentModel).subscribe(
            _ => {
                this.isSaving = false;
                this.isConfirming = false;

                this.documentListService.refresh.next();
                this.dialogRef.close(true);
            },
            _ => {
                this.isConfirming = false;
                this.isSaving = false;
                this.formGroup.setErrors({ errorOccurred: true });
            }
        );
    }

    public onAltPressed(event: KeyboardEvent): void {

        const pageSwitcher = this.interactiveImageService.featurePageSwitcher.value;
        if (pageSwitcher === null || pageSwitcher.focusedFeaturePage === null) {
            return;
        }
        event.preventDefault();

        if (pageSwitcher.focusedFeatureReturnPage !== null) {
            this.interactiveImageService.currentPage.next(pageSwitcher.focusedFeatureReturnPage);
            this.interactiveImageService.featurePageSwitcher.next({
                ...pageSwitcher,
                focusedFeatureReturnPage: null,
            });
            return;
        }

        this.interactiveImageService.featurePageSwitcher.next({
            ...pageSwitcher,
            focusedFeatureReturnPage: this.interactiveImageService.currentPage.value,
        });
        this.interactiveImageService.currentPage.next(pageSwitcher.focusedFeaturePage);
    }

    public downloadOriginalFile(): void {
        this.documentService.DownloadDocumentOriginal(this.documentModel.documentId);
    }

    public deleteDocument(): void {

        const documentNumber = this.documentModel.documentNumber !== null
            ? this.documentModel.documentNumber
            : null;

        const dialogRef = this.dialog.open(DocumentDeleteDialogComponent, {
            data: { documentNumbers: [documentNumber] },
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.documentService.DeleteDocuments([this.documentModel.documentId]).subscribe(_ => {
                    this.shouldCallTurnOffEdit = false;
                    this.documentListService.refresh.next();
                    this.dialogRef.close(true);
                });
            }
        });
    }

    public openAttachmentListDialog(): void {

        this.dialog.open(AttachmentListDialogComponent, {
            data: this.documentModel,
            autoFocus: false,
        });
    }

    public canConfirm(): boolean {
        return this.documentDetails && this.documentDetails.toArray().every(x => x.canConfirm());
    }

    public enableArchiveEdit(): void {
        this.isEditable = true;
    }

    @HostListener('document:keydown.escape')
    public onKeydownHandler() {
        this.closeDialog();
    }

    public closeDialog(): void {
        if (!this.documentDetails || this.documentDetails.toArray().every(x => x.canDeactivate())) {
            this.dialogRef.close(false);
            return;
        }

        const confirm = window.confirm('Czy na pewno chcesz porzucić wprowadzone zmiany?');

        if (confirm) {
            this.dialogRef.close(false);
        }
    }
}
