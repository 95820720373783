import { DocumentFeature } from './document-feature.model';

export class DocumentVATFeature {

    id: number;

    rate: DocumentFeature<string>;
    net: DocumentFeature<number>;
    gross: DocumentFeature<number>;
    vat: DocumentFeature<number>;
}
