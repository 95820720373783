import { Injectable } from '@angular/core';
import { IParser } from '../iparser';

@Injectable({
    providedIn: 'root'
})
export class PaymentMethodParserService implements IParser {

    constructor() { }

    public parse(value: string): string {

        if (/got(o|w)ka/i.test(value)) {
            return 'Gotówka';
        }

        if (/karta/i.test(value)) {
            return 'Karta';
        }

        if (/przedp.ata/i.test(value)) {
            return 'Przedpłata';
        }

        return 'Przelew';
    }
}
