import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user-service/user.service';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { DocumentListService } from './document-list.service';
import { DocumentExtract } from 'src/app/contract/document/document-extract';
import { DocumentDeleteDialogComponent } from '../document-delete-dialog/document-delete-dialog.component';
import { DocumentService } from 'src/app/services/document-service/document.service';

@Component({
    templateUrl: './document-list.component.html',
    styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit, OnDestroy {

    public sortingColumn = 'receiptDate';
    public sortingDirection = 'asc';
    public page = 0;

    public items = new Array<DocumentExtract>();
    public isLoading = false;
    public isEveryDocumentLoaded = false;

    @ViewChild('tableWrapper') tableWrapper: ElementRef;

    private filters: any[];
    private fetchSubscription: Subscription;
    private refreshSubscription: Subscription;

    constructor(
        private documentService: DocumentService,
        private dialog: MatDialog,
        private userService: UserService,
        private titleService: Title,
        private documentListService: DocumentListService,
    ) { }

    public ngOnInit(): void {

        this.titleService.setTitle(`Dokumenty | ${environment.applicationTabName}`);
        window.addEventListener('scroll', this.onWindowScroll, true);

        this.refreshSubscription = this.documentListService.refresh.subscribe(() => {
            this.reloadResults();
        });
    }

    public ngOnDestroy(): void {

        window.removeEventListener('scroll', this.onWindowScroll, true);

        if (this.fetchSubscription) {
            this.fetchSubscription.unsubscribe();
        }

        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
    }

    private reloadResults() {

        if (this.fetchSubscription) {
            this.fetchSubscription.unsubscribe();
            this.isLoading = false;
        }

        this.page = 0;
        this.items = [];
        this.isEveryDocumentLoaded = false;
        this.loadNextPage();
    }

    private loadNextPage() {

        if (this.isEveryDocumentLoaded || this.isLoading) {
            return;
        }

        this.isLoading = true;
        this.page += 1;
        const pageSize = 10;

        this.fetchSubscription = this.documentService.GetDocuments(
            this.filters || [],
            [`${this.sortingColumn} ${this.sortingDirection}`],
            this.page,
            pageSize,
        ).subscribe(
            data => {
                this.isLoading = false;

                this.items = [...this.items, ...data.slice(0, pageSize)];

                this.isEveryDocumentLoaded = data.length !== pageSize;

                this.onWindowScroll();
            },
            _ => {
                this.isLoading = false;
            }
        );
    }

    private onWindowScroll = (): void => {

        const element = document.querySelector('.default-layout') as HTMLElement;
        const scrollPosition = element.scrollTop + window.innerHeight;
        const tableHeight = this.tableWrapper.nativeElement.offsetHeight;
        const tableOffsetTop = this.tableWrapper.nativeElement.offsetTop;
        const tablePosition = tableOffsetTop + tableHeight;

        if (scrollPosition >= tablePosition) {
            this.loadNextPage();
        }
    }

    public sortChanged(column: string) {

        if (this.sortingColumn === column) {
            this.sortingDirection = (this.sortingDirection === 'asc') ? 'desc' : 'asc';
        } else {
            this.sortingColumn = column;
        }

        this.reloadResults();
    }

    public isItemLocked(item: DocumentExtract): boolean {

        if (item.editor === null) {
            return false;
        }

        if (item.editor.userId === this.userService.userExtract.value.userId) {
            return false;
        }

        return true;
    }

    public openMenu($event: MouseEvent) {
        $event.stopPropagation();
        $event.preventDefault();
    }

    public download(item: DocumentExtract) {

        this.documentService.DownloadDocumentOriginal(item.id);
    }

    public print(item: DocumentExtract) {

        this.documentService.PrintDocumentOriginal(item.id);
    }

    public delete(item: DocumentExtract) {

        const dialogRef = this.dialog.open(DocumentDeleteDialogComponent, {
            data: { documentNumbers: [item.documentNumber] },
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.documentService.DeleteDocuments([item.id]).subscribe(_ => {
                    this.items = this.items.filter(x => x.id !== item.id);
                });
            }
        });
    }

    public onFiltersSelected(filters: any[]): void {

        this.filters = filters;
        this.reloadResults();
    }

    public documentExtractTrackBy = (_: number, documentExtract: DocumentExtract) => documentExtract.id;
}
