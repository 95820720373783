import { Component, ElementRef, OnChanges, OnDestroy, OnInit, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Input } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { DetailsService } from '../services/details/details.service';
import { IDocumentDetails } from '../document-details.interface';
import { Subscription } from 'rxjs';
import { PaymentMethodParserService } from 'src/app/services/parsers/payment-method-parser-service/payment-method-parser.service';
import { DateParserService } from 'src/app/services/parsers/date-parser-service/date-parser.service';
import { DocumentModel } from 'src/app/contract/document/document.model';

@Component({
    selector: 'digica-document-details-invoice-base',
    templateUrl: './document-details-invoice-base.component.html',
    styleUrls: ['./document-details-invoice-base.component.scss']
})
export class DocumentDetailsInvoiceBaseComponent implements IDocumentDetails, OnInit, OnDestroy, OnChanges {

    @Input() public documentModel: DocumentModel;
    @Input() public isEditable: boolean;

    @ViewChildren('errors') errors: QueryList<TemplateRef<ElementRef>>;

    public availablePaymentMethods = [
        { value: 'Karta', display: 'Karta', },
        { value: 'Przelew', display: 'Przelew', },
        { value: 'Gotówka', display: 'Gotówka', },
        { value: 'Rekompensata', display: 'Rekompensata' },
        { value: 'Przedpłata', display: 'Przedpłata' }
    ];

    public formGroup = new FormGroup({
        currency: new FormControl('', [Validators.required]),
        invoiceNumber: new FormControl('', [Validators.required]),
        paymentMethod: new FormControl(''),
        splitPayment: new FormControl(false),
        issueDate: new FormControl('', [Validators.required]),
        saleDate: new FormControl('', [Validators.required]),
        paymentDeadline: new FormControl('', []),
        isDuplicate: new FormControl(false),
        duplicateDate: new FormControl(),
    });

    public get currency() { return this.formGroup.get('currency') as FormControl; }
    public get invoiceNumber() { return this.formGroup.get('invoiceNumber') as FormControl; }
    public get paymentMethod() { return this.formGroup.get('paymentMethod') as FormControl; }
    public get splitPayment() { return this.formGroup.get('splitPayment') as FormControl; }
    public get issueDate() { return this.formGroup.get('issueDate') as FormControl; }
    public get saleDate() { return this.formGroup.get('saleDate') as FormControl; }
    public get paymentDeadline() { return this.formGroup.get('paymentDeadline') as FormControl; }
    public get isDuplicate() { return this.formGroup.get('isDuplicate') as FormControl; }
    public get duplicateDate() { return this.formGroup.get('duplicateDate') as FormControl; }

    private paymentMethodValueChangesSubscription: Subscription;
    private isDuplicateSubscription: Subscription;

    constructor(
        public detailsService: DetailsService,
        public paymentMethodParserService: PaymentMethodParserService,
        public dateParserService: DateParserService,
    ) { }

    public ngOnInit(): void {

        this.detailsService.currencyValue = this.currency.valueChanges;
        this.detailsService.paymentMethodValueChanges = this.paymentMethod.valueChanges;
        this.detailsService.documentNumberControl = this.invoiceNumber;

        this.isDuplicateSubscription = this.isDuplicate.valueChanges.subscribe(value => {

            this.duplicateDate.clearValidators();

            if (value) {
                this.duplicateDate.setValidators([Validators.required]);
                return;
            }

            this.duplicateDate.updateValueAndValidity();
        });
    }

    public ngOnDestroy(): void {

        if (this.paymentMethodValueChangesSubscription) {
            this.paymentMethodValueChangesSubscription.unsubscribe();
        }

        if (this.isDuplicateSubscription) {
            this.isDuplicateSubscription.unsubscribe();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {

        if (changes.documentModel && changes.documentModel.currentValue) {

            this.detailsService.updateFormControl(this.currency, this.documentModel.currency);
            this.detailsService.updateFormControl(this.invoiceNumber, this.documentModel.invoiceNumber);
            this.detailsService.updateFormControl(this.paymentMethod, this.documentModel.paymentMethod);
            this.detailsService.updateFormControl(this.splitPayment, this.documentModel.splitPayment);
            this.detailsService.updateFormControl(this.issueDate, this.documentModel.issueDate);
            this.detailsService.updateFormControl(this.saleDate, this.documentModel.saleDate);
            this.detailsService.updateFormControl(this.paymentDeadline, this.documentModel.paymentDeadline);
            this.detailsService.updateFormControl(this.isDuplicate, this.documentModel.isDuplicate);
            this.detailsService.updateFormControl(this.duplicateDate, this.documentModel.duplicateDate);

            this.detailsService.markFormGroupTouched(this.formGroup);
        }

        this.detailsService.changeEnabledFormStateBasingOnIsEditable(this.isEditable, this.formGroup);
    }

    public assignDocumentModelValues(documentModel: DocumentModel): void {

        this.detailsService.updateDocumentModelFeature(documentModel, 'currency', this.currency.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'invoiceNumber', this.invoiceNumber.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'paymentMethod', this.paymentMethod.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'splitPayment', this.splitPayment.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'issueDate', this.issueDate.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'saleDate', this.saleDate.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'paymentDeadline', this.paymentDeadline.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'isDuplicate', this.isDuplicate.value);

        if (this.isDuplicate.value) {
            this.detailsService.updateDocumentModelFeature(documentModel, 'duplicateDate', this.duplicateDate.value);
        }
    }

    public canDeactivate = () => this.formGroup.pristine;
    public canConfirm = () => true;
    public getErrors = (): TemplateRef<ElementRef<any>>[] => this.errors.toArray();
}
