import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    SimpleChanges,
    ElementRef,
    QueryList,
    TemplateRef,
    ViewChildren,
    OnChanges,
    ChangeDetectorRef
} from '@angular/core';
import { merge, Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ContractorStatusEnum } from './contractor-status.enum';
import { ValidationService } from 'src/app/services/validation-service/validation.service';
import { HttpErrorResponse } from '@angular/common/http';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ContractorVatListStatusEnum } from './contractor-vat-list-status.enum';
import { VatListService } from 'src/app/services/vat-list-service/vat-list.service';
import { AccountFormatterService } from 'src/app/services/formatters/account-formatter-service/account-formatter.service';
import { TaxNumberFormatterService } from 'src/app/services/formatters/tax-number-formatter-service/tax-number-formatter.service';
import { DetailsService } from 'src/app/document-details/services/details/details.service';
import { IDocumentDetails } from '../document-details.interface';
import { DocumentModel } from 'src/app/contract/document/document.model';
import { DocumentStatusEnum } from 'src/app/contract/document/document-status.enum';
import { ContractorService } from 'src/app/services/contractor-service/contractor.service';

@Component({
    selector: 'digica-document-details-contractors',
    templateUrl: './document-details-contractors.component.html',
    styleUrls: ['./document-details-contractors.component.scss']
})
export class DocumentDetailsContractorsComponent implements IDocumentDetails, OnInit, OnDestroy, OnChanges {

    @Input() public documentModel: DocumentModel;
    @Input() public isEditable: boolean;

    @ViewChildren('errors') errors: QueryList<TemplateRef<ElementRef>>;

    @Input() public taxNumberFeatureName = 'sellerTaxNumber';
    @Input() public accountFeatureName = 'sellerAccount';
    @Input() public nameFeatureName = 'sellerName';
    @Input() public streetFeatureName = 'sellerStreet';
    @Input() public addressFeatureName = 'sellerAddress';
    @Input() public isInputRequired = true;

    public formGroup = new FormGroup({
        taxNumber: new FormControl('', [Validators.required, this.validationService.taxNumberValidator()]),
        account: new FormControl('', [Validators.required, this.validationService.accountValidator()]),
        name: new FormControl('', [Validators.required]),
        street: new FormControl('', [Validators.required]),
        address: new FormControl('', [Validators.required]),
    });

    public get taxNumber() { return this.formGroup.get('taxNumber') as FormControl; }
    public get account() { return this.formGroup.get('account') as FormControl; }
    public get name() { return this.formGroup.get('name') as FormControl; }
    public get street() { return this.formGroup.get('street') as FormControl; }
    public get address() { return this.formGroup.get('address') as FormControl; }

    public ContractorVatListStatusEnum = ContractorVatListStatusEnum;
    public ContractorStatusEnum = ContractorStatusEnum;

    public isManualEditModeEnabled = false;
    public dataSyncedTaxNumber: string;
    public contractorStatus = ContractorStatusEnum.Loading;
    public vatListStatus: ContractorVatListStatusEnum = ContractorVatListStatusEnum.Unknown;
    public gusVerificationDatetime: Date;

    private vatlistSubscription: Subscription;
    private paymentMethodValueChangesSubscription: Subscription;

    constructor(
        private validationService: ValidationService,
        private contractorService: ContractorService,
        private vatListService: VatListService,
        public accountFormatterService: AccountFormatterService,
        public taxNumberFormatterService: TaxNumberFormatterService,
        public detailsService: DetailsService,
        private changeDetectorRef: ChangeDetectorRef,
    ) { }

    public ngOnChanges(changes: SimpleChanges): void {

        if (changes.documentModel && changes.documentModel.currentValue) {

            this.vatlistSubscription = merge(this.taxNumber.valueChanges, this.account.valueChanges)
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe(() => this.validateContractorWithVatList());

            this.detailsService.updateFormControl(
                this.taxNumber,
                this.documentModel[this.taxNumberFeatureName],
                this.taxNumberFormatterService
            );
            this.detailsService.updateFormControl(this.account, this.documentModel[this.accountFeatureName], this.accountFormatterService);
            this.detailsService.updateFormControl(this.name, this.documentModel[this.nameFeatureName]);
            this.detailsService.updateFormControl(this.street, this.documentModel[this.streetFeatureName]);
            this.detailsService.updateFormControl(this.address, this.documentModel[this.addressFeatureName]);

            this.detailsService.markFormGroupTouched(this.formGroup);

            this.dataSyncedTaxNumber = this.documentModel[this.taxNumberFeatureName]
                ? this.taxNumberFormatterService.parseWithoutCountryCode(this.documentModel[this.taxNumberFeatureName].value)
                : null;

            setTimeout(() => {
                this.contractorStatus = this.documentModel.gusVerificationDatetime !== null
                ? ContractorStatusEnum.Ok
                : ContractorStatusEnum.NotLoaded;
            });

            if (
                this.documentModel.currentStatus.value === DocumentStatusEnum.Archived
                && this.documentModel.gusVerificationDatetime === null
            ) {
                this.isManualEditModeEnabled = true;
                this.contractorStatus = ContractorStatusEnum.Ok;
            }

            this.gusVerificationDatetime = this.documentModel.gusVerificationDatetime !== null
                ? new Date(this.documentModel.gusVerificationDatetime)
                : null;

            this.detailsService.markFormGroupTouched(this.formGroup);
            this.changeDetectorRef.markForCheck();
        }

        this.detailsService.changeEnabledFormStateBasingOnIsEditable(this.isEditable, this.formGroup);
    }

    public assignDocumentModelValues(documentModel: DocumentModel): void {

        this.detailsService.updateDocumentModelFeature(
            documentModel,
            this.taxNumberFeatureName,
            this.taxNumber.value,
            this.taxNumberFormatterService
        );

        this.detailsService.updateDocumentModelFeature(
            documentModel,
            this.accountFeatureName,
            this.account.value,
            this.accountFormatterService
        );

        this.detailsService.updateDocumentModelFeature(documentModel, this.nameFeatureName, this.name.value);
        this.detailsService.updateDocumentModelFeature(documentModel, this.streetFeatureName, this.street.value);
        this.detailsService.updateDocumentModelFeature(documentModel, this.addressFeatureName, this.address.value);
    }

    public canDeactivate = () => this.formGroup.pristine;
    public canConfirm(): boolean {
        return !this.account.hasError('invalidAccount')
            && !this.taxNumber.hasError('invalidTaxNumber')
            && !this.shouldLoadContractorData();
    }

    public getErrors = (): TemplateRef<ElementRef<any>>[] => this.errors.toArray();

    public ngOnInit(): void {

        this.updateFormControlsValidators();

        this.paymentMethodValueChangesSubscription = this.detailsService.paymentMethodValueChanges.subscribe(paymentMethod => {

            if (paymentMethod === 'Przelew' && this.isInputRequired) {
                this.account.clearValidators();
                this.account.setValidators([Validators.required, this.validationService.accountValidator()]);
            } else {
                this.account.clearValidators();
                this.account.setValidators([this.validationService.accountValidator()]);
            }

            this.account.updateValueAndValidity();
        });

        this.detailsService.contractorTaxNumberControl = this.taxNumber;
    }

    public ngOnDestroy(): void {

        if (this.vatlistSubscription) {
            this.vatlistSubscription.unsubscribe();
        }

        if (this.paymentMethodValueChangesSubscription) {
            this.paymentMethodValueChangesSubscription.unsubscribe();
        }
    }

    public shouldLoadContractorData(): boolean {

        return !this.isManualEditModeEnabled
            && this.contractorStatus === ContractorStatusEnum.Ok
            && !this.isSyncedWithTaxNumber(this.taxNumber.value);
    }

    public isSyncedWithTaxNumber(value: string): boolean {
        return this.dataSyncedTaxNumber === this.taxNumberFormatterService.parseWithoutCountryCode(value);
    }

    public loadContractorData(): void {

        const taxNumber = this.taxNumberFormatterService.parseWithoutCountryCode(this.taxNumber.value);
        if (!this.validationService.isNipValid(taxNumber)) {
            return;
        }

        this.contractorStatus = ContractorStatusEnum.Loading;

        this.contractorService.GetContractorData(taxNumber).subscribe(contractor => {

            this.contractorStatus = ContractorStatusEnum.Ok;

            this.gusVerificationDatetime = new Date();
            this.dataSyncedTaxNumber = taxNumber;
            this.name.setValue(contractor.name);
            this.street.setValue(contractor.street);
            this.address.setValue(`${contractor.postalCode} ${contractor.city}`);

        }, (error: HttpErrorResponse) => {

            this.contractorStatus = ContractorStatusEnum.Error;
            this.dataSyncedTaxNumber = taxNumber;
            this.name.setValue('');
            this.street.setValue('');
            this.address.setValue('');

            if (error.status === 404) {
                this.contractorStatus = ContractorStatusEnum.NotFound;
            } else if (error.status === 429) {
                this.contractorStatus = ContractorStatusEnum.Throttle;
            } else if (error.status === 502) {
                this.contractorStatus = ContractorStatusEnum.BadGateway;
            }
        });
    }

    private validateContractorWithVatList(): void {

        if (
            this.taxNumber.invalid || this.taxNumber.value === ''
            || this.account.invalid || this.account.value === ''
        ) {
            this.vatListStatus = ContractorVatListStatusEnum.InvalidInput;
            return;
        }

        const taxNumber = this.taxNumberFormatterService.parseWithoutCountryCode(this.taxNumber.value);
        const account = this.accountFormatterService.parseWithoutCountryCode(this.account.value);

        this.vatListStatus = ContractorVatListStatusEnum.Loading;
        this.vatListService.Validate(this.documentModel.documentId, taxNumber, account).subscribe(result => {
            this.vatListStatus = result ? ContractorVatListStatusEnum.Ok : ContractorVatListStatusEnum.Fail;
        }, (error: HttpErrorResponse) => {

            if (error.status === 502) {
                this.vatListStatus = ContractorVatListStatusEnum.Unknown;
                return;
            }

            this.vatListStatus = ContractorVatListStatusEnum.Fail;
        });
    }

    public toggleEditMode(): void {
        this.isManualEditModeEnabled = !this.isManualEditModeEnabled;

        if (!this.isManualEditModeEnabled) {
            this.dataSyncedTaxNumber = null;
        }
    }

    private updateFormControlsValidators(): void {
        const validators = [];
        if (this.isInputRequired) {
            validators.push(Validators.required);
        }

        this.account.setValidators([...validators, this.validationService.accountValidator()]);
        this.name.setValidators([...validators]);
        this.street.setValidators([...validators]);
        this.address.setValidators([...validators]);
    }
}
