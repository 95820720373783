import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { MatDialog } from '@angular/material';
import { NoInternetDialogComponent } from '../no-internet-dialog/no-internet-dialog.component';
import { InternetConnectionService } from 'src/app/services/internet-connection-service/internet-connection.service';

@Injectable()
export class InternetInterceptor implements HttpInterceptor {

    constructor(
        private dialog: MatDialog,
        private internetConnectionService: InternetConnectionService,
    ) { }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (window.navigator && !window.navigator.onLine) {

            if (this.internetConnectionService.isInternetConnectionBroken) {
                return;
            }

            this.dialog.open(NoInternetDialogComponent, {
                maxWidth: '100vw',
                maxHeight: '100vh',
                height: '100%',
                width: '100%',
                panelClass: 'full-screen-modal',
                autoFocus: false,
                disableClose: true,
            });

            this.internetConnectionService.isInternetConnectionBroken = true;
            return throwError(new HttpErrorResponse({ error: 'Internet is required.' }));
        } else {
            return next.handle(request);
        }
    }
}
