import { Injectable } from '@angular/core';
import { CallService } from '../call-service/call.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VatListService {
    private servicePrefix = 'vatlist';

    constructor(
        private callService: CallService,
    ) { }

    public Validate(id: number, nip: string, account: string): Observable<boolean> {
        return this.callService.get<boolean>(`${this.servicePrefix}/validate/${id}/${nip}/${account}`);
    }
}
