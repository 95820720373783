import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { merge, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DocumentConnection } from 'src/app/contract/document/document-connection.model';
import { DocumentExtract } from 'src/app/contract/document/document-extract';
import { DocumentTypeEnum } from 'src/app/contract/document/document-type.enum';
import { DocumentService } from 'src/app/services/document-service/document.service';
import { DetailsService } from '../services/details/details.service';
import { ConnectDocumentModel } from './connect-document.model';
import { ConnectedDocumentService } from './connected-document.service';

@Component({
    selector: 'digica-connected-document',
    templateUrl: './connected-document.component.html',
    styleUrls: ['./connected-document.component.scss']
})
export class ConnectedDocumentComponent implements OnInit, OnDestroy {

    public formGroup = new FormGroup({
        documentNumber: new FormControl(''),
        documentType: new FormControl(null),
    });

    public get documentNumber() { return this.formGroup.get('documentNumber') as FormControl; }
    public get documentType() { return this.formGroup.get('documentType') as FormControl; }
    public isActive = false;
    public isLoading = false;

    public documentExtracts: DocumentExtract[];
    public DocumentTypeEnum = DocumentTypeEnum;
    public taxNumber: string;

    private formValueChangesSubscription: Subscription;
    private documentReloadSubscription: Subscription;
    private openSubscription: Subscription;
    private connectDocumentModel: ConnectDocumentModel;

    constructor(
        public connectedDocumentService: ConnectedDocumentService,
        private documentService: DocumentService,
        private detailsService: DetailsService,
    ) { }

    public ngOnInit(): void {

        this.openSubscription = this.connectedDocumentService.openEvent$.subscribe(connectDocumentModel => {

            this.connectDocumentModel = connectDocumentModel;
            this.taxNumber = this.detailsService.contractorTaxNumberControl.value;
            const documentNumber = this.detailsService.documentNumberControl.value;

            this.onOpen(documentNumber);
        });
    }

    public ngOnDestroy(): void {

        if (this.formValueChangesSubscription) {
            this.formValueChangesSubscription.unsubscribe();
        }

        if (this.documentReloadSubscription) {
            this.documentReloadSubscription.unsubscribe();
        }

        if (this.openSubscription) {
            this.openSubscription.unsubscribe();
        }
    }

    public finishConnecting(documentExtract: DocumentExtract, $event: MouseEvent): void {
        $event.preventDefault();
        $event.stopPropagation();

        this.onClose();
        this.connectedDocumentService.selectConnectedDocument(documentExtract);
    }

    public abortConnecting(): void {
        this.onClose();
        this.connectedDocumentService.closeConnectingDocument();
    }

    private onOpen(documentNumber: string): void {

        this.documentNumber.setValue(documentNumber);
        this.reloadDocuments();

        this.formValueChangesSubscription = this.formGroup.valueChanges.pipe(
            debounceTime(500),
        ).subscribe(_ => {
            this.reloadDocuments();
        });

        this.isActive = true;
    }

    private onClose(): void {

        if (this.formValueChangesSubscription) {
            this.formValueChangesSubscription.unsubscribe();
        }

        if (this.documentReloadSubscription) {
            this.documentReloadSubscription.unsubscribe();
        }

        this.isActive = false;
    }

    private reloadDocuments(): void {

        if (this.documentReloadSubscription) {
            this.documentReloadSubscription.unsubscribe();
        }

        const filters = new Array<any>();
        filters.push('contains((tolower(ContractorTaxNumber)), \'' + this.taxNumber + '\')');

        const filterOutIds = [
            ...this.connectDocumentModel.documentConnections.map(x => x.documentId),
            this.connectDocumentModel.documentId,
        ];

        filters.push(`not(DocumentId in (${filterOutIds.join(', ')}))`);

        if (this.documentNumber.value) {
            filters.push('contains((tolower(DocumentNumber)), \'' + this.documentNumber.value.toLowerCase() + '\')');
        }

        if (this.documentType.value && this.documentType.value.length > 0) {
            filters.push({ or: (this.documentType.value as Array<DocumentTypeEnum>).map(x => ({ DocumentType: DocumentTypeEnum[x] }))});
        }

        const queryData = {
            filter: filters,
            orderBy: [`DocumentId desc`],
            top: 50,
        };

        this.isLoading = true;
        this.documentReloadSubscription = this.documentService.getDocumentFromQuery(queryData).subscribe(
            documents => {
                this.documentExtracts = documents;
                this.isLoading = false;
            },
            _ => {
                this.isLoading = false;
            }
        );
    }

    public abortConnectingDocument(): void {
        this.connectedDocumentService.closeConnectingDocument();
    }

    public documentExtractTrackBy = (_: number, documentExtract: DocumentExtract) => documentExtract.id;
}
