<h2 mat-dialog-title>
    Dodawanie nowego dokumentu
</h2>

<mat-dialog-content>

    <div class="content-wrapper">

        <div class="upload-container">
            <div class="description">
                Możesz wysłać dokumnety upuszczając pliki na poniższe pole
            </div>

            <div class="upload-area">
                <digica-document-upload></digica-document-upload>
            </div>
        </div>

        <div class="email-container">
            <div class="description">
                lub przesyłając je jako załącznik na jeden z adresów
            </div>

            <div class="setting-wrapper">
                <div class="setting-input">
                    <div class="label">Adres e-mail do dokumentów</div>
                    <mat-form-field
                        floatLabel="never"
                        class="custom"
                        appearance="standard"
                        (click)="copyToClipboard(documentEmailInput)"
                    >
                        <input
                            matInput
                            [value]="documentEmail"
                            #documentEmailInput
                        />
                        <mat-hint>Naciśnij na pole aby skopiować</mat-hint>
                    </mat-form-field>
                </div>
            </div>
    
            <div class="setting-wrapper">
                <div class="setting-input">
                    <div class="label">Adres e-mail do e-faktur</div>
                    <mat-form-field
                        floatLabel="never"
                        class="custom"
                        appearance="standard"
                        (click)="copyToClipboard(eInvoiceEmailInput)"
                    >
                        <input
                            matInput
                            [value]="eInvoiceEmail"
                            #eInvoiceEmailInput
                        />
                        <mat-hint>Naciśnij na pole aby skopiować</mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button color="primary" [mat-dialog-close]="true">Zamknij</button>
</mat-dialog-actions>

<ng-template #emailCopiedTemplate>
    📩 Adres email został skopiowany do schowka 😁
</ng-template>
