import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { DocumentFeature } from 'src/app/contract/document/document-feature.model';
import { DocumentModelCorrection } from 'src/app/contract/document/document-model-correction.model';
import { DocumentVATFeature } from 'src/app/contract/document/document-vat-feature.model';
import { DocumentModel } from 'src/app/contract/document/document.model';
import { IFormatter } from 'src/app/services/formatters/iformatter';
import { Area } from 'src/app/services/interactive-image-service/area';
import { FeatureAreas, InvoiceFeatureArea, VATFeatureArea } from 'src/app/services/interactive-image-service/feature-areas.model';
import { InteractiveImageService } from 'src/app/services/interactive-image-service/interactive-image.service';
import { IParser } from 'src/app/services/parsers/iparser';

@Injectable({
    providedIn: 'root'
})
export class DetailsService {

    public featureAreas: FeatureAreas;
    public currencyValue: Observable<string>;
    public noteTypeValueChanges: Observable<string>;
    public paymentMethodValueChanges: Observable<string>;
    public documentNumberControl: FormControl;
    public contractorTaxNumberControl: FormControl;
    public documentTypeControl: FormControl;

    constructor(
        private interactiveImageService: InteractiveImageService,
    ) { }

    public generateFeatureAreas(documentModel: DocumentModel): void {
        const featureAreas = new FeatureAreas();

        const model = new DocumentModel();
        for (const key of Object.keys(model)) {

            if (this.isVatFeature(key)) {

                featureAreas[key] = new Array<VATFeatureArea>();
                for (const vatRow of documentModel[key]) {
                    const vatFeatureArea = new VATFeatureArea();
                    vatFeatureArea.net = this.createAreaForFeature(vatRow.net);
                    vatFeatureArea.rate = this.createAreaForFeature(vatRow.rate);
                    vatFeatureArea.vat = this.createAreaForFeature(vatRow.vat);
                    vatFeatureArea.gross = this.createAreaForFeature(vatRow.gross);

                    featureAreas[key].push(vatFeatureArea);
                }

                continue;
            }

            if (this.isInvoiceTableFeature(key)) {

                featureAreas[key] = new Array<InvoiceFeatureArea>();
                for (const invRow of documentModel[key]) {
                    const invFeatureArea = new InvoiceFeatureArea();
                    invFeatureArea.invoiceNumber = this.createAreaForFeature(invRow.invoiceNumber);
                    invFeatureArea.amountPayed = this.createAreaForFeature(invRow.amountPayed);
                    invFeatureArea.issueDate = this.createAreaForFeature(invRow.issueDate);
                    invFeatureArea.payedOn = this.createAreaForFeature(invRow.payedOn);
                    invFeatureArea.paymentDeadline = this.createAreaForFeature(invRow.paymentDeadline);
                    invFeatureArea.toPay = this.createAreaForFeature(invRow.toPay);
                    invFeatureArea.daysOverdue = this.createAreaForFeature(invRow.daysOverdue);
                    invFeatureArea.interestToPay = this.createAreaForFeature(invRow.interestToPay);

                    featureAreas[key].push(invFeatureArea);
                }

                continue;
            }

            if (typeof model[key] === 'object') {

                featureAreas[key] = this.createAreaForFeature(documentModel[key]);
                continue;
            }
        }

        this.featureAreas = featureAreas;
    }

    public getCorrections(oldDocumentModel: DocumentModel, newDocumentModel: DocumentModel): Array<DocumentModelCorrection> {

        let corrections = new Array<DocumentModelCorrection>();

        const model = new DocumentModel();
        for (const featureName of Object.keys(model)) {

            if (this.isVatFeature(featureName)) {
                const vatRowToString = (row: DocumentVATFeature) =>
                    `${row.net.value} ${row.rate.value} ${row.vat.value} ${row.gross.value}`;
                const oldVatRows = [ ...oldDocumentModel[featureName] ];
                const newVatRows = [ ...newDocumentModel[featureName] ];

                for (let i = oldVatRows.length - 1; i >= 0; i -= 1) {
                    const oldValue = vatRowToString(oldVatRows[i]);
                    const newRowIndex = newVatRows.findIndex(x => x.rate.value === oldVatRows[i].rate.value);
                    if (newRowIndex === -1) {
                        corrections.push({ featureName, oldValue, newValue: null });
                        continue;
                    }
                    const newValue = vatRowToString(newVatRows[newRowIndex]);

                    oldVatRows.splice(i, 1);
                    newVatRows.splice(newRowIndex, 1);

                    if (oldValue !== newValue) {
                        corrections.push({ featureName, oldValue, newValue });
                    }
                }

                for (let i = newVatRows.length - 1; i >= 0; i -= 1) {
                    corrections.push({ featureName, oldValue: null, newValue: vatRowToString(newVatRows[i]) });
                }

            } else if (typeof model[featureName] === 'object') {

                const oldValue = oldDocumentModel[featureName] ? oldDocumentModel[featureName].value : null;
                const newValue = newDocumentModel[featureName] ? newDocumentModel[featureName].value : null;

                if (oldValue != newValue) { // tslint:disable-line
                    corrections.push({ featureName, oldValue, newValue });
                }
            }
        }

        corrections = corrections.map(x => ({ ...x, featureName: x.featureName.charAt(0).toUpperCase() + x.featureName.slice(1) }));
        corrections = corrections.map(x => ({ ...x, featureName: x.featureName === 'VatRows' ? 'VatRow' : x.featureName }));
        return corrections;
    }

    private isVatFeature(featureName: string): boolean {
        return featureName === 'vatRows' || featureName === 'correctionVatRows'
            || featureName === 'prepaymentVatRows' || featureName === 'billingVatRows';
            // || featureName === 'provisionalVatRows';
    }

    private isInvoiceTableFeature(featureName: string): boolean {
        return featureName === 'noteInvoiceListRows';
    }

    private createAreaForFeature(feature: DocumentFeature<any>): Area {

        if (!feature || feature.width === 0 || feature.height === 0) {
            return new Area();
        }

        return new Area(feature.x, feature.y, feature.width, feature.height, feature.page);
    }

    public getFeatureArea(featureKeys: Array<string>): Area {

        if (typeof featureKeys === 'undefined') {
            return null;
        }

        let area = this.featureAreas;
        for (const key of featureKeys) {

            if (!(key in area)) {
                return;
            }

            area = area[key];
        }

        return (area as any) as Area;
    }



    public updateDocumentModelFeature(model: DocumentModel, propertyName: string, value: string, formatter: IFormatter = null): void {

        if (model[propertyName] === null) {
            model[propertyName] = new DocumentFeature<any>();
        }

        model[propertyName].value = formatter ? formatter.parse(value) : value;
        DocumentFeature.assignArea(model[propertyName], this.getFeatureArea([propertyName]));
    }

    public updateFormControl(control: FormControl, feature: DocumentFeature<any>, formatter: IFormatter = null): void {

        if (!feature || feature.value === null) {
            return;
        }

        control.setValue(formatter ? formatter.format(feature.value) : feature.value);
    }

    public markFormGroupTouched(formGroup: FormGroup) {

        Object.keys(formGroup.controls).map(x => formGroup.controls[x]).forEach((control: FormGroup) => {
            control.markAsTouched();

            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }

    public onFocusChanged(
        event?: FocusEvent,
        featureKeys?: Array<string>,
        control?: FormControl,
        parser: IParser = null,
        formatter: IFormatter = null
    ): void {

        const area = this.getFeatureArea(featureKeys);
        this.interactiveImageService.focusedFeatureArea.next(area);

        this.interactiveImageService.featurePageSwitcher.next({
            focusedFeaturePage: area !== null && this.interactiveImageService.currentPage.value !== area.page && area.width > 0
                ? area.page
                : null,
            focusedFeatureReturnPage: null,
        });

        if (typeof event === 'undefined') {
            return;
        }

        this.interactiveImageService.selectedCallback.next((text: string, selectedArea: Area) => {

            text = parser !== null ? parser.parse(text) : text;
            text = formatter !== null ? formatter.format(text) : text;

            control.setValue(text);
            control.markAsTouched();
            (event.target as HTMLInputElement).focus();

            const areaObject = this.getFeatureArea(featureKeys);
            areaObject.set(selectedArea.x, selectedArea.y, selectedArea.width, selectedArea.height, selectedArea.page);
        });
    }

    public changeEnabledFormStateBasingOnIsEditable(isEditable: boolean, formGroup: FormGroup): void {

        if (isEditable) {
            formGroup.enable();
            return;
        }

        formGroup.disable();
    }
}
