import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    public form: FormGroup = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
    });

    public get email(): FormControl {
        return this.form.get('email') as FormControl;
    }

    public isSubmitted = false;

    constructor(
        private authService: AuthService,
        private router: Router,
        private titleService: Title,
    ) { }

    public ngOnInit(): void {

        this.titleService.setTitle(`Nie pamiętam hasła | ${environment.applicationTabName}`);
    }

    public submit(): void {

        const urlTree = this.router.createUrlTree(['/reset-password']);
        const url = `${window.location.origin}${urlTree}`;

        if (this.form.valid) {
            const email = this.email.value;

            this.authService.forgotPassword(email, url).subscribe(
                _ => {
                    this.isSubmitted = true;
                },
            );
        }
    }
}
