import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService,
        private dialog: MatDialog,
        private router: Router,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(
            tap(
                _ => { },
                (err: any) => {

                    if (err instanceof HttpErrorResponse && err.status === 401) {
                        this.dialog.closeAll();
                        this.authService.redirectToLogin(this.router.url);
                    }
                }
            )
        );
    }
}
