import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription, timer, Subject, Observable } from 'rxjs';
import { debounceTime, filter, take, takeUntil, switchMap, startWith } from 'rxjs/operators';
import { UserService } from 'src/app/services/user-service/user.service';
import { StorageService } from 'src/app/services/storage-service/storage.service';
import { DocumentCounter } from 'src/app/contract/document/document-counter';
import { DocumentStatusEnum } from 'src/app/contract/document/document-status.enum';
import { DocumentService } from 'src/app/services/document-service/document.service';

@Component({
    selector: 'digica-document-filters',
    templateUrl: './document-filters.component.html',
    styleUrls: ['./document-filters.component.scss']
})
export class DocumentFiltersComponent implements OnInit, OnDestroy {

    @Output() filtersSelected = new EventEmitter<any[]>();

    public search = new FormControl('');
    public counter: DocumentCounter;

    private userId: number = null;
    private showFilter = 'all';
    private searchSubscription: Subscription;

    private readonly documentFiltersSearchKey = 'document_filters_search';
    private readonly documentFiltersShowKey = 'document_filters_show';

    private counterRefreshTimer: Observable<any>;
    private counterRefreshTimerReset = new Subject();
    private counterRefreshTimerStop = new Subject();

    constructor(
        private userService: UserService,
        private storageService: StorageService,
        private documentService: DocumentService,
    ) { }

    public ngOnInit(): void {

        this.search.setValue(this.storageService.get(this.documentFiltersSearchKey));
        this.showFilter = this.storageService.get(this.documentFiltersShowKey);

        this.searchSubscription = this.search.valueChanges
            .pipe(debounceTime(500))
            .subscribe(value => {

                this.storageService.set(this.documentFiltersSearchKey, value);
                this.emitFilters();
            });

        this.userService.userExtract
            .pipe(
                filter(x => x !== null),
                take(1)
            )
            .subscribe(userExtract => {
                this.userId = userExtract.userId;
                this.emitFilters();
            });

        this.startRefreshCounterInterval();
    }

    public ngOnDestroy(): void {

        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
        }

        this.counterRefreshTimerStop.next();
    }

    private emitFilters(): void {
        const filters = this.buildFilters();
        this.filtersSelected.emit(filters);
    }

    private buildFilters(): any[] {

        const filters = new Array<any>();

        if (this.isShowFilterActive('e-invoices')) {
            filters.push({ IsEInvoice: true });
        } else if (this.isShowFilterActive('my') && this.userId !== null) {
            filters.push({ 'DocumentGroup/Uploader/UserId': this.userId });
        }

        if (this.search.value) {
            filters.push({ or: [
                'contains((tolower(ContractorName)), \'' + this.search.value.toLowerCase() + '\')',
                'contains((tolower(ContractorTaxNumber)), \'' + this.search.value.toLowerCase() + '\')',
                'contains((tolower(DocumentNumber)), \'' + this.search.value.toLowerCase() + '\')'
            ]});
        }

        filters.push({ or: [
            { 'CurrentStatus/Value': DocumentStatusEnum[DocumentStatusEnum.ToConfirm] },
        ]});

        return filters;
    }

    public setShowFilter(selectedFilter: string): void {
        this.storageService.set(this.documentFiltersShowKey, selectedFilter);
        this.showFilter = selectedFilter;
        this.emitFilters();
        this.counterRefreshTimerReset.next(void 0);
    }

    public isShowFilterActive(selectedFilter: string): boolean {
        return this.showFilter === selectedFilter;
    }

    private startRefreshCounterInterval(): void {

        this.counterRefreshTimer = this.counterRefreshTimerReset.pipe(
            startWith(0),
            switchMap(() => timer(0, 10000)),
        );

        this.counterRefreshTimer.pipe(takeUntil(this.counterRefreshTimerStop)).subscribe(_ => {
            this.refreshCounter();
        });
    }

    private refreshCounter(): void {
        this.documentService.getCounter().subscribe(counter => {
            this.counter = counter;
        });
    }
}
