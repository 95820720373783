<ng-container [ngSwitch]="feature">
    <span *ngSwitchCase="'DocumentType'">Typ dokumentu</span>

    <!-- PurchaseInvoice -->
    <span *ngSwitchCase="'Currency'">Waluta</span>

    <span *ngSwitchCase="'InvoiceNumber'">Numer faktury</span>
    <span *ngSwitchCase="'PaymentMethod'">Metoda płatności</span>
    <span *ngSwitchCase="'SplitPayment'">Podzielona płatność</span>

    <span *ngSwitchCase="'IssueDate'">Data wystawienia</span>
    <span *ngSwitchCase="'SaleDate'">Data sprzedaży</span>
    <span *ngSwitchCase="'PaymentDeadline'">Termin płatności</span>

    <span *ngSwitchCase="'SellerTaxNumber'">NIP sprzedawcy</span>
    <span *ngSwitchCase="'SellerAccount'">Rachunek bankowy sprzedawcy</span>

    <span *ngSwitchCase="'SellerName'">Nazwa sprzedawcy</span>
    <span *ngSwitchCase="'SellerStreet'">Ulica sprzedawcy</span>
    <span *ngSwitchCase="'SellerAddress'">Adres sprzedawcy</span>

    <span *ngSwitchCase="'VatRow'">Wiersz VAT</span>

    <span *ngSwitchCase="'AmountGross'">Kwota brutto</span>
    <span *ngSwitchCase="'AmountNet'">Kwota netto</span>
    <span *ngSwitchCase="'AmountVat'">Kwota VAT</span>

    <!-- CorrectionInvoice -->
    <span *ngSwitchCase="'CorrectedInvoiceNumber'">Numer faktury korygowanej</span>
    <span *ngSwitchCase="'CorrectionNumber'">Numer faktury korygującej</span>
    <span *ngSwitchCase="'CorrectionDate'">Data korekty</span>
    <span *ngSwitchCase="'CorrectionSaleDate'">Data sprzedaży</span>
    <span *ngSwitchCase="'CorrectionCurrency'">Waluta</span>
    <span *ngSwitchCase="'CorrectionAmountNet'">Kwota netto</span>
    <span *ngSwitchCase="'CorrectionAmountVat'">Kwota VAT</span>
    <span *ngSwitchCase="'CorrectionAmountGross'">Kwota brutto</span>
    <span *ngSwitchCase="'CorrectionSellerAccount'">Rachunek bankowy sprzedawcy</span>
    <span *ngSwitchCase="'CorrectionSellerName'">Nazwa sprzedawcy</span>
    <span *ngSwitchCase="'CorrectionSellerTaxNumber'">NIP sprzedawcy</span>
    <span *ngSwitchCase="'CorrectionSellerStreet'">Ulica sprzedawcy</span>
    <span *ngSwitchCase="'CorrectionSellerAddress'">Adres sprzedawcy</span>
    <span *ngSwitchCase="'CorrectionVatRow'">Wiersz VAT</span>
    <span *ngSwitchCase="'CorrectionSplitPayment'">Podzielona płatność</span>
    <span *ngSwitchCase="'CorrectionPaymentDeadline'">Termin płatności</span>
    <span *ngSwitchCase="'CorrectionPaymentMethod'">Metoda płatności</span>

    <span *ngSwitchCase="'IsDuplicate'">Duplikat</span>

    <!-- PrepaymentInvoice -->
    <span *ngSwitchCase="'PrepaymentInvoiceNumber'">Numer faktury</span>
    <span *ngSwitchCase="'PrepaymentCurrency'">Waluta</span>
    <span *ngSwitchCase="'PrepaymentAmountNet'">Kwota netto</span>
    <span *ngSwitchCase="'PrepaymentAmountVat'">Kwota VAT</span>
    <span *ngSwitchCase="'PrepaymentAmountGross'">Kwota brutto</span>
    <span *ngSwitchCase="'PrepaymentAmountToPay'">Kwota do zapłaty</span>
    <span *ngSwitchCase="'PrepaymentSellerAccount'">Rachunek bankowy sprzedawcy</span>
    <span *ngSwitchCase="'PrepaymentSellerName'">Nazwa sprzedawcy</span>
    <span *ngSwitchCase="'PrepaymentSellerTaxNumber'">NIP sprzedawcy</span>
    <span *ngSwitchCase="'PrepaymentSellerStreet'">Ulica sprzedawcy</span>
    <span *ngSwitchCase="'PrepaymentSellerAddress'">Adres sprzedawcy</span>
    <span *ngSwitchCase="'PrepaymentPaymentMethod'">Metoda płatności</span>
    <span *ngSwitchCase="'PrepaymentPaymentDeadline'">Termin płatności</span>
    <span *ngSwitchCase="'PrepaymentIssueDate'">Data wystwienia</span>
    <span *ngSwitchCase="'PrepaymentSaleDate'">Data sprzedaży</span>
    <span *ngSwitchCase="'PrepaymentSplitPayment'">Podzielona płatność</span>
    <span *ngSwitchCase="'PrepaymentDate'">Data płatności</span>

    <!-- BillingInvoice -->
    <span *ngSwitchCase="'BillingInvoiceNumber'">Numer faktury</span>
    <span *ngSwitchCase="'BillingCurrency'">Waluta</span>
    <span *ngSwitchCase="'BillingAmountNet'">Kwota netto</span>
    <span *ngSwitchCase="'BillingAmountVat'">Kwota VAT</span>
    <span *ngSwitchCase="'BillingAmountGross'">Kwota brutto</span>
    <span *ngSwitchCase="'BillingAmountToPay'">Kwota do zapłaty</span>
    <span *ngSwitchCase="'BillingSellerAccount'">Rachunek bankowy sprzedawcy</span>
    <span *ngSwitchCase="'BillingSellerName'">Nazwa sprzedawcy</span>
    <span *ngSwitchCase="'BillingSellerTaxNumber'">NIP sprzedawcy</span>
    <span *ngSwitchCase="'BillingSellerStreet'">Ulica sprzedawcy</span>
    <span *ngSwitchCase="'BillingSellerAddress'">Adres sprzedawcy</span>
    <span *ngSwitchCase="'BillingPaymentMethod'">Metoda płatności</span>
    <span *ngSwitchCase="'BillingPaymentDeadline'">Termin płatności</span>
    <span *ngSwitchCase="'BillingIssueDate'">Data wystawienia</span>
    <span *ngSwitchCase="'BillingSaleDate'">Data sprzedaży</span>
    <span *ngSwitchCase="'BillingSplitPayment'">Podzielona płatność</span>

    <!-- ProvisionalInvoice -->
    <!--<span *ngSwitchCase="'ProvisionalCurrency'">Waluta</span>
    <span *ngSwitchCase="'ProvisionalInvoiceNumber'">Numer faktury</span>
    <span *ngSwitchCase="'ProvisionalPaymentMethod'">Metoda płatności</span>
    <span *ngSwitchCase="'ProvisionalSplitPayment'">Podzielona płatność</span>
    <span *ngSwitchCase="'ProvisionalIssueDate'">Data wystawienia</span>
    <span *ngSwitchCase="'ProvisionalSaleDate'">Data sprzedaży</span>
    <span *ngSwitchCase="'ProvisionalPaymentDeadline'">Termin płatności</span>
    <span *ngSwitchCase="'ProvisionalSellerTaxNumber'">NIP sprzedawcy</span>
    <span *ngSwitchCase="'ProvisionalSellerAccount'">Rachunek bankowy sprzedawcy</span>
    <span *ngSwitchCase="'ProvisionalSellerName'">Nazwa sprzedawcy</span>
    <span *ngSwitchCase="'ProvisionalSellerStreet'">Ulica sprzedawcy</span>
    <span *ngSwitchCase="'ProvisionalSellerAddress'">Adres sprzedawcy</span>
    <span *ngSwitchCase="'ProvisionalVatRow'">Wiersz VAT</span>
    <span *ngSwitchCase="'ProvisionalAmountGross'">Kwota brutto</span>
    <span *ngSwitchCase="'ProvisionalAmountNet'">Kwota netto</span>
    <span *ngSwitchCase="'ProvisionalAmountVat'">Kwota VAT</span>-->

    <!-- AccountingNote -->
    <span *ngSwitchCase="'NoteNumber'">Numer noty</span>
    <span *ngSwitchCase="'NoteDate'">Data wystawienia</span>
    <span *ngSwitchCase="'NoteType'">Typ noty</span>
    <span *ngSwitchCase="'NoteCurrency'">Waluta</span>
    <span *ngSwitchCase="'NoteIssuer'">Wystawca</span>
    <span *ngSwitchCase="'NoteTaxNumber'">NIP wystawcy</span>
    <span *ngSwitchCase="'NoteSellerName'">Nazwa wystawcy</span>
    <span *ngSwitchCase="'NoteSellerStreet'">Ulica wystawcy</span>
    <span *ngSwitchCase="'NoteSellerAddress'">Adres wystawcy</span>
    <span *ngSwitchCase="'NotePaymentDeadline'">Termin płatności</span>
    <span *ngSwitchCase="'NotePaymentMethod'">Metoda płatności</span>
    <span *ngSwitchCase="'NoteAmountToPay'">Kwota do zapłaty</span>
    <span *ngSwitchCase="'NoteAccountNumber'">Numer konta wystawcy</span>
    <!-- <span *ngSwitchCase="'NoteInvoiceListRows'"></span> -->

    <span *ngSwitchDefault>Nieznana cecha {{ feature }}</span>
</ng-container>
