import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from 'src/app/services/user-service/user.service';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'digica-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

    public nickname = '';
    public organizationName = '';

    private userExtractSubscription: Subscription;

    constructor(
        private userService: UserService,
        private authService: AuthService,
    ) { }

    public ngOnInit(): void {

        this.userExtractSubscription = this.userService.userExtract
            .pipe(filter(x => x !== null))
            .subscribe(userExtract => {
                this.nickname = userExtract.nickname;
                this.organizationName = userExtract.organizationName;
            });
    }

    public ngOnDestroy(): void {

        if (this.userExtractSubscription) {
            this.userExtractSubscription.unsubscribe();
        }
    }

    public logOut(): void {
        this.authService.logOut();
    }
}
