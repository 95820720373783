<div class="container">
    <header>
        <img src="/assets/logos/digica_wText_horizontal.svg" alt="Digica">
    </header>

    <div class="auth-container">
        <form [formGroup]="form" (ngSubmit)="submit()">
            <div class="auth-box">
                <h1>Zaloguj się</h1>

                <div class="input-wrapper">
                    <div class="label">
                        E-mail
                    </div>

                    <mat-form-field floatLabel="never" class="custom">
                        <input matInput formControlName="email" autocomplete="email" />
                        <mat-error *ngIf="email.hasError('required')">
                            Wprowadź email
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="input-wrapper">
                    <div class="label">
                        Hasło
                    </div>

                    <mat-form-field floatLabel="never" class="custom">
                        <input matInput formControlName="password" type="password" autocomplete="password" />
                        <mat-error *ngIf="password.hasError('required')">
                            Wprowadź hasło
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="input-wrapper">
                    <mat-error class="default">
                        <span *ngIf="form.errors && form.errors.invalidEmailOrPassword">
                            Niepoprawny email lub hasło
                        </span>
                    </mat-error>
                    <mat-error class="default">
                        <span *ngIf="form.errors && form.errors.apiUnreachable">
                            Coś poszło nie tak, spróbuj ponownie później.
                        </span>
                    </mat-error>
                </div>

                <button
                    type="submit"
                    mat-flat-button
                    color="primary"
                    class="submit-button with-loader"
                    [class.is-loading]="isSubmitting"
                >
                    <div class="content">
                        Zaloguj się
                    </div>
                    <div class="loader">
                        <span class="icon loading"></span>
                    </div>
                </button>

                <div class="links">
                    <a [routerLink]="['/register']">Nie mam konta</a>
                    <a [routerLink]="['/forgot-password']">Nie pamiętam hasła</a>
                </div>
            </div>
        </form>
    </div>
</div>
