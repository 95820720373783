
<div class="background gray">
    <div class="container menu">
        <div class="label-container">
            <span class="label">Ustawienia</span>
        </div>

        <div class="menu-items">
            <ng-container *ngIf="(role | async) === UserRoleEnum.Admin || (role | async) === UserRoleEnum.User">
                <a [routerLink]="['/settings', 'account']" routerLinkActive="active">
                    Konto
                </a>
                <a [routerLink]="['/settings', 'uploading']" routerLinkActive="active">
                    Przesyłanie plików
                </a>
            </ng-container>

            <ng-container *ngIf="(role | async) === UserRoleEnum.Admin">
                <a [routerLink]="['/settings', 'organization']" routerLinkActive="active">
                    Organizacja
                </a>
            </ng-container>
        </div>
    </div>
</div>

<router-outlet></router-outlet>
