import { Component, Input } from '@angular/core';
import { AutomationEntryDocumentResultEnum } from 'src/app/automations/models/automation-entry-document-result.enum';

@Component({
    selector: 'digica-automation-icon',
    templateUrl: './automation-icon.component.html',
    styleUrls: ['./automation-icon.component.scss']
})
export class AutomationIconComponent {

    @Input() public automated = false;
    @Input() public result: AutomationEntryDocumentResultEnum;

    public AutomationEntryDocumentResultEnum = AutomationEntryDocumentResultEnum;
}
