import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import {
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    MatInputModule,
    MatFormFieldModule,
    MatMenuModule,
    MatDividerModule
} from '@angular/material';
import { AddDocumentsDialogComponent } from './add-documents-dialog/add-documents-dialog.component';
import { AttachmentListDialogComponent } from './attachment-list-dialog/attachment-list-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DocumentDetailsModule } from '../document-details/document-details.module';
import { DocumentDeleteDialogComponent } from './document-delete-dialog/document-delete-dialog.component';
import { DocumentConfirmDialogComponent } from './document-confirm-dialog/document-confirm-dialog.component';
import { DocumentFiltersComponent } from './document-filters/document-filters.component';
import { DocumentListComponent } from './document-list/document-list.component';
import { DocumentDetailsDialogComponent } from './document-details-dialog/document-details-dialog.component';
import { DocumentDetailsDialogContainerComponent } from './document-details-dialog-container/document-details-dialog-container.component';
import { DocumentImageComponent } from './document-image/document-image.component';
import { DocumentPageThumbnailListComponent } from './document-page-thumbnail-list/document-page-thumbnail-list.component';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import {
    DisableAutomationWhenCorrectingDialogComponent
} from './disable-automation-when-correcting-dialog/disable-automation-when-correcting-dialog.component';


const declarations = [
    AddDocumentsDialogComponent,
    AttachmentListDialogComponent,
    DocumentConfirmDialogComponent,
    DocumentDeleteDialogComponent,
    DocumentDetailsDialogComponent,
    DocumentDetailsDialogContainerComponent,
    DocumentFiltersComponent,
    DocumentImageComponent,
    DocumentListComponent,
    DocumentPageThumbnailListComponent,
    DocumentUploadComponent,
    DisableAutomationWhenCorrectingDialogComponent,
];

@NgModule({
    declarations: [
        ...declarations,
    ],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        DocumentDetailsModule,
        FormsModule,
        ReactiveFormsModule,

        MatButtonModule,
        MatDialogModule,
        MatTooltipModule,
        MatInputModule,
        MatFormFieldModule,
        MatMenuModule,
        MatDividerModule,
    ],
    exports: [
        ...declarations,
    ],
    entryComponents: [
        AddDocumentsDialogComponent,
        AttachmentListDialogComponent,
        DocumentConfirmDialogComponent,
        DocumentDeleteDialogComponent,
        DocumentDetailsDialogComponent,
        DocumentDetailsDialogContainerComponent,
        DisableAutomationWhenCorrectingDialogComponent,
    ],
})
export class DocumentsModule { }
