import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { ApprovementTypeEnum } from 'src/app/contract/document/approvement-type.enum';
import { AutomationEntryDocument } from '../models/automation-entry-document';

@Component({
    selector: 'digica-automation-documents-history',
    templateUrl: './automation-documents-history.component.html',
    styleUrls: ['./automation-documents-history.component.scss']
})
export class AutomationDocumentsHistoryComponent {

    @Input() documentsHistory: Array<AutomationEntryDocument>;
    public ApprovementTypeEnum = ApprovementTypeEnum;

}
