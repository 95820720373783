import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, NgForm } from '@angular/forms';
import { TrustedContactExtract } from 'src/app/contract/user/trusted-contact-extract';
import { MatTableDataSource } from '@angular/material/table';
import { TrustedContactService } from 'src/app/services/trusted-contact-service/trusted-contact.service';

function wildcardEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value) {
            return null;
        }

        const value = control.value;

        const re = new RegExp (/^[a-zA-Z0-9\.\-\_]+@[a-zA-Z0-9\.\-\_]+\.[a-zA-Z0-9\.\-\_]+$/);
        const isValid = re.test(value);

        return !isValid ? { invalidEmail: { value: control.value } } : null;
    };
}

@Component({
    selector: 'digica-settings-trusted-contacts',
    templateUrl: './settings-trusted-contacts.component.html',
    styleUrls: ['./settings-trusted-contacts.component.scss']
})
export class SettingsTrustedContactsComponent implements OnInit {

    @ViewChild('form') form: NgForm;

    public trustedContacts: TrustedContactExtract[];
    public dataSource = new MatTableDataSource(this.trustedContacts);
    public trustedContactColumns = ['email', 'addDateTime', 'actions'];

    public trustedContactForm: FormGroup = new FormGroup({
        email: new FormControl('', [Validators.required, wildcardEmailValidator()]),
    });

    constructor(
        private trustedContactService: TrustedContactService,
    ) { }

    ngOnInit() {
        this.trustedContactService.GetTrustedContacts().subscribe(
            trustedContacts => {
                this.dataSource.data = trustedContacts;
            }
        );
    }

    trustedContactSubmit() {

        const email = this.trustedContactForm.controls.email.value;

        if (this.trustedContactForm.valid) {
            this.trustedContactService.AddTrustedContact(email).subscribe(
                (trustedContact: TrustedContactExtract) => {
                    this.dataSource.data.push(trustedContact);
                    this.dataSource._updateChangeSubscription();

                    this.form.resetForm();
                }
            );
        }
    }

    deleteTrustedContact(id: number) {
        this.trustedContactService.DeleteTrustedContact(id).subscribe(
            _ => {
                this.dataSource.data = this.dataSource.data.filter(x => x.id !== id);
            }
        );
    }
}
