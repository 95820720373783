import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { UserService } from 'src/app/services/user-service/user.service';
import { MatDialog } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { GroupByDayPipe } from 'src/app/shared/pipes/group-by-day.pipe';
import { DocumentStatusEnum } from 'src/app/contract/document/document-status.enum';
import { DocumentGroup } from 'src/app/document-group/models/document-group.model';
import { DocumentGroupService } from 'src/app/services/document-group-service/document-group.service';
import { DocumentDeleteDialogComponent } from 'src/app/documents/document-delete-dialog/document-delete-dialog.component';
import { DocumentDeleteDialogData } from 'src/app/documents/document-delete-dialog/document-delete-dialog.data';

@Component({
    selector: 'digica-split-list',
    templateUrl: './split-list.component.html',
    styleUrls: ['./split-list.component.scss']
})
export class SplitListComponent implements OnInit, OnDestroy {

    @ViewChild('tableWrapper') tableWrapper: ElementRef;

    public isLoading = false;
    public areNewDocumentGroupsAvailable = false;
    public groupedItems: Array<{ day: string, value: DocumentGroup[] }> = [];
    public DocumentStatusEnum = DocumentStatusEnum;
    public isEveryDocumentGroupLoaded = false;
    public page = 0;

    private readonly pageSize = 10;
    private filters: any[];
    private fetchSubscription: Subscription;
    private documentGroupUpdateSubscription: Subscription;

    constructor(
        private userService: UserService,
        private dialog: MatDialog,
        private titleService: Title,
        private documentGroupService: DocumentGroupService,
        private groupByDayPipe: GroupByDayPipe,
    ) { }

    public ngOnInit(): void {

        this.titleService.setTitle(`Pliki do podziału | ${environment.applicationTabName}`);
        window.addEventListener('scroll', this.checkLoadMoreResults, true);
        this.watchForDocumentGroupUpdate();
    }

    public ngOnDestroy(): void {

        window.removeEventListener('scroll', this.checkLoadMoreResults, true);

        if (this.fetchSubscription) {
            this.fetchSubscription.unsubscribe();
        }

        if (this.documentGroupUpdateSubscription) {
            this.documentGroupUpdateSubscription.unsubscribe();
        }
    }

    private watchForDocumentGroupUpdate(): void {

        this.documentGroupService.ensureWebsocketConnectionStarted();
        this.documentGroupUpdateSubscription = this.documentGroupService.documentGroupUpdate
            .subscribe(documentGroup => {

                let isUpdated = false;

                this.groupedItems.forEach(group => {

                    const index = group.value.findIndex(x => x.id === documentGroup.id);
                    if (index !== -1) {
                        group.value[index] = documentGroup;
                        isUpdated = true;
                    }
                });

                if (!isUpdated && this.isEveryDocumentGroupLoaded) {
                    this.areNewDocumentGroupsAvailable = true;
                }
            });
    }

    public reloadResults() {

        if (this.fetchSubscription) {
            this.fetchSubscription.unsubscribe();
            this.isLoading = false;
        }

        this.page = 0;
        this.groupedItems = [];
        this.isEveryDocumentGroupLoaded = false;
        this.areNewDocumentGroupsAvailable = false;
        this.loadNextPage();
    }

    private loadNextPage() {

        if (this.isEveryDocumentGroupLoaded || this.isLoading) {
            return;
        }

        this.isLoading = true;
        this.page += 1;

        const queryData = {
            filter: this.filters || [],
            orderBy: [`addDatetime asc`],
            top: this.pageSize,
            skip: this.pageSize * (this.page - 1)
        };

        this.fetchSubscription = this.documentGroupService.getDdocumentGroups(queryData)
            .subscribe(
                data => {
                    this.isLoading = false;

                    const grouped = this.groupByDayPipe.transform(data, 'uploadDatetime');
                    grouped.forEach(dayCollection => {
                        const group = this.groupedItems.find(x => x.day === dayCollection.day);
                        if (!group) {
                            this.groupedItems.push(dayCollection);
                        } else {
                            group.value = group.value.concat(dayCollection.value);
                        }
                    });

                    this.isEveryDocumentGroupLoaded = data.length !== this.pageSize;

                    timer(500).subscribe(() => {
                        this.checkLoadMoreResults();
                    });
                },
                _ => {
                    this.isLoading = false;
                }
            );
    }

    private checkLoadMoreResults = (): void => {

        const element = document.querySelector('.default-layout') as HTMLElement;
        const scrollPosition = element.scrollTop + window.innerHeight;
        const tableHeight = this.tableWrapper.nativeElement.offsetHeight;
        const tableOffsetTop = this.tableWrapper.nativeElement.offsetTop;
        const tablePosition = tableOffsetTop + tableHeight;

        if (scrollPosition >= tablePosition) {
            this.loadNextPage();
        }
    }

    public getImageUrl(item: DocumentGroup): string {
        return this.documentGroupService.getPageImageUrl(item.id, 1, 'small');
    }

    public onFiltersSelected(filters: any[]): void {

        this.filters = filters;
        this.reloadResults();
    }

    public delete(item: DocumentGroup): void {

        const data: DocumentDeleteDialogData = { documentNumbers: [null] };
        const dialogRef = this.dialog.open(DocumentDeleteDialogComponent, { data, autoFocus: false });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.documentGroupService.delete([item.id]).subscribe(_ => {
                    this.groupedItems.map(group => {
                        group.value.filter(x => x.id !== item.id);
                    });
                });
            }
        });
    }

    public openMenu($event: MouseEvent) {
        $event.stopPropagation();
        $event.preventDefault();
    }

    public isItemLocked(item: DocumentGroup): boolean {

        return item.status !== DocumentStatusEnum.Splitted
            && item.splitterId !== null
            && item.splitterId !== this.userService.userExtract.value.userId;
    }

    public canActivate(item: DocumentGroup): boolean {
        return !this.isItemLocked(item)
            && item.status !== DocumentStatusEnum.GeneratingPreview
            && item.status !== DocumentStatusEnum.Splitted
            && item.status !== DocumentStatusEnum.Deleted;
    }

    public getTooltipText(item: DocumentGroup): string {

        if (this.isItemLocked(item)) {
            return `Dokument dzielony przez ${item.splitterNickname}`;
        }

        return null;
    }

    public documentGroupTrackBy = (_: number, documentGroup: DocumentGroup) => documentGroup.id;
    public groupedItemsTrackBy = (_: number, groupedItem: { day: string, value: DocumentGroup[] }) => groupedItem.day;

    public countResults(): number {

        return this.groupedItems.reduce((sum, current) => sum + current.value.length, 0);
    }
}
