import { Injectable } from '@angular/core';
import { IFormatter } from '../iformatter';

@Injectable({
    providedIn: 'root'
})
export class AccountFormatterService implements IFormatter {

    constructor() { }

    public formatInput($event: any): void {

        const input = $event.target as HTMLInputElement;
        input.value = this.format(input.value);
    }

    public format(value: string): string {

        value = value.replace(/[^a-z0-9]/ig, '');

        let count = 2;
        if (value.charAt(0).match(/[a-z]/i)) {
            count -= 1;
        }
        if (value.charAt(1).match(/[a-z]/i)) {
            count -= 1;
        }

        value = value.replace(/\s/g, '');
        let output = '';
        for (let i = 0; i < value.length; i += 1) {
            output += value.charAt(i);
            count += 1;

            if (count === 4 && i !== value.length - 1) {
                output += ' ';
                count = 0;
            }
        }

        return output;
    }

    public parse(value: string): string {

        value = value.replace(/[^A-Z0-9]/g, '');
        return value;
    }

    public parseWithoutCountryCode(value: string): string {

        return value.toUpperCase().replace(/[^0-9]/g, '');
    }

    public getCountryCode(value: string): string {

        if (!value.charAt(0).match(/[a-z]/i) ||
            !value.charAt(1).match(/[a-z]/i)) {
            return null;
        }

        return value.substr(0, 2).toUpperCase();
    }
}
