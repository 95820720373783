import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentDeleteDialogData } from './document-delete-dialog.data';

@Component({
    templateUrl: './document-delete-dialog.component.html',
})
export class DocumentDeleteDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<DocumentDeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DocumentDeleteDialogData,
    ) { }
}
