import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentStatusEnum } from 'src/app/contract/document/document-status.enum';
import { DocumentGroupService } from 'src/app/services/document-group-service/document-group.service';
import { DocumentService } from 'src/app/services/document-service/document.service';

@Component({
    selector: 'digica-analyzing-documents',
    templateUrl: './analyzing-documents.component.html',
    styleUrls: ['./analyzing-documents.component.scss']
})
export class AnalyzingDocumentsComponent implements OnInit, OnDestroy {

    public analyzingIds: Array<number> = [];
    private analyzingDocumentsSubscription: Subscription;
    private documentGroupUpdateSubscription: Subscription;

    constructor(
        private documentService: DocumentService,
        private documentGroupService: DocumentGroupService,
    ) { }

    public ngOnInit(): void {
        this.analyzingDocumentsSubscription = this.documentService.getAnalyzingDocuments()
            .pipe(map(x => x.map(y => y.id)))
            .subscribe(analyzingIds => {
                this.analyzingIds = analyzingIds;
            });

        this.watchForAnalyzingCountChange();
    }

    private watchForAnalyzingCountChange(): void {

        this.documentGroupService.ensureWebsocketConnectionStarted();
        this.documentGroupUpdateSubscription = this.documentGroupService.documentGroupUpdate
            .subscribe(documentGroup => {

                for (const child of documentGroup.children) {

                    const isInArray = this.analyzingIds.includes(child.id);
                    if (child.status === DocumentStatusEnum.Analyzing && !isInArray) {
                        this.analyzingIds.push(child.id);
                    } else if (child.status === DocumentStatusEnum.ToConfirm && isInArray) {
                        this.analyzingIds = this.analyzingIds.filter(x => x !== child.id);
                    }
                }
            });
    }

    public ngOnDestroy(): void {

        if (this.analyzingDocumentsSubscription) {
            this.analyzingDocumentsSubscription.unsubscribe();
        }

        if (this.documentGroupUpdateSubscription) {
            this.documentGroupUpdateSubscription.unsubscribe();
        }
    }
}
