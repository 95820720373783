import { Injectable } from '@angular/core';
import { CallService } from '../call-service/call.service';
import { Observable, Subject } from 'rxjs';
import { WebSocketService } from '../web-socket-service/web-socket.service';
import { HubConnection } from '@microsoft/signalr';
import buildQuery from 'odata-query';
import { UserService } from '../user-service/user.service';
import { ScanPageMarker } from 'src/app/split/split-dialog/scan-page-marker';
import { DocumentStatusEnum } from 'src/app/contract/document/document-status.enum';
import { DocumentGroup } from 'src/app/document-group/models/document-group.model';
import { ScanDocumentMarker } from 'src/app/sp-attachment/sp-attachment-dialog/scan-document-marker';

@Injectable({
    providedIn: 'root'
})
export class DocumentGroupService {

    public documentGroupUpdate = new Subject<DocumentGroup>();

    private readonly servicePrefix = 'document-group';
    private connection: HubConnection = null;

    constructor(
        private callService: CallService,
        private webSocketService: WebSocketService,
        private userService: UserService,
    ) { }

    public ensureWebsocketConnectionStarted(): void {

        if (this.connection !== null) {
            return;
        }

        this.connection = this.webSocketService.buildConnection(`${this.servicePrefix}/hub`);
        this.connection.on('DocumentGroupUpdated', data => {
            const documentGroup = data as DocumentGroup;
            this.documentGroupUpdate.next(documentGroup);
        });
    }

    public getDocumentGroup(id: number): Observable<DocumentGroup> {
        return this.callService.get<DocumentGroup>(`${this.servicePrefix}/${id}`);
    }

    public getDdocumentGroups(queryData: any): Observable<DocumentGroup[]> {

        const query = buildQuery(queryData);
        return this.callService.get<DocumentGroup[]>(`${this.servicePrefix}${query}`);
    }

    public turnOnEdit(id: number) {
        return this.callService.postForm(`${this.servicePrefix}/${id}/turnOnEdit`, {});
    }

    public turnOffEdit(id: number) {
        return this.callService.postForm(`${this.servicePrefix}/${id}/turnOffEdit`, {});
    }

    public setDocumentMarkers(id: number, receiptDate: Date, markers: ScanDocumentMarker[]) {

        return this.callService.postForm(`${this.servicePrefix}/${id}/documentMarkers`, {
            dateOfReceipt: receiptDate.toJSON(),
            markers: JSON.stringify(markers),
        });
    }

    public setScanMarkers(id: number, receiptDate: Date, markers: ScanPageMarker[]) {

        return this.callService.postForm(`${this.servicePrefix}/${id}/markers`, {
            dateOfReceipt: receiptDate.toJSON(),
            markers: JSON.stringify(markers),
        });
    }

    public getPageImageUrl(documentId: number, page: number, size: 'small' | 'big' = 'small') {
        return `${this.callService.apiUrl}/${this.servicePrefix}/${documentId}/image/${page}?size=${size}`;
    }

    public getNextDocument(currentId: number): Observable<Array<DocumentGroup>> {

        const filter = [
            { 'CurrentStatus/Value': DocumentStatusEnum[DocumentStatusEnum.ToBeMarked] },
            { DocumentGroupId: { ne: currentId }},
            { or: [
                { Editor: null },
                { 'Editor/UserId': this.userService.userExtract.value.userId }
            ]}
        ];
        const orderBy = [`addDateTime asc`];
        const top = 1;

        const query = buildQuery({ filter, orderBy, top });

        return this.callService.get<Array<DocumentGroup>>(`${this.servicePrefix}${query}`);
    }

    public delete(documentIds: number[]) {
        return this.callService.delete(`${this.servicePrefix}`, { documentIds });
    }
}
