export class Point {

    public x: number;
    public y: number;

    constructor(x?: number, y?: number) {

        this.x = x ? x : 0;
        this.y = y ? y : 0;
    }

    public set(x: number, y: number) {
        this.x = x;
        this.y = y;
    }
}
