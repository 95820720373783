import { Area } from 'src/app/services/interactive-image-service/area';
import { DocumentTypeEnum } from './document-type.enum';

export class DocumentFeature<T> {

    public static documentTypeFeatures: { [K in DocumentTypeEnum]: Array<string>; } = {
        [DocumentTypeEnum.PurchaseInvoice]: [
            'Currency',
            'InvoiceNumber',
            'PaymentMethod',
            'SplitPayment',
            'IssueDate',
            'SaleDate',
            'PaymentDeadline',
            'SellerTaxNumber',
            'SellerAccount',
            'SellerName',
            'SellerStreet',
            'SellerAddress',
            'VatRow',
            'AmountGross',
            'AmountNet',
            'AmountVat',
            'IsDuplicate'
        ],
        [DocumentTypeEnum.InvoiceCorrection]: [
            'CorrectedInvoiceNumber',
            'CorrectionNumber',
            'CorrectionDate',
            'CorrectionSaleDate',
            'CorrectionCurrency',
            'CorrectionAmountNet',
            'CorrectionAmountVat',
            'CorrectionAmountGross',
            'CorrectionSellerAccount',
            'CorrectionSellerName',
            'CorrectionSellerTaxNumber',
            'CorrectionSellerStreet',
            'CorrectionSellerAddress',
            'CorrectionVatRow',
            'IsDuplicate'
        ],
        // [DocumentTypeEnum.ProvisionalInvoice]: [
        //    'ProvisionalInvoiceNumber',
        //    'ProvisionalCurrency',
        //    'ProvisionalPaymentMethod',
        //    'ProvisionalSplitPayment',
        //    'ProvisionalIssueDate',
        //    'ProvisionalSaleDate',
        //    'ProvisionalPaymentDeadline',
        //    'ProvisionalSellerTaxNumber',
        //    'ProvisionalSellerAccount',
        //    'ProvisionalSellerName',
        //    'ProvisionalSellerStreet',
        //    'ProvisionalSellerAddress',
        //    'ProvisionalVatRow',
        //    'ProvisionalAmountGross',
        //    'ProvisionalAmountNet',
        //    'ProvisionalAmountVat',
        //    'IsDuplicate'
        // ],
        [DocumentTypeEnum.PrepaymentInvoice]: [
            'PrepaymentInvoiceNumber',
            'PrepaymentCurrency',
            'PrepaymentAmountNet',
            'PrepaymentAmountVat',
            'PrepaymentAmountGross',
            'PrepaymentAmountToPay',
            'PrepaymentSellerAccount',
            'PrepaymentSellerName',
            'PrepaymentSellerTaxNumber',
            'PrepaymentSellerStreet',
            'PrepaymentSellerAddress',
            'PrepaymentPaymentMethod',
            'PrepaymentPaymentDeadline',
            'PrepaymentIssueDate',
            'PrepaymentSaleDate',
            'PrepaymentSplitPayment',
            'PrepaymentVatRow',
            'PrepaymentDate',
            'IsDuplicate'
        ],
        [DocumentTypeEnum.BillingInvoice]: [
            'BillingInvoiceNumber',
            'BillingCurrency',
            'BillingAmountNet',
            'BillingAmountVat',
            'BillingAmountGross',
            'BillingAmountToPay',
            'BillingSellerAccount',
            'BillingSellerName',
            'BillingSellerTaxNumber',
            'BillingSellerStreet',
            'BillingSellerAddress',
            'BillingPaymentMethod',
            'BillingPaymentDeadline',
            'BillingIssueDate',
            'BillingSaleDate',
            'BillingSplitPayment',
            'BillingVatRow',
            'BillingDate',
            'IsDuplicate'
        ],
        [DocumentTypeEnum.AccountingNote]: [
            'NoteNumber',
            'NoteDate',
            'NoteType',
            'NoteCurrency',
            'NoteIssuer',
            'NoteTaxNumber',
            'NoteSellerName',
            'NoteSellerStreet',
            'NoteSellerAddress',
            'NotePaymentDeadline',
            'NotePaymentMethod',
            'NoteAmountToPay',
            'NoteAccountNumber',
            // 'NoteInvoiceListRows'
        ]
    };

    id: number;
    value: T;
    x: number;
    y: number;
    width: number;
    height: number;
    page: number = null;

    public static assignArea(feature: DocumentFeature<any>, area: Area): void {
        feature.x = area.x;
        feature.y = area.y;
        feature.width = area.width;
        feature.height = area.height;
        feature.page = area.page;
    }

    // tslint:disable-next-line:max-line-length
    public static isVatRowFeature = (featureName: string) => featureName === 'VatRow' || featureName === 'CorrectionVatRow' || featureName === 'PrepaymentVatRow' || featureName === 'BillingVatRow';
}
