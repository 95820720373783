<ng-container [ngSwitch]="documentStatus">
    <ng-container *ngSwitchCase="DocumentStatusEnum.Pending">
        Oczekuje
    </ng-container>
    <ng-container *ngSwitchCase="DocumentStatusEnum.Analyzing">
        Trwa przetwarzanie
    </ng-container>
    <ng-container *ngSwitchCase="DocumentStatusEnum.ToConfirm">
        Do potwierdzenia
    </ng-container>
    <ng-container *ngSwitchCase="DocumentStatusEnum.Error">
        Błąd
    </ng-container>
    <ng-container *ngSwitchCase="DocumentStatusEnum.Archived">
        Zarchwizowane
    </ng-container>
    <ng-container *ngSwitchCase="DocumentStatusEnum.ToBeMarked">
        Do podzielenia
    </ng-container>
    <ng-container *ngSwitchCase="DocumentStatusEnum.GeneratingPreview">
        Trwa generowanie podglądu
    </ng-container>
</ng-container>
