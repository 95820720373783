import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Area } from './area';
import { OcrBox } from 'src/app/contract/document/ocr-box';
import { Point } from './point';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { DocumentImageComponent } from 'src/app/documents/document-image/document-image.component';

@Injectable({
    providedIn: 'root'
})
export class InteractiveImageService {

    public focusedFeatureArea = new Subject<Area>();
    public selectedCallback = new BehaviorSubject<(text: string, area: Area) => void>(() => {});
    public currentPage = new BehaviorSubject<number>(1);
    public featurePageSwitcher = new BehaviorSubject<{ focusedFeaturePage: number, focusedFeatureReturnPage: number }>(null);

    constructor() { }

    public readOcrBoxes(ocrboxes: OcrBox[]): string {
        ocrboxes = ocrboxes.sort((x, y) => x.y - y.y);

        const orderedOcrboxes: OcrBox[] = [];

        while (ocrboxes.length) {
            const currentLineOcrboxes = [ocrboxes[0]];
            const toRemove = [0];

            for (let i = 1; i < ocrboxes.length; i++) {

                const middleCurrent = currentLineOcrboxes[0].y + (currentLineOcrboxes[0].height / 2);
                const middleOther = ocrboxes[i].y + (ocrboxes[i].height / 2);

                if (
                    (
                        middleCurrent >= ocrboxes[i].y
                        && middleCurrent <= ocrboxes[i].y + ocrboxes[i].height
                    ) || (
                        middleOther >= currentLineOcrboxes[0].y
                        && middleOther <= currentLineOcrboxes[0].y + currentLineOcrboxes[0].height
                    )
                ) {
                    currentLineOcrboxes.push(ocrboxes[i]);
                    toRemove.push(i);
                }
            }

            for (let i = 0; i < toRemove.length; i++) {
                ocrboxes.splice(toRemove[i] - i, 1);
            }

            orderedOcrboxes.push(...currentLineOcrboxes.sort((x, y) => x.x - y.x));
        }

        let text = orderedOcrboxes.reduce((prev, curr) => `${prev} ${curr.value}`, '');
        text = text.trim();
        return text;
    }

    public convertCanvasPointToImage(component: DocumentImageComponent, posX: number, posY: number): Point {

        const x = ((((
            posX - component.drawOffset.x)
            * component.imageWidth)
            / component.drawWidth / component.zoom)
            + component.zoomPosition.x)
            * component.originalWidth / component.imageWidth;
        const y = ((((
            posY - component.drawOffset.y)
            * component.imageWidth)
            / component.drawWidth / component.zoom)
            + component.zoomPosition.y)
            * component.originalHeight / component.imageHeight;

        return new Point(Math.round(x), Math.round(y));
    }

    public convertImagePointToCanvas(component: DocumentImageComponent, posX: number, posY: number): Point {

        const x = component.drawOffset.x
            + (((((
                component.imageWidth
                * posX)
                / component.originalWidth)
                - component.zoomPosition.x)
                * component.drawWidth
                * component.zoom)
                / component.imageWidth);

        const y = component.drawOffset.y
            + (((((
                component.imageHeight
                * posY)
                / component.originalHeight)
                - component.zoomPosition.y)
                * component.drawWidth
                * component.zoom)
                / component.imageWidth);

        return new Point(Math.round(x), Math.round(y));
    }

    public calculateZoomPosition(component: DocumentImageComponent, point: Point) {

        if (typeof component.imageWidth === 'undefined' || typeof component.imageHeight === 'undefined') {
            return;
        }

        let x = ((point.x * component.imageWidth / component.originalWidth) - component.zoomPosition.x) * component.zoom;
        let y = ((point.y * component.imageHeight / component.originalHeight) - component.zoomPosition.y) * component.zoom;

        x = x - (component.imageWidth / component.zoom / 2);
        y = y - (component.imageHeight / component.zoom / 2);

        x = Math.max(0, Math.min(component.imageWidth - (component.imageWidth / component.zoom), x));
        y = Math.max(0, Math.min(component.imageHeight - (component.imageHeight / component.zoom), y));

        component.zoom = 2;
        component.zoomPosition.set(x, y);
    }

    public resizeCanvasToParent(component: DocumentImageComponent) {

        let desiredWidth = component.canvas.nativeElement.parentNode.offsetWidth;
        let desiredHeight = desiredWidth * 1.41428571429;
        if (desiredHeight > window.innerHeight - 16) {
            desiredHeight = window.innerHeight - 16;
            desiredWidth = Math.floor(desiredHeight * 0.70707070707);
        }

        if (component.canvas.nativeElement.width !== desiredWidth) {
            component.canvas.nativeElement.width = desiredWidth;
            component.canvas.nativeElement.height = desiredHeight;
        }
    }

    public getOcrBoxesInside(ocrboxes: OcrBox[], area: Area): OcrBox[] {
        return ocrboxes.filter(x => area.containsOcrBox(x));
    }
}
