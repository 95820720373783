import { Component, Input } from '@angular/core';
import { StatusEnum } from './status.enum';

@Component({
    selector: 'digica-status-icon',
    templateUrl: './status-icon.component.html',
    styleUrls: ['./status-icon.component.scss']
})
export class StatusIconComponent {

    @Input() status: StatusEnum;
    public StatusEnum = StatusEnum;
}
