import { Injectable } from '@angular/core';
import { CallService } from '../call-service/call.service';
import { Observable } from 'rxjs';
import { DocumentTypeEnum } from 'src/app/contract/document/document-type.enum';
import { AutomationEntryExtract } from 'src/app/automations/models/automation-entry-extract';

@Injectable({
    providedIn: 'root'
})
export class AutomationService {
    private servicePrefix = 'automations';

    constructor(
        private callService: CallService,
    ) { }

    public getAutomationEntryExtracts(
        top: number,
        skip: number,
        documentType: DocumentTypeEnum,
        contractor: string
    ): Observable<AutomationEntryExtract[]> {
        return this.callService.get<AutomationEntryExtract[]>(`${this.servicePrefix}`, { top, skip, documentType, contractor });
    }

    public getAutomationEntryPropositions(
        top: number,
        skip: number,
        documentType: DocumentTypeEnum,
        contractor: string,
        isAutomated: boolean = null,
    ): Observable<AutomationEntryExtract[]> {

        return this.callService.get<AutomationEntryExtract[]>(`${this.servicePrefix}/propositions`, {
            top,
            skip,
            documentType,
            contractor,
            isAutomated
        });
    }

    public createAutomationEntry(contractorTaxNumber: string, documentType: DocumentTypeEnum): Observable<void> {
        return this.callService.postForm<void>(`${this.servicePrefix}/documentType`, { contractorTaxNumber, documentType });
    }

    public removeAutomationEntry(id: number, documentType: DocumentTypeEnum): Observable<void> {
        return this.callService.delete<void>(`${this.servicePrefix}/${id}/documentType/${documentType}`);
    }
}
