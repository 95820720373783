import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AutomationService } from 'src/app/services/automation-service/automation.service';
import { DisableAutomationWhenCorrectingDialogData } from './disable-automation-when-correcting-dialog.data';

@Component({
    templateUrl: './disable-automation-when-correcting-dialog.component.html',
    styleUrls: ['./disable-automation-when-correcting-dialog.component.scss']
})
export class DisableAutomationWhenCorrectingDialogComponent {

    public isTurningOff = false;

    constructor(
        public dialogRef: MatDialogRef<DisableAutomationWhenCorrectingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DisableAutomationWhenCorrectingDialogData,
        private automationService: AutomationService,
    ) { }

    public formatBoolean = (bool: string) => bool ? 'Tak' : 'Nie';

    public turnOffAutomations(): void {

        this.isTurningOff = true;

        this.automationService
            .removeAutomationEntry(this.data.automationEntryExtract.id, this.data.documentType)
            .subscribe(_ => {
                this.isTurningOff = false;
                this.dialogRef.close(true);
            }, _ => {
                this.isTurningOff = false;
            });
    }
}
