import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ValidationService } from 'src/app/services/validation-service/validation.service';

@Component({
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    public form: FormGroup = new FormGroup({
        nickname: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required]),
        phone: new FormControl('', [Validators.pattern('\\d{9}')]),
        organizationName: new FormControl('', [Validators.required]),
        taxNumber: new FormControl('', [Validators.required, this.validationService.taxNumberValidator()]),
        typeOfActivity: new FormControl('', [Validators.required]),
        monthlyProcessedDocuments: new FormControl(''),
        marketingAgreement: new FormControl(true, [Validators.requiredTrue]),
    });
    public isSubmitting = false;
    public isRegisterd = false;
    public indexExpanded = 1;

    public get nickname() { return this.form.get('nickname') as FormControl; }
    public get email() { return this.form.get('email') as FormControl; }
    public get phone() { return this.form.get('phone') as FormControl; }
    public get organizationName() { return this.form.get('organizationName') as FormControl; }
    public get taxNumber() { return this.form.get('taxNumber') as FormControl; }
    public get typeOfActivity() { return this.form.get('typeOfActivity') as FormControl; }
    public get monthlyProcessedDocuments() { return this.form.get('monthlyProcessedDocuments') as FormControl; }
    public get marketingAgreement() { return this.form.get('marketingAgreement') as FormControl; }

    constructor(
        private authService: AuthService,
        private titleService: Title,
        private router: Router,
        private validationService: ValidationService,
    ) { }

    public ngOnInit(): void {

        this.titleService.setTitle(`Rejestracja | ${environment.applicationTabName}`);
    }

    public submit(): void {
        if (this.form.valid) {

            this.isSubmitting = true;

            const nickname = this.nickname.value;
            const email = this.email.value;
            const phone = this.phone.value;
            const organizationName = this.organizationName.value;
            const taxNumber = this.taxNumber.value;
            const typeOfActivity = this.typeOfActivity.value;
            const monthlyProcessedDocuments = this.monthlyProcessedDocuments.value;

            const urlTree = this.router.createUrlTree(['/activate']);
            const url = `${window.location.origin}${urlTree}`;

            this.authService.register(
                nickname,
                email,
                phone,
                organizationName,
                taxNumber,
                typeOfActivity,
                monthlyProcessedDocuments,
                url
            ).subscribe(
                _ => {
                    this.isSubmitting = false;
                    this.isRegisterd = true;
                },
                () => {

                    this.isSubmitting = false;
                    this.form.setErrors({ errorOccurred: true });
                }
            );
        }
    }

    public changeToStep(index: number): void {

        let isValid = true;
        if (index === 2) {

            this.nickname.markAsTouched();
            this.email.markAsTouched();
            this.phone.markAsTouched();

            isValid = this.nickname.valid
                    && this.email.valid
                    && this.phone.valid;
        } else if (index === 3) {

            this.organizationName.markAsTouched();
            this.taxNumber.markAsTouched();
            this.typeOfActivity.markAsTouched();

            isValid = this.organizationName.valid
                    && this.taxNumber.valid
                    && this.typeOfActivity.valid;

        }

        if (isValid) {
            this.indexExpanded = index;
        }
    }
}
