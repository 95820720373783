
<div #uploadArea class="upload-container" [class.is-dragover]="isDragOver">
    <div class="upload-input-content">
        <span class="call-to-action">Prześlij dokument</span>
        <span class="drop-info">Upuść pliki aby dodać je do systemu</span>
    </div>

    <label
        for="upload"
        class="dropzone"
        digicaDragDrop
        (dragEvent)="isDragOver = $event"
        (filesDropped)="onFilesDropped($event)"
    >
    </label>

    <input
        id="upload"
        type="file"
        (change)="onFilesSelected($event)"
        placeholder="Upload file"
        accept="application/pdf, image/*"
        hidden
        multiple
    />
</div>

<div class="uploading-files" *ngIf="uploadingFiles.length">
    <div class="label">Wysyłane pliki</div>

    <div
        *ngFor="let file of uploadingFiles"
        [class.upload-finished]="file.isUploadFinished"
        [class.has-error]="file.uploadStatus !== UploadStatus.Uploaded"
        class="file"
    >
        <div class="file-info">
            <div class="name">{{ file.name }}</div>
            <div class="size">{{ file.size }}</div>
        </div>

        <div class="success-message" *ngIf="file.uploadStatus === UploadStatus.Uploaded">
            Wysyłanie pliku zakończone
        </div>

        <div class="uploading-message" *ngIf="file.uploadStatus === null">
            Trwa przesyłanie pliku
        </div>

        <div class="error-message" [ngSwitch]="file.uploadStatus">
            <ng-container *ngSwitchCase="UploadStatus.BadDocumentFormat">
                Nieprawidłowy format pliku
            </ng-container>
            <ng-container *ngSwitchCase="UploadStatus.InvalidExtension">
                Nieprawidłowe rozszerzenie pliku
            </ng-container>
            <ng-container *ngSwitchCase="UploadStatus.Duplicate">
                Wykryto ten dokument w systemie, plik nie został przesłany
            </ng-container>
            <ng-container *ngSwitchCase="UploadStatus.Error">
                Wystąpił błąd, spróbuj ponownie poźniej 
            </ng-container>
        </div>
    </div>
</div>
