import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material';
import { UserService } from 'src/app/services/user-service/user.service';
import { filter, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
    selector: 'digica-settings-emails',
    templateUrl: './settings-emails.component.html',
    styleUrls: ['./settings-emails.component.scss']
})
export class SettingsEmailsComponent implements OnInit {

    public documentEmail = '';
    public eInvoiceEmail = '';

    @ViewChild('emailCopiedTemplate') emailCopiedTemplate: TemplateRef<any>;

    constructor(
        private snackBar: MatSnackBar,
        private userService: UserService,
    ) { }

    public ngOnInit(): void {

        this.userService.userExtract
            .pipe(filter(x => x !== null), take(1))
            .subscribe(
                userExtract => {
                    this.documentEmail = userExtract.frugalEmail;
                    this.eInvoiceEmail = userExtract.eInvoiceEmail;
                }
            );
    }

    public copyToClipboard(inputElement: HTMLInputElement): void {
        inputElement.focus();
        inputElement.select();
        document.execCommand('copy');
        window.getSelection().removeAllRanges();

        this.openEmailCopiedSnackbar();
    }

    private openEmailCopiedSnackbar(): void {
        const config = new MatSnackBarConfig();
        config.verticalPosition = 'bottom';
        config.horizontalPosition = 'center';
        config.duration = 3000;

        this.snackBar.openFromTemplate(this.emailCopiedTemplate, config);
    }
}
