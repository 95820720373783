import { Injectable } from '@angular/core';
import { CallService } from '../call-service/call.service';
import { UserExtract } from 'src/app/contract/user/user-extract';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth-service/auth.service';
import { UserRoleEnum } from 'src/app/contract/user/user-role.enum';
import { AccountingSystemEnum } from 'src/app/contract/user/accounting-system.enum';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private servicePrefix = 'user';

    public userExtract = new BehaviorSubject<UserExtract>(null);

    constructor(
        private callService: CallService,
        private authService: AuthService,
    ) {
        this.loadUserExtract();
    }

    public saveAccountSettings(userExtract: UserExtract) {
        return this.callService.patch(`${this.servicePrefix}/userSettings`, userExtract);
    }

    public reloadUserExtract(): void {

        this.userExtract.next(null);
        this.loadUserExtract();
    }

    public loadUserExtract(): void {
        const token = this.authService.GetDecodedToken();
        if (token === null) {
            return;
        }

        const userId = this.authService.GetDecodedToken().UserId;
        this.callService.get<UserExtract>(`${this.servicePrefix}/${userId}`).subscribe(
            data => this.userExtract.next(data)
        );
    }

    public SetUserRole(userId: number, role: UserRoleEnum) {
        return this.callService.postForm(`${this.servicePrefix}/${userId}/role`, { role });
    }

    public changePassword(password: string, newPassword: string) {
        return this.callService.patchForm(`${this.servicePrefix}/password`, {
            oldPassword: password,
            newPassword
        });
    }

    public updateUserSettings(userExtract: UserExtract) {
        return this.callService.patch(`${this.servicePrefix}/userSettings`, userExtract);
    }
}
