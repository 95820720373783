import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CallService } from '../call-service/call.service';
import { map } from 'rxjs/operators';
import { UserRoleEnum } from 'src/app/contract/user/user-role.enum';
import { StorageService } from '../storage-service/storage.service';
import { Subject } from 'rxjs';
import { UserExtract } from 'src/app/contract/user/user-extract';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public logout$ = new Subject<void>();

    private servicePrefix = 'auth';
    private readonly tokenName = 'frugal_sip_auth_tokena';
    private helper = new JwtHelperService();
    private loginTargetUrl: string = null;
    private readonly loginRoute = '/login';

    constructor(
        private callService: CallService,
        private router: Router,
        private storageService: StorageService,
    ) { }

    public getToken(): string {
        return this.storageService.get(this.tokenName);
    }

    public isAuthenticated(): boolean {
        const rawToken = this.getToken();
        return !this.helper.isTokenExpired(rawToken);
    }

    public logOut(): void {
        this.storageService.delete(this.tokenName);
        this.logout$.next();
        this.redirectToLogin(null);
    }

    public logIn(email: string, password: string): any {
        return this.callService
            .post(`${this.servicePrefix}/login`, {
                Email: email,
                Password: password
            })
            .pipe(
                map((data: { token: string }) => {
                    this.setToken(data.token);
                    return true;
                })
            );
    }

    public register(
        name: string,
        email: string,
        phone: string,
        organizationName: string,
        taxNumber: string,
        typeOfActivity: string,
        monthlyProcessedDocuments: string,
        accountActivationUrl: string,
    ): any {

        return this.callService
            .postForm(`${this.servicePrefix}/register`, {
                name,
                email,
                phone,
                organizationName,
                taxNumber,
                typeOfActivity,
                monthlyProcessedDocuments,
                accountActivationUrl,
            });
    }

    public activateAccount(email: string, token: string, password: string) {
        return this.callService.postForm(`${this.servicePrefix}/activate`, { email, token, password });
    }

    public forgotPassword(email: string, resetPasswordUrl: string): any {
        return this.callService.postForm(`${this.servicePrefix}/forgotPassword`, { email, resetPasswordUrl });
    }

    public verifyResetPasswordToken(email: string, token: string): any {
        return this.callService.postForm(`${this.servicePrefix}/verifyResetPasswordToken`, { email, token });
    }

    public resetPassword(email: string, token: string, password: string): any {
        return this.callService.postForm(`${this.servicePrefix}/resetPassword`, { email, token, password });
    }

    public redirectToLogin(targetUrl: string): void {
        if (targetUrl !== this.loginRoute) {
            this.loginTargetUrl = targetUrl;
        }

        this.router.navigate([this.loginRoute]);
    }

    public redirectAfterLogin(userExtract: UserExtract): void {

        if (this.loginTargetUrl !== null) {
            this.router.navigate([this.loginTargetUrl]);
            this.loginTargetUrl = null;
            return;
        }

        if (userExtract.role === UserRoleEnum.Guest) {
            this.router.navigate(['/archive']);
            return;
        }

        this.router.navigate(['/documents']);
    }

    public GetDecodedToken() {
        const token = this.getToken();
        const decoded = this.helper.decodeToken(token);
        return decoded;
    }

    public HasAnyRole(roles: UserRoleEnum[]): boolean {
        const token = this.GetDecodedToken();
        if (token === null) {
            return false;
        }

        const userRole: UserRoleEnum = +token.RoleEnum;
        return roles.some(x => x === userRole);
    }

    private setToken(token: string): void {
        this.storageService.set(this.tokenName, token);
    }
}
