import { Point } from './point';
import { OcrBox } from 'src/app/contract/document/ocr-box';

export class Area {

    public x: number;
    public y: number;
    public width: number;
    public height: number;
    public page: number = null;

    public static createFromPoints(p1: Point, p2: Point, page: number): Area {

        const x = Math.min(p1.x, p2.x);
        const y = Math.min(p1.y, p2.y);
        const width = Math.max(p1.x, p2.x) - x;
        const height = Math.max(p1.y, p2.y) - y;

        return new Area(x, y, width, height, page);
    }

    constructor(x?: number, y?: number, width?: number, height?: number, page?: number) {

        this.x = x ? x : 0;
        this.y = y ? y : 0;
        this.width = width ? width : 0;
        this.height = height ? height : 0;
        this.page = typeof page !== 'undefined' ? page : null;
    }

    public set(x: number, y: number, width: number, height: number, page?: number) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.page = page;
    }

    public containsOcrBox(ocrbox: OcrBox): boolean {

        return (
            this.x <= ocrbox.x
            && ocrbox.x + ocrbox.width <= this.x + this.width
            && this.y <= ocrbox.y
            && ocrbox.y + ocrbox.height <= this.y + this.height);
    }
}
