import { Injectable } from '@angular/core';
import { CallService } from '../call-service/call.service';
import { Observable } from 'rxjs';
import buildQuery from 'odata-query';
import { HttpResponse } from '@angular/common/http';
import { OcrBox } from 'src/app/contract/document/ocr-box';
import { FileUploadResult } from 'src/app/contract/upload/file-upload-result';
import { DocumentCounter } from 'src/app/contract/document/document-counter';
import { ScanPageMarker } from 'src/app/split/split-dialog/scan-page-marker';
import { DocumentExtract } from 'src/app/contract/document/document-extract';
import { DocumentModel } from 'src/app/contract/document/document.model';
import { DocumentStatusEnum } from 'src/app/contract/document/document-status.enum';
import { DocumentTypeEnum } from 'src/app/contract/document/document-type.enum';
import { SimiliarDocument } from 'src/app/contract/document/similar-document';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {
    private servicePrefix = 'documents';

    constructor(
        private callService: CallService,
    ) { }

    public GetDocuments(
        filter: any,
        orderBy: any,
        page: number,
        pageSize: number,
    ): Observable<DocumentExtract[]> {

        let query = buildQuery({ filter, orderBy });

        if (page !== null && pageSize !== null) {

            const top = pageSize;
            const skip = pageSize * (page - 1);
            query = buildQuery({ filter, orderBy, top, skip });
        }

        return this.callService.get<DocumentExtract[]>(`${this.servicePrefix}${query}`);
    }

    public getDocumentFromQuery(queryData: any): Observable<DocumentExtract[]> {

        const query = buildQuery(queryData);
        return this.callService.get<DocumentExtract[]>(`${this.servicePrefix}${query}`);
    }

    public DeleteDocuments(documentIds: number[]) {
        return this.callService.delete(`${this.callService.apiUrl}/${this.servicePrefix}`, { documentIds });
    }

    public DownloadDocumentOriginal(documentId: number) {

        return this.callService
            .download(`${this.servicePrefix}/${documentId}/original`)
            .subscribe((res: HttpResponse<Blob>) => {
                const a = document.createElement('a');
                a.href = URL.createObjectURL(res.body);
                a.download = `${documentId}.pdf`;
                document.body.appendChild(a);
                a.click();
            });
    }

    public PrintDocumentOriginal(documentid: number) {

        return this.callService
            .download(`${this.servicePrefix}/${documentid}/original`)
            .subscribe((res: HttpResponse<Blob>) => {

                const blobUrl = URL.createObjectURL(res.body);
                const iframe = document.createElement('iframe');
                iframe.style.display = 'none';
                iframe.src = blobUrl;
                document.body.appendChild(iframe);
                iframe.contentWindow.print();
            });
    }

    public GetDocumentPageImageUrl(documentId: number, page: number, size: 'small' | 'big' = 'small') {
        return `${this.callService.apiUrl}/${this.servicePrefix}/${documentId}/image/${page}?size=${size}`;
    }

    public GetOriginalPdf(documentId: number): Observable<HttpResponse<ArrayBuffer>> {

        return this.callService
            .downloadArrayBuffer(`${this.callService.apiUrl}/${this.servicePrefix}/${documentId}/original`);
    }

    public SetScanMarkers(id: number, receiptDate: Date, markers: ScanPageMarker[]) {

        return this.callService.postForm(`${this.servicePrefix}/${id}/markers`, {
            dateOfReceipt: receiptDate.toJSON(),
            markers: JSON.stringify(markers),
        });
    }

    public GetDocumentDetails(documentId: number): Observable<DocumentModel> {
        return this.callService.get<DocumentModel>(`${this.servicePrefix}/${documentId}`);
    }

    public TurnOnEdit(id: number) {
        return this.callService.postForm(`${this.servicePrefix}/${id}/turnOnEdit`, {});
    }

    public TurnOffEdit(id: number) {
        return this.callService.postForm(`${this.servicePrefix}/${id}/turnOffEdit`, {});
    }

    public UpdateDocumentDetails(documentModel: DocumentModel): Observable<DocumentModel> {
        return this.callService.patch<DocumentModel>(`${this.servicePrefix}/${documentModel.documentId}`, documentModel);
    }

    public GetOcrBoxes(documentId: number): Observable<OcrBox[]> {
        return this.callService.get<OcrBox[]>(`${this.servicePrefix}/${documentId}/ocr`);
    }

    public UploadDocument(file: File, params?: any): Observable<FileUploadResult> {
        return this.callService.upload(`${this.servicePrefix}/upload`, file, params) as Observable<FileUploadResult>;
    }

    public getCounter(): Observable<DocumentCounter> {
        return this.callService.get<DocumentCounter>(`${this.servicePrefix}/counter`);
    }

    public getAnalyzingDocuments(): Observable<DocumentExtract[]> {

        const query = buildQuery({
            filter: [
                { 'CurrentStatus/Value': DocumentStatusEnum[DocumentStatusEnum.Analyzing] },
            ],
        });

        return this.callService.get<DocumentExtract[]>(`${this.servicePrefix}${query}`);
    }

    public getDuplicates(
        documentId: number,
        documentNumber: string,
        contractorTaxNumber: string,
        documentType: DocumentTypeEnum,
    ): Observable<SimiliarDocument[]> {

        return this.callService.get<SimiliarDocument[]>(`${this.servicePrefix}/${documentId}/duplicates`, {
            documentNumber,
            contractorTaxNumber,
            documentType,
        });
    }

    public getCountPerStatus(): Observable<{ [key in DocumentStatusEnum]: number }> {
        return this.callService.get(`${this.servicePrefix}/statusCounter`);
    }
}
