
export enum DocumentStatusEnum {
    Pending = 0,
    Analyzing = 1,
    ToConfirm = 2,
    ToPay = 3,
    Paid = 4,
    Deleted = 5,
    Error = 6,
    Archived = 7,
    ToBeMarked = 8,
    GeneratingPreview = 9,
    Splitted = 10,
    AttachmentsToAssign = 11,
}
