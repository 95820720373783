import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage-service/storage.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
    templateUrl: './no-local-storage.component.html',
    styleUrls: ['./no-local-storage.component.scss']
})
export class NoLocalStorageComponent implements OnInit {

    constructor(
        private storageService: StorageService,
        private router: Router,
        private titleService: Title,
    ) { }

    public ngOnInit(): void {

        this.titleService.setTitle(`Wystąpił błąd | ${environment.applicationTabName}`);

        if (this.storageService.isLocalStorageAvailable()) {
            this.router.navigate(['/login']);
        }
    }
}
