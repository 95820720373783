import { Injectable } from '@angular/core';
import { IParser } from '../iparser';

@Injectable({
    providedIn: 'root'
})
export class NoteTypeParserService implements IParser {

    constructor() { }

    public parse(value: string): string {

        if (/odsetkowa/i.test(value)) {
            return 'Odsetkowa';
        }

        if (/uznaniowa/i.test(value)) {
            return 'Uznaniowa';
        }

        return 'Obciążeniowa';
    }
}
