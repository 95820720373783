import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage-service/storage.service';

@Injectable({ providedIn: 'root' })
export class LocalStorageGuard implements CanActivate {

    constructor(
        private storageService: StorageService,
        private router: Router,
    ) { }

    canActivate() {
        if (this.storageService.isLocalStorageAvailable()) {
            return true;
        }

        this.router.navigate(['/no-local-storage']);
    }
}
