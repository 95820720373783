<form [formGroup]="formGroup">
    <div class="contractors" >
        <div class="contractor-details">
            <div class="inputs">
                <div class="taxNumber">
                    <div class="label">NIP Sprzedawcy</div>
                    <mat-form-field floatLabel="never" class="custom">
                        <input
                            matInput
                            formControlName="taxNumber"
                            autocomplete="off"
                            (focus)="detailsService.onFocusChanged($event, [taxNumberFeatureName], taxNumber, taxNumberFormatterService, taxNumberFormatterService)"
                            (focusout)="
                                taxNumberFormatterService.formatInput($event);
                                detailsService.onFocusChanged()
                            "
                        />
                        <mat-error *ngIf="taxNumber.hasError('required')">
                            Wprowadź NIP
                            <ng-template #errors>
                                Nie wprowadzono NIP sprzedawcy
                            </ng-template>
                        </mat-error>
                        <mat-error
                            *ngIf="taxNumber.hasError('invalidTaxNumber')"
                        >
                            Niepoprawny NIP
                            <ng-template #errors>
                                Wprowadzono niepoprawny NIP sprzedawcy
                            </ng-template>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="account">
                    <div class="label">
                        Numer rachunku bankowego sprzedawcy
                    </div>
                    <mat-form-field floatLabel="never" class="custom">
                        <input
                            matInput
                            formControlName="account"
                            autocomplete="off"
                            (focus)="detailsService.onFocusChanged($event, [accountFeatureName], account, accountFormatterService, accountFormatterService)"
                            (focusout)="
                                accountFormatterService.formatInput($event);
                                detailsService.onFocusChanged()
                            "
                        />
                        <mat-error *ngIf="account.hasError('required')">
                            Wprowadź numer konta
                            <ng-template #errors>
                                Nie wprowadzono numeru konta
                            </ng-template>
                        </mat-error>
                        <mat-error *ngIf="account.hasError('invalidAccount')">
                            Niepoprawny numer konta
                            <ng-template #errors>
                                Wprowadzono niepoprawny numer konta
                            </ng-template>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div
                [ngClass]="[
                    'verification',
                    ContractorVatListStatusEnum[vatListStatus]
                ]"
            >
                <div class="status-wrapper success">
                    <div class="icon-wrapper">
                        <span class="icon success"></span>
                    </div>
                    <div class="text">
                        NIP i numer konta bankowego należą do czynnego płatnika VAT
                    </div>
                </div>

                <div class="status-wrapper loading">
                    <div class="icon-wrapper">
                        <span class="icon loading"></span>
                    </div>
                    <div class="text">
                        Trwa sprawdzanie NIP i numeru konta bankowego
                    </div>
                </div>

                <div class="status-wrapper error">
                    <div class="icon-wrapper">
                        <span class="icon status error"></span>
                    </div>
                    <div class="text">
                        NIP i numer konta bankowego nie należą do czynnego płatnika
                        VAT
                    </div>
                </div>

                <div class="status-wrapper invalid-input">
                    <div class="icon-wrapper">
                        <span class="icon status warning"></span>
                    </div>
                    <div class="text">
                        Wprowadź prawidłowy NIP i numer konta bankowego
                    </div>
                </div>

                <div class="status-wrapper unknown">
                    <div class="icon-wrapper">
                        <span class="icon status locked"></span>
                    </div>
                    <div class="text">
                        Zweryfikowanie płatnika VAT nie powiodło się
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="contractors-data">
        <div
            class="data"
            [class.has-error]="
                contractorStatus !== ContractorStatusEnum.Ok &&
                contractorStatus !== ContractorStatusEnum.Loading &&
                !isManualEditModeEnabled
            "
            [class.should-load-contractor-data]="shouldLoadContractorData()"
        >
            <div
                class="loading-container"
                *ngIf="contractorStatus === ContractorStatusEnum.Loading"
            >
                <div class="loading-wrapper">
                    <span class="icon loading"></span>
                </div>
            </div>

            <div
                class="fetch-contractor"
                matTooltip="Wprowadzony NIP jest niepoprawny"
                [matTooltipDisabled]="taxNumber.valid"
                *ngIf="
                    (
                        contractorStatus === ContractorStatusEnum.Throttle
                        || contractorStatus === ContractorStatusEnum.BadGateway
                        || !isSyncedWithTaxNumber(taxNumber.value)
                        || contractorStatus === ContractorStatusEnum.NotLoaded
                    )
                    && contractorStatus !== ContractorStatusEnum.Loading
                    && !isManualEditModeEnabled
                "
            >
                <button
                    mat-stroked-button
                    color="primary"
                    (click)="loadContractorData()"
                    type="button"
                    [disabled]="taxNumber.invalid"
                >
                    Wczytaj dane na podstawie NIP
                </button>
            </div>

            <div
                class="error"
                *ngIf="contractorStatus === ContractorStatusEnum.NotFound"
            >
                Nie znaleziono danych dla podanego numeru NIP
            </div>
            <div
                class="error"
                *ngIf="contractorStatus === ContractorStatusEnum.Throttle"
            >
                Osiągnięto limit zapytań. Spróbuj ponownie
            </div>
            <div
                class="error"
                *ngIf="contractorStatus === ContractorStatusEnum.BadGateway"
            >
                Nawiązanie połączenia z serwisem GUS było niemożliwe. Spróbuj ponownie później
            </div>

            <div *ngIf="!isManualEditModeEnabled" style="display: none;">
                <mat-error *ngIf="taxNumber.value !== '' && name.hasError('required')">
                    Wprowadź nazwę sprzedawcy
                    <ng-template #errors>
                        Nie wprowadzono nazwy sprzedawcy
                    </ng-template>
                </mat-error>
                <mat-error *ngIf="taxNumber.value !== '' && street.hasError('required')">
                    Wprowadź ulicy sprzedawcy

                    <ng-template #errors>
                        Nie wprowadzono ulicy sprzedawcy
                    </ng-template>
                </mat-error>
                <mat-error *ngIf="taxNumber.value !== '' && address.hasError('required')">
                    Wprowadź adresu sprzedawcy

                    <ng-template #errors>
                        Nie wprowadzono adresu sprzedawcy
                    </ng-template>
                </mat-error>
            </div>

            <button mat-icon-button type="button" (click)="toggleEditMode()" class="manual-edit-button" *ngIf="isEditable">
                <span class="icon edit"></span>
            </button>
            
            <div
                class="contractor-data"
                *ngIf="
                    contractorStatus === ContractorStatusEnum.Ok &&
                    isSyncedWithTaxNumber(taxNumber.value) &&
                    !isManualEditModeEnabled
                "
            >

                <div class="name">{{ name.value }}</div>
                <div class="street">{{ street.value }}</div>
                <div class="city">{{ address.value }}</div>
                <div class="verification-date">
                    Zweryfikowano w GUS
                    {{ gusVerificationDatetime | date: "dd.LL.yyyy" }}
                </div>
            </div>

            <div
                class="contractor-data manual"
                *ngIf="isManualEditModeEnabled"
            >
                <div class="name">
                    <div class="label">Nazwa sprzedawcy</div>
                    <mat-form-field floatLabel="never" class="custom small">
                        <input
                            matInput
                            autocomplete="off"
                            formControlName="name"
                            placeholder="Nazwa sprzedawcy"
                        />

                        <mat-error *ngIf="taxNumber.value !== '' && name.hasError('required')">
                            Wprowadź nazwę sprzedawcy
                            <ng-template #errors>
                                Nie wprowadzono nazwy sprzedawcy
                            </ng-template>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="street">
                    <div class="label">Ulica</div>
                    <mat-form-field floatLabel="never" class="custom small">
                        <input
                            matInput
                            autocomplete="off"
                            formControlName="street"
                            placeholder="Ulica"
                        />

                        <mat-error *ngIf="taxNumber.value !== '' && street.hasError('required')">
                            Wprowadź ulicy sprzedawcy

                            <ng-template #errors>
                                Nie wprowadzono ulicy sprzedawcy
                            </ng-template>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="adress">
                    <div class="label">Adres</div>
                    <mat-form-field floatLabel="never" class="custom small">
                        <input
                            matInput
                            autocomplete="off"
                            formControlName="address"
                            placeholder="Adres"
                        />

                        <mat-error *ngIf="taxNumber.value !== '' && address.hasError('required')">
                            Wprowadź adresu sprzedawcy

                            <ng-template #errors>
                                Nie wprowadzono adresu sprzedawcy
                            </ng-template>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</form>
