import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StatusIconComponent } from './status-icon/status-icon.component';
import { FeatureNameComponent } from './feature-name/feature-name.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { DocumentTypeComponent } from './document-type/document-type.component';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { GroupByDayPipe } from './pipes/group-by-day.pipe';
import { SecurePipe } from './pipes/secure.pipe';
import { NoInternetDialogComponent } from './no-internet-dialog/no-internet-dialog.component';
import { MatButtonModule, MatDialogModule, MatTooltipModule } from '@angular/material';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InternetInterceptor } from './interceptors/internet.interceptor';
import { DaysAgoPipe } from './pipes/days-ago.pipe';
import { AutomationIconComponent } from './automation-icon/automation-icon.component';
import { AutomationDocumentsHistoryComponent } from '../automations/automation-documents-history/automation-documents-history.component';
import { TableReloadComponent } from './table-reload/table-reload.component';
import { DocumentStatusComponent } from './document-status/document-status.component';

const declarations = [
    StatusIconComponent,
    FeatureNameComponent,
    DocumentTypeComponent,
    DocumentStatusComponent,
    NoInternetDialogComponent,
    AutomationIconComponent,
    AutomationDocumentsHistoryComponent,
    TableReloadComponent,

    DragDropDirective,
    ClickStopPropagationDirective,

    EnumToArrayPipe,
    DaysAgoPipe,
    GroupByDayPipe,
    SecurePipe,
];

@NgModule({
    declarations: [
        ...declarations,
    ],
    imports: [
        CommonModule,

        MatDialogModule,
        MatButtonModule,
        MatTooltipModule,
    ],
    exports: [
        ...declarations,
    ],
    providers: [
        GroupByDayPipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InternetInterceptor,
            multi: true
        },
    ],
    entryComponents: [
        NoInternetDialogComponent,
    ],
})
export class SharedModule { }
