import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { ImageService } from 'src/app/services/image-service/image.service';
import { Page } from 'src/app/shared/models/page';
import { InteractiveImageService } from 'src/app/services/interactive-image-service/interactive-image.service';
import { DocumentService } from 'src/app/services/document-service/document.service';

@Component({
    selector: 'digica-document-page-thumbnail-list',
    templateUrl: './document-page-thumbnail-list.component.html',
    styleUrls: ['./document-page-thumbnail-list.component.scss']
})
export class DocumentPageThumbnailListComponent implements OnInit, OnDestroy, OnChanges {

    @Input() documentId: number;
    @Input() pagesCount: number;

    @Output() pageNavigation = new EventEmitter<number>();

    public pagesArray: Array<Page> = null;
    public currentPage: Observable<number>;

    constructor(
        private documentService: DocumentService,
        private imageService: ImageService,
        private interactiveImageService: InteractiveImageService,
    ) { }

    public ngOnInit(): void {
        this.updatePagesArray();
        this.currentPage = this.interactiveImageService.currentPage.asObservable();
    }

    public ngOnDestroy(): void {
        this.clearSubscriptions();
    }

    public ngOnChanges(_: SimpleChanges): void {
        this.updatePagesArray();
    }

    private updatePagesArray(): void {

        if (this.pagesArray === null) {
            this.pagesArray = [];
        }

        for (let i = 0; i < this.pagesCount; i += 1) {

            if (this.pagesArray.some(x => x.num === i + 1)) {
                continue;
            }

            this.pagesArray.push({
                num: i + 1,
                isLoaded: false,
                source: null,
                subscription: null,
            });
        }

        for (const page of this.pagesArray) {

            if (page.subscription !== null) {
                continue;
            }

            this.loadPagePreview(page);
        }
    }

    private clearSubscriptions(): void {

        if (this.pagesArray) {
            for (const page of this.pagesArray) {

                if (page.subscription) {
                    page.subscription.unsubscribe();
                }
            }
        }
    }

    public pageClicked(page: number): void {
        this.pageNavigation.emit(page);
    }

    public loadPagePreview(page: Page): void {

        const url = this.documentService.GetDocumentPageImageUrl(this.documentId, page.num, 'small');
        page.subscription = this.imageService.getImageSource(url, result => {
            page.source = result;
        });
    }
}
