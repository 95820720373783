<h2 mat-dialog-title>Czy chcesz wyłączyć automatyzajce dla tego kontrahenta?</h2>
<mat-dialog-content>
    <p>Kontrahent <strong>{{ data.automationEntryExtract.contractorName }}</strong> (NIP: {{ data.automationEntryExtract.contractorTaxNumber }}) jest zautomatyzowany.</p>
    <p>Historia dokumentów:</p>
    <digica-automation-documents-history
        [documentsHistory]="data.automationEntryExtract.automationEntryDocuments.reverse()"
    ></digica-automation-documents-history>

    <mat-divider></mat-divider>

    <p>Na dokumencie zostały wprowadzone następujące poprawki:</p>

    <div class="corrections">
        <div class="correction labels">
            <div class="property-name label">Nazwa cechy</div>
            <div class="value value-before label">Wykryta wartość</div>
            <div class="value value-after label">Potwierdzona wartość</div>
        </div>
        <div
            *ngFor="let correction of data.corrections"
            class="correction body"
        >
            <div class="property-name">
                <digica-feature-name [feature]="correction.featureName"></digica-feature-name>
            </div>
            <div class="value value-before">
                <span *ngIf="correction.oldValue === null" class="not-detected">
                    Nie wykryto wartości
                </span>
                <span *ngIf="correction.oldValue !== null">
                    <span *ngIf="correction.featureName === 'SplitPayment' || correction.featureName === 'CorrectionSplitPayment'">
                        {{ formatBoolean(correction.oldValue) }}
                    </span>
                    <span *ngIf="correction.featureName === 'DocumentType'">
                        <digica-document-type [documentType]="correction.oldValue"></digica-document-type>
                    </span>

                    <span *ngIf="
                        correction.featureName !== 'SplitPayment'
                        && correction.featureName !== 'CorrectionSplitPayment'
                        && correction.featureName !== 'DocumentType'
                    ">
                        {{ correction.oldValue }}
                    </span>
                </span>
            </div>

            <div class="value value-after">
                <span *ngIf="correction.featureName === 'SplitPayment' || correction.featureName === 'CorrectionSplitPayment'">
                    {{ formatBoolean(correction.newValue) }}
                </span>
                <span *ngIf="correction.featureName === 'DocumentType'">
                    <digica-document-type [documentType]="correction.newValue"></digica-document-type>
                </span>

                <span *ngIf="
                    correction.featureName !== 'SplitPayment'
                    && correction.featureName !== 'CorrectionSplitPayment'
                    && correction.featureName !== 'DocumentType'
                ">
                    {{ correction.newValue }}
                </span>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button
        mat-button
        class="with-loader"
        color="primary"
        type="button"
        (click)="turnOffAutomations()"
        [class.is-loading]="isTurningOff"
    >
        <div class="content">
            Wyłącz automatyzacje
        </div>
        <div class="loader">
            <div class="loader-wrapper">
                <span class="icon loading"></span>
            </div>
        </div>
    </button>
    <button mat-button color="primary" [mat-dialog-close]="true" class="size-normal">Pozostaw włączoną automatyzacje</button>
</mat-dialog-actions>
