<div class="container">
    <header class="logo-container">
        <img src="/assets/logos/digica_wText_horizontal.svg" alt="Digica">
    </header>

    <div class="error-container">
        <div class="error-box">

            <div class="icon-wrapper">
                <span class="icon error"></span>
            </div>

            <div class="text-container">
                <div class="error-occured">
                    Wystąpił błąd
                </div>

                <div class="content">
                    Twoja przeglądarka blokuje dostęp do plików cookie i danych witryn. Odblokuj ich obsługę, aby wyświetlić aplikację.
                </div>
            </div>
        </div>
    </div>
</div>
