import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { UserService } from 'src/app/services/user-service/user.service';
import { StorageService } from 'src/app/services/storage-service/storage.service';
import { DocumentStatusEnum } from 'src/app/contract/document/document-status.enum';

@Component({
    selector: 'digica-split-filters',
    templateUrl: './split-filters.component.html',
    styleUrls: ['./split-filters.component.scss']
})
export class SplitFiltersComponent implements OnInit {

    @Output() filtersSelected = new EventEmitter<any[]>();

    private userId: number = null;
    private showFilter = 'all';

    private readonly splitFiltersShowKey = 'split_filters_show';

    constructor(
        private userService: UserService,
        private storageService: StorageService,
    ) { }

    public ngOnInit() {
        this.showFilter = this.storageService.get(this.splitFiltersShowKey);

        this.userService.userExtract
            .pipe(
                filter(x => x !== null),
                take(1)
            )
            .subscribe(userExtract => {
                this.userId = userExtract.userId;
                this.emitFilters();
            });
    }

    private emitFilters(): void {
        const filters = this.buildFilters();
        this.filtersSelected.emit(filters);
    }

    private buildFilters(): any[] {

        const filters = new Array<any>();

        if (this.isShowFilterActive('e-invoices')) {
            filters.push({ Source: 'EInvoice' });
        } else if (this.isShowFilterActive('my') && this.userId !== null) {
            filters.push({ 'Uploader/UserId': this.userId });
        }

        filters.push({
            or: [
                { 'CurrentStatus/Value': DocumentStatusEnum[DocumentStatusEnum.ToBeMarked] },
                { 'CurrentStatus/Value': DocumentStatusEnum[DocumentStatusEnum.GeneratingPreview] },
            ]
        });

        return filters;
    }

    public setShowFilter(selectedFilter: string): void {
        this.storageService.set(this.splitFiltersShowKey, selectedFilter);
        this.showFilter = selectedFilter;
        this.emitFilters();
    }

    public isShowFilterActive(selectedFilter: string): boolean {
        return this.showFilter === selectedFilter;
    }
}
