<form [formGroup]="formGroup" (ngSubmit)="addUserToOrganization()">
    <h2 mat-dialog-title>Dodawanie nowego użytkownika</h2>

    <mat-dialog-content>
        <section>
            <div class="setting-wrapper">
                <div class="setting-input">
                    <div class="label">
                        Adres e-mail
                    </div>
                    <mat-form-field floatLabel="never" class="custom">
                        <input
                            type="text"
                            matInput
                            placeholder="jan.kowalski@mail.pl"
                            formControlName="email"
                            autocomplete="off"
                            name="new-user-email"
                        />
                        <mat-error
                            *ngIf="
                                formGroup.controls['email'].hasError('required')
                            "
                        >
                            Wprowadź email
                        </mat-error>
                        <mat-error *ngIf="formGroup.controls['email'].hasError('emailTaken')">
                            Ten użytkownik jest już zarejstrowany w systemie.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="setting-wrapper">
                <div class="setting-input">
                    <div class="label">
                        Nazwa użytkownika
                    </div>
                    <mat-form-field floatLabel="never" class="custom">
                        <input
                            type="text"
                            matInput
                            placeholder="Jan Kowalski"
                            formControlName="nickname"
                            autocomplete="off"
                            name="new-user-nickname
                            "
                        />
                        <mat-error
                            *ngIf="
                                formGroup.controls['nickname'].hasError(
                                    'required'
                                )
                            "
                        >
                            Wprowadź nazwę użytkownika
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="setting-wrapper">
                <div class="setting-input">
                    <div class="label">
                        Rola
                    </div>
                    <mat-form-field floatLabel="never" class="custom">
                        <mat-select formControlName="role">
                            <mat-option
                                *ngFor="let enum of UserRoleEnum | enumToArray"
                                [value]="enum"
                            >
                                <digica-user-role
                                    [role]="enum"
                                ></digica-user-role>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </section>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button
            mat-stroked-button
            type="button"
            class="size-normal"
            color="primary"
            [mat-dialog-close]="false"
        >
            Anuluj
        </button>

        <button
            mat-flat-button
            class="secondary-button with-loader"
            color="primary"
            [class.is-loading]="isAdding"
        >
            <div class="content">
                Dodaj
            </div>
            <div class="loader">
                <div class="loader-wrapper">
                    <span class="icon loading"></span>
                </div>
            </div>
        </button>
    </mat-dialog-actions>
</form>
