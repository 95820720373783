<ng-container [ngSwitch]="role">
    <ng-container *ngSwitchCase="UserRoleEnum.Guest">
        Gość
    </ng-container>
    <ng-container *ngSwitchCase="UserRoleEnum.User">
        Użytkownik
    </ng-container>
    <ng-container *ngSwitchCase="UserRoleEnum.Admin">
        Admin
    </ng-container>
</ng-container>
