import { Component, ElementRef, OnChanges, OnDestroy, OnInit, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Input } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { DetailsService } from '../services/details/details.service';
import { IDocumentDetails } from '../document-details.interface';
import { Subscription } from 'rxjs';
import { PaymentMethodParserService } from 'src/app/services/parsers/payment-method-parser-service/payment-method-parser.service';
import { DateParserService } from 'src/app/services/parsers/date-parser-service/date-parser.service';
import { DocumentModel } from 'src/app/contract/document/document.model';

@Component({
    selector: 'digica-document-details-correction-invoice-base',
    templateUrl: './document-details-correction-invoice-base.component.html',
    styleUrls: ['./document-details-correction-invoice-base.component.scss']
})
export class DocumentDetailsCorrectionInvoiceBaseComponent implements IDocumentDetails, OnInit, OnDestroy, OnChanges {

    @Input() public documentModel: DocumentModel;
    @Input() public isEditable: boolean;

    @ViewChildren('errors') errors: QueryList<TemplateRef<ElementRef>>;

    public availablePaymentMethods = [
        { value: 'Karta', display: 'Karta', },
        { value: 'Przelew', display: 'Przelew', },
        { value: 'Gotówka', display: 'Gotówka', },
        { value: 'Rekompensata', display: 'Rekompensata', },
        { value: 'Przedpłata', display: 'Przedpłata'}
    ];

    public formGroup = new FormGroup({
        correctionNumber: new FormControl('', [Validators.required]),
        correctionDate: new FormControl('', [Validators.required]),
        correctionCurrency: new FormControl('', []),
        correctedInvoiceNumber: new FormControl('', [Validators.required]),
        correctionPaymentMethod: new FormControl(''),
        correctionSplitPayment: new FormControl(false),
        correctionIssueDate: new FormControl('', []),
        correctionSaleDate: new FormControl('', [Validators.required]),
        correctionPaymentDeadline: new FormControl('', []),
        isDuplicate: new FormControl(false),
        duplicateDate: new FormControl(),
    });

    public get correctionNumber() { return this.formGroup.get('correctionNumber') as FormControl; }
    public get correctionDate() { return this.formGroup.get('correctionDate') as FormControl; }
    public get correctionCurrency() { return this.formGroup.get('correctionCurrency') as FormControl; }
    public get correctedInvoiceNumber() { return this.formGroup.get('correctedInvoiceNumber') as FormControl; }
    public get correctionPaymentMethod() { return this.formGroup.get('correctionPaymentMethod') as FormControl; }
    public get correctionSplitPayment() { return this.formGroup.get('correctionSplitPayment') as FormControl; }
    public get correctionIssueDate() { return this.formGroup.get('correctionIssueDate') as FormControl; }
    public get correctionSaleDate() { return this.formGroup.get('correctionSaleDate') as FormControl; }
    public get correctionPaymentDeadline() { return this.formGroup.get('correctionPaymentDeadline') as FormControl; }
    public get isDuplicate() { return this.formGroup.get('isDuplicate') as FormControl; }
    public get duplicateDate() { return this.formGroup.get('duplicateDate') as FormControl; }

    private paymentMethodValueChangesSubscription: Subscription;
    private isDuplicateSubscription: Subscription;

    constructor(
        public detailsService: DetailsService,
        public paymentMethodParserService: PaymentMethodParserService,
        public dateParserService: DateParserService,
    ) { }

    public ngOnInit(): void {

        this.detailsService.currencyValue = this.correctionCurrency.valueChanges;
        this.detailsService.paymentMethodValueChanges = this.correctionPaymentMethod.valueChanges;
        this.detailsService.documentNumberControl = this.correctionNumber;

        this.isDuplicateSubscription = this.isDuplicate.valueChanges.subscribe(value => {

            this.duplicateDate.clearValidators();

            if (value) {
                this.duplicateDate.setValidators([Validators.required]);
            }

            this.duplicateDate.updateValueAndValidity();
        });
    }

    public ngOnDestroy(): void {

        if (this.paymentMethodValueChangesSubscription) {
            this.paymentMethodValueChangesSubscription.unsubscribe();
        }

        if (this.isDuplicateSubscription) {
            this.isDuplicateSubscription.unsubscribe();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {

        if (changes.documentModel && changes.documentModel.currentValue) {

            this.detailsService.updateFormControl(this.correctionCurrency, this.documentModel.correctionCurrency);
            this.detailsService.updateFormControl(this.correctedInvoiceNumber, this.documentModel.correctedInvoiceNumber);
            this.detailsService.updateFormControl(this.correctionPaymentMethod, this.documentModel.correctionPaymentMethod);
            this.detailsService.updateFormControl(this.correctionSplitPayment, this.documentModel.correctionSplitPayment);
            this.detailsService.updateFormControl(this.correctionIssueDate, this.documentModel.correctionIssueDate);
            this.detailsService.updateFormControl(this.correctionSaleDate, this.documentModel.correctionSaleDate);
            this.detailsService.updateFormControl(this.correctionPaymentDeadline, this.documentModel.correctionPaymentDeadline);
            this.detailsService.updateFormControl(this.correctionNumber, this.documentModel.correctionNumber);
            this.detailsService.updateFormControl(this.correctionDate, this.documentModel.correctionDate);
            this.detailsService.updateFormControl(this.isDuplicate, this.documentModel.isDuplicate);
            this.detailsService.updateFormControl(this.duplicateDate, this.documentModel.duplicateDate);
            this.detailsService.markFormGroupTouched(this.formGroup);
        }

        this.detailsService.changeEnabledFormStateBasingOnIsEditable(this.isEditable, this.formGroup);
    }

    public assignDocumentModelValues(documentModel: DocumentModel): void {

        this.detailsService.updateDocumentModelFeature(documentModel, 'correctionCurrency', this.correctionCurrency.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'correctedInvoiceNumber', this.correctedInvoiceNumber.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'correctionPaymentMethod', this.correctionPaymentMethod.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'correctionSplitPayment', this.correctionSplitPayment.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'correctionIssueDate', this.correctionIssueDate.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'correctionSaleDate', this.correctionSaleDate.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'correctionPaymentDeadline', this.correctionPaymentDeadline.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'correctionNumber', this.correctionNumber.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'correctionDate', this.correctionDate.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'isDuplicate', this.isDuplicate.value);

        if (this.isDuplicate.value) {
            this.detailsService.updateDocumentModelFeature(documentModel, 'duplicateDate', this.duplicateDate.value);
        }
    }

    public canDeactivate = () => this.formGroup.pristine;
    public canConfirm = () => true;
    public getErrors = (): TemplateRef<ElementRef<any>>[] => this.errors.toArray();
}
