import { Component, ElementRef, OnChanges, OnDestroy, OnInit, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Input } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { DetailsService } from '../services/details/details.service';
import { IDocumentDetails } from '../document-details.interface';
import { Subscription } from 'rxjs';
import { NoteTypeParserService } from 'src/app/services/parsers/note-type-parser-service/note-type-parser.service';
import { PaymentMethodParserService } from 'src/app/services/parsers/payment-method-parser-service/payment-method-parser.service';
import { DateParserService } from 'src/app/services/parsers/date-parser-service/date-parser.service';
import { DocumentModel } from 'src/app/contract/document/document.model';

@Component({
    selector: 'digica-document-details-accounting-note-base',
    templateUrl: './document-details-accounting-note-base.component.html',
    styleUrls: ['./document-details-accounting-note-base.component.scss']
})
export class DocumentDetailsAccountingNoteBaseComponent implements IDocumentDetails, OnInit, OnDestroy, OnChanges {

    @Input() public documentModel: DocumentModel;
    @Input() public isEditable: boolean;

    @ViewChildren('errors') errors: QueryList<TemplateRef<ElementRef>>;

    public availablePaymentMethods = [
        { value: 'Karta', display: 'Karta', },
        { value: 'Przelew', display: 'Przelew', },
        { value: 'Gotówka', display: 'Gotówka', },
        { value: 'Rekompensata', display: 'Rekompensata', },
        { value: 'Przedpłata', display: 'Przedpłata'}
    ];

    public availableNoteTypes = [
        { value: 'Odsetkowa', display: 'Odsetkowa', },
        { value: 'Obciążeniowa', display: 'Obciążeniowa', },
        { value: 'Uznaniowa', display: 'Uznaniowa', }
    ];

    public formGroup = new FormGroup({
        noteNumber: new FormControl('', [Validators.required]),
        noteDate: new FormControl('', [Validators.required]),
        noteType: new FormControl(''),
        noteCurrency: new FormControl('', []),
        noteToPay: new FormControl('', []),
        notePaymentDeadline: new FormControl('', []),
        notePaymentMethod: new FormControl(''),
    });

    public get noteNumber() { return this.formGroup.get('noteNumber') as FormControl; }
    public get noteDate() { return this.formGroup.get('noteDate') as FormControl; }
    public get noteType() { return this.formGroup.get('noteType') as FormControl; }
    public get noteCurrency() { return this.formGroup.get('noteCurrency') as FormControl; }
    public get noteToPay() { return this.formGroup.get('noteToPay') as FormControl; }
    public get notePaymentDeadline() { return this.formGroup.get('notePaymentDeadline') as FormControl; }
    public get notePaymentMethod() { return this.formGroup.get('notePaymentMethod') as FormControl; }

    public paymentMethodValueChangesSubscription: Subscription;
    public noteTypeValueChangesSubscription: Subscription;

    constructor(
        public detailsService: DetailsService,
        public paymentMethodParserSErvice: PaymentMethodParserService,
        public noteTypeParserService: NoteTypeParserService,
        public dateParserService: DateParserService
    ) { }

    public ngOnInit(): void {
        this.detailsService.noteTypeValueChanges = this.noteType.valueChanges;
        this.detailsService.currencyValue = this.noteCurrency.valueChanges;
        this.detailsService.paymentMethodValueChanges = this.notePaymentMethod.valueChanges;
        this.detailsService.documentNumberControl = this.noteNumber;
    }

    public ngOnDestroy(): void {

        if (this.paymentMethodValueChangesSubscription) {
            this.paymentMethodValueChangesSubscription.unsubscribe();
        }

        if (this.noteTypeValueChangesSubscription) {
            this.noteTypeValueChangesSubscription.unsubscribe();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {

        if (changes.documentModel && changes.documentModel.currentValue) {
            this.detailsService.updateFormControl(this.noteNumber, this.documentModel.noteNumber);
            this.detailsService.updateFormControl(this.noteDate, this.documentModel.noteDate);
            this.detailsService.updateFormControl(this.noteType, this.documentModel.noteType);
            this.detailsService.updateFormControl(this.noteCurrency, this.documentModel.noteCurrency);
            this.detailsService.updateFormControl(this.noteToPay, this.documentModel.noteAmountToPay);
            this.detailsService.updateFormControl(this.notePaymentDeadline, this.documentModel.notePaymentDeadline);
            this.detailsService.updateFormControl(this.notePaymentMethod, this.documentModel.notePaymentMethod);
            this.detailsService.markFormGroupTouched(this.formGroup);
        }

        this.detailsService.changeEnabledFormStateBasingOnIsEditable(this.isEditable, this.formGroup);
    }

    public assignDocumentModelValues(documentModel: DocumentModel): void {

        this.detailsService.updateDocumentModelFeature(documentModel, 'noteNumber', this.noteNumber.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'noteDate', this.noteDate.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'noteType', this.noteType.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'noteCurrency', this.noteCurrency.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'noteAmountToPay', this.noteToPay.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'notePaymentDeadline', this.notePaymentDeadline.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'notePaymentMethod', this.notePaymentMethod.value);
    }

    public canDeactivate = () => this.formGroup.pristine;
    public canConfirm = () => true;
    public getErrors = (): TemplateRef<ElementRef<any>>[] => this.errors.toArray();
}
