
import { User } from '../user/user.model';
import { Attachment } from './attachment.model';
import { DocumentStatus } from './document-status.model';
import { DocumentVATFeature } from './document-vat-feature.model';
import { DocumentFeature } from './document-feature.model';
import { DocumentLanguageEnum } from './document-language.enum';
import { DocumentTypeEnum } from './document-type.enum';
import { ApprovementTypeEnum } from './approvement-type.enum';
import { AutomationEntryDocumentResultEnum } from 'src/app/automations/models/automation-entry-document-result.enum';
import { DocumentConnection } from './document-connection.model';
import { DocumentInvoiceListFeature } from './document-invoice-list-feature.model';

export class DocumentModel {

    documentId: number;
    documentNumber: string;
    contractorTaxNumber: string;
    documentType: DocumentTypeEnum;
    user: User;
    editor: User;
    originalFileName: string;
    previewDownloadFileName: string;
    guid: string;
    width: number;
    height: number;

    currentStatus: DocumentStatus;
    statuses: DocumentStatus[];

    addDateTime: Date;
    payDateTime?: Date;
    receiptDate?: Date;
    gusVerificationDatetime: Date;
    isShared: boolean;
    language: DocumentLanguageEnum;
    attachments: Attachment[];
    numberOfPages: number;

    splitDate: Date;
    splitterNickname: string;
    uploadDate: Date;
    uploaderNickname: string;

    correctionDocumentId: number;
    correctionDocumentNumber: string;
    correctionDatetime: Date;

    approvementType: ApprovementTypeEnum;
    automationResult: AutomationEntryDocumentResultEnum;
    confirmerNickname: string;
    confirmDate: Date;

    documentConnections: Array<DocumentConnection>;

    constructor(
        public isDuplicate: DocumentFeature<boolean> = new DocumentFeature<boolean>(),
        public duplicateDate: DocumentFeature<string> = new DocumentFeature<string>(),

        public invoiceNumber: DocumentFeature<string> = new DocumentFeature<string>(),
        public amountToPay: DocumentFeature<number> = new DocumentFeature<number>(),
        public amountNet: DocumentFeature<number> = new DocumentFeature<number>(),
        public amountVat: DocumentFeature<number> = new DocumentFeature<number>(),
        public amountGross: DocumentFeature<number> = new DocumentFeature<number>(),
        public sellerAccount: DocumentFeature<string> = new DocumentFeature<string>(),
        public sellerName: DocumentFeature<string> = new DocumentFeature<string>(),
        public sellerStreet: DocumentFeature<string> = new DocumentFeature<string>(),
        public sellerAddress: DocumentFeature<string> = new DocumentFeature<string>(),
        public sellerTaxNumber: DocumentFeature<string> = new DocumentFeature<string>(),
        public issuerAccount: DocumentFeature<string> = new DocumentFeature<string>(),
        public issuerName: DocumentFeature<string> = new DocumentFeature<string>(),
        public issuerStreet: DocumentFeature<string> = new DocumentFeature<string>(),
        public issuerAddress: DocumentFeature<string> = new DocumentFeature<string>(),
        public issuerTaxNumber: DocumentFeature<string> = new DocumentFeature<string>(),
        public paymentDeadline: DocumentFeature<string> = new DocumentFeature<string>(),
        public saleDate: DocumentFeature<string> = new DocumentFeature<string>(),
        public issueDate: DocumentFeature<string> = new DocumentFeature<string>(),
        public paymentMethod: DocumentFeature<string> = new DocumentFeature<string>(),
        public splitPayment: DocumentFeature<boolean> = new DocumentFeature<boolean>(),
        public currency: DocumentFeature<string> = new DocumentFeature<string>(),
        public vatRows: Array<DocumentVATFeature> = new Array<DocumentVATFeature>(),

        // public provisionalInvoiceNumber: DocumentFeature<string> = new DocumentFeature<string>(),
        // public provisionalAmountToPay: DocumentFeature<number> = new DocumentFeature<number>(),
        // public provisionalAmountNet: DocumentFeature<number> = new DocumentFeature<number>(),
        // public provisionalAmountVat: DocumentFeature<number> = new DocumentFeature<number>(),
        // public provisionalAmountGross: DocumentFeature<number> = new DocumentFeature<number>(),
        // public provisionalSellerAccount: DocumentFeature<string> = new DocumentFeature<string>(),
        // public provisionalSellerName: DocumentFeature<string> = new DocumentFeature<string>(),
        // public provisionalSellerStreet: DocumentFeature<string> = new DocumentFeature<string>(),
        // public provisionalSellerAddress: DocumentFeature<string> = new DocumentFeature<string>(),
        // public provisionalSellerTaxNumber: DocumentFeature<string> = new DocumentFeature<string>(),
        // public provisionalIssuerAccount: DocumentFeature<string> = new DocumentFeature<string>(),
        // public provisionalIssuerName: DocumentFeature<string> = new DocumentFeature<string>(),
        // public provisionalIssuerStreet: DocumentFeature<string> = new DocumentFeature<string>(),
        // public provisionalIssuerAddress: DocumentFeature<string> = new DocumentFeature<string>(),
        // public provisionalIssuerTaxNumber: DocumentFeature<string> = new DocumentFeature<string>(),
        // public provisionalPaymentDeadline: DocumentFeature<string> = new DocumentFeature<string>(),
        // public provisionalSaleDate: DocumentFeature<string> = new DocumentFeature<string>(),
        // public provisionalIssueDate: DocumentFeature<string> = new DocumentFeature<string>(),
        // public provisionalPaymentMethod: DocumentFeature<string> = new DocumentFeature<string>(),
        // public provisionalSplitPayment: DocumentFeature<boolean> = new DocumentFeature<boolean>(),
        // public provisionalCurrency: DocumentFeature<string> = new DocumentFeature<string>(),
        // public provisionalVatRows: Array<DocumentVATFeature> = new Array<DocumentVATFeature>(),

        public correctedInvoiceNumber: DocumentFeature<string> = new DocumentFeature<string>(),
        public correctionNumber: DocumentFeature<string> = new DocumentFeature<string>(),
        public correctionReason: DocumentFeature<string> = new DocumentFeature<string>(),
        public correctionDate: DocumentFeature<Date> = new DocumentFeature<Date>(),
        public correctionCurrency: DocumentFeature<string> = new DocumentFeature<string>(),
        public correctionAmountNet: DocumentFeature<number> = new DocumentFeature<number>(),
        public correctionAmountVat: DocumentFeature<number> = new DocumentFeature<number>(),
        public correctionAmountGross: DocumentFeature<number> = new DocumentFeature<number>(),
        public correctionAmountToPay: DocumentFeature<number> = new DocumentFeature<number>(),
        public correctionSellerAccount: DocumentFeature<string> = new DocumentFeature<string>(),
        public correctionSellerName: DocumentFeature<string> = new DocumentFeature<string>(),
        public correctionSellerTaxNumber: DocumentFeature<string> = new DocumentFeature<string>(),
        public correctionSellerStreet: DocumentFeature<string> = new DocumentFeature<string>(),
        public correctionSellerAddress: DocumentFeature<string> = new DocumentFeature<string>(),
        public correctionBuyerAccount: DocumentFeature<string> = new DocumentFeature<string>(),
        public correctionBuyerName: DocumentFeature<string> = new DocumentFeature<string>(),
        public correctionBuyerTaxNumber: DocumentFeature<string> = new DocumentFeature<string>(),
        public correctionBuyerStreet: DocumentFeature<string> = new DocumentFeature<string>(),
        public correctionBuyerAddress: DocumentFeature<string> = new DocumentFeature<string>(),
        public correctionPaymentMethod: DocumentFeature<string> = new DocumentFeature<string>(),
        public correctionPaymentDeadline: DocumentFeature<Date> = new DocumentFeature<Date>(),
        public correctionIssueDate: DocumentFeature<Date> = new DocumentFeature<Date>(),
        public correctionSaleDate: DocumentFeature<Date> = new DocumentFeature<Date>(),
        public correctionSplitPayment: DocumentFeature<boolean> = new DocumentFeature<boolean>(),
        public correctionVatRows: Array<DocumentVATFeature> = new Array<DocumentVATFeature>(),

        public prepaymentInvoiceNumber: DocumentFeature<string> = new DocumentFeature<string>(),
        public prepaymentCurrency: DocumentFeature<string> = new DocumentFeature<string>(),
        public prepaymentAmountNet: DocumentFeature<number> = new DocumentFeature<number>(),
        public prepaymentAmountGross: DocumentFeature<number> = new DocumentFeature<number>(),
        public prepaymentAmountVat: DocumentFeature<number> = new DocumentFeature<number>(),
        public prepaymentAmountToPay: DocumentFeature<number> = new DocumentFeature<number>(),
        public prepaymentSellerName: DocumentFeature<string> = new DocumentFeature<string>(),
        public prepaymentSellerStreet: DocumentFeature<string> = new DocumentFeature<string>(),
        public prepaymentSellerAddress: DocumentFeature<string> = new DocumentFeature<string>(),
        public prepaymentSellerTaxNumber: DocumentFeature<string> = new DocumentFeature<string>(),
        public prepaymentSellerAccount: DocumentFeature<string> = new DocumentFeature<string>(),
        public prepaymentPaymentMethod: DocumentFeature<string> = new DocumentFeature<string>(),
        public prepaymentPaymentDeadline: DocumentFeature<Date> = new DocumentFeature<Date>(),
        public prepaymentIssueDate: DocumentFeature<Date> = new DocumentFeature<Date>(),
        public prepaymentSaleDate: DocumentFeature<Date> = new DocumentFeature<Date>(),
        public prepaymentSplitPayment: DocumentFeature<boolean> = new DocumentFeature<boolean>(),
        public prepaymentDate: DocumentFeature<Date> = new DocumentFeature<Date>(),
        public prepaymentVatRows: Array<DocumentVATFeature> = new Array<DocumentVATFeature>(),

        public billingInvoiceNumber: DocumentFeature<string> = new DocumentFeature<string>(),
        public billingCurrency: DocumentFeature<string> = new DocumentFeature<string>(),
        public billingAmountNet: DocumentFeature<number> = new DocumentFeature<number>(),
        public billingAmountGross: DocumentFeature<number> = new DocumentFeature<number>(),
        public billingAmountVat: DocumentFeature<number> = new DocumentFeature<number>(),
        public billingAmountToPay: DocumentFeature<number> = new DocumentFeature<number>(),
        public billingSellerName: DocumentFeature<string> = new DocumentFeature<string>(),
        public billingSellerStreet: DocumentFeature<string> = new DocumentFeature<string>(),
        public billingSellerAddress: DocumentFeature<string> = new DocumentFeature<string>(),
        public billingSellerTaxNumber: DocumentFeature<string> = new DocumentFeature<string>(),
        public billingSellerAccount: DocumentFeature<string> = new DocumentFeature<string>(),
        public billingPaymentMethod: DocumentFeature<string> = new DocumentFeature<string>(),
        public billingPaymentDeadline: DocumentFeature<Date> = new DocumentFeature<Date>(),
        public billingIssueDate: DocumentFeature<Date> = new DocumentFeature<Date>(),
        public billingSaleDate: DocumentFeature<Date> = new DocumentFeature<Date>(),
        public billingSplitPayment: DocumentFeature<boolean> = new DocumentFeature<boolean>(),
        public billingPrepayments: Array<DocumentFeature<string>> = new Array<DocumentFeature<string>>(),
        public billingVatRows: Array<DocumentVATFeature> = new Array<DocumentVATFeature>(),
        public billingDate: DocumentFeature<Date> = new DocumentFeature<Date>(),

        public noteNumber: DocumentFeature<string> = new DocumentFeature<string>(),
        public noteType: DocumentFeature<string> = new DocumentFeature<string>(),
        public noteCurrency: DocumentFeature<string> = new DocumentFeature<string>(),
        public noteDate: DocumentFeature<Date> = new DocumentFeature<Date>(),
        public noteSellerName: DocumentFeature<string> = new DocumentFeature<string>(),
        public noteSellerStreet: DocumentFeature<string> = new DocumentFeature<string>(),
        public noteSellerAddress: DocumentFeature<string> = new DocumentFeature<string>(),
        public noteIssuer: DocumentFeature<string> = new DocumentFeature<string>(),
        public notePaymentMethod: DocumentFeature<string> = new DocumentFeature<string>(),
        public notePaymentDeadline: DocumentFeature<Date> = new DocumentFeature<Date>(),
        public noteAmountToPay: DocumentFeature<number> = new DocumentFeature<number>(),
        public noteAccountNumber: DocumentFeature<string> = new DocumentFeature<string>(),
        public noteTaxNumber: DocumentFeature<string> = new DocumentFeature<string>(),
        public noteInvoiceListRows: Array<DocumentInvoiceListFeature> = new Array<DocumentInvoiceListFeature>()
    ) {}
}
