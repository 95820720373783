import { Component, Input } from '@angular/core';
import { DocumentTypeEnum } from 'src/app/contract/document/document-type.enum';

@Component({
    selector: 'digica-document-type',
    templateUrl: './document-type.component.html',
    styleUrls: []
})
export class DocumentTypeComponent {

    @Input() documentType: DocumentTypeEnum;
    @Input() multiple = false;
    public DocumentTypeEnum = DocumentTypeEnum;

    constructor() { }
}
