<div class="container flex">
    <nav>
        <a [routerLink]="['/group']" routerLinkActive="active">
            <span class="icon flow" routerLinkActive="active"></span>
            <span>Przepływy</span>
        </a>
        <a [routerLink]="['/split']" routerLinkActive="active">
            <span class="icon to_split" routerLinkActive="active"></span>
            <span>Pliki do podziału</span>
        </a>
        <a [routerLink]="['/documents']" routerLinkActive="active">
            <span class="icon document" routerLinkActive="active"></span>
            <span>Dokumenty</span>
        </a>
        <a [routerLink]="['/archive']" routerLinkActive="active">
            <span class="icon archive" routerLinkActive="active"></span>
            <span>Archiwum</span>
        </a>
    </nav>

    <div class="side-menu">
        <div class="add-documents-wrapper">
            <button
                mat-stroked-button
                class="add-documents-button"
                color="primary"
                type="button"
                (click)="openAddDocumentsDialog()"
            >
                Dodaj dokumenty
            </button>
        </div>
        <digica-analyzing-documents></digica-analyzing-documents>
    </div>
</div>