<form [formGroup]="formGroup">

    <mat-divider></mat-divider>

    <div class="inputs-container correction">
        <div class="input-wrapper correctionNumber">
            <div class="label">Numer faktury korygującej</div>
            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    formControlName="correctionNumber"
                    autocomplete="off"
                    (focus)="detailsService.onFocusChanged($event, ['correctionNumber'], correctionNumber)"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <mat-error *ngIf="correctionNumber.hasError('required')">
                    Wprowadź numer
                    <ng-template #errors>
                        Nie wprowadzono numeru faktury
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="input-wrapper correctionDate">
            <div class="label">Data korekty</div>
            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    autocomplete="off"
                    formControlName="correctionDate"
                    [satDatepicker]="correctionDatepicker"
                    (focus)="detailsService.onFocusChanged($event, ['correctionDate'], correctionDate, dateParserService);"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <sat-datepicker #correctionDatepicker></sat-datepicker>
                <sat-datepicker-toggle
                    matSuffix
                    [for]="correctionDatepicker"
                ></sat-datepicker-toggle>
                <mat-error *ngIf="correctionDate.hasError('required')">
                    Wprowadź datę
                    <ng-template #errors>
                        Nie wprowadzono daty korekty
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="inputs-container corrected">
        <div class="input-wrapper correctedInvoiceNumber">
            <div class="label">
                Numer faktury korygowanej
            </div>
            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    formControlName="correctedInvoiceNumber"
                    autocomplete="off"
                    (focus)="detailsService.onFocusChanged($event, ['correctedInvoiceNumber'], correctedInvoiceNumber)"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <mat-error *ngIf="correctedInvoiceNumber.hasError('required')">
                    Wprowadź numer
                    <ng-template #errors>
                        Nie wprowadzono numeru faktury korygowanej
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="input-wrapper correctionCurrency">
            <div class="label">Waluta</div>
            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    autocomplete="off"
                    formControlName="correctionCurrency"
                    (focus)="detailsService.onFocusChanged($event, ['correctionCurrency'], correctionCurrency)"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <mat-error *ngIf="correctionCurrency.hasError('required')">
                    Wprowadź walutę
                    <ng-template #errors>
                        Nie wprowadzono waluty
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="inputs-container dates">
        <div class="input-wrapper correctionSaleDate">
            <div class="label">Data sprzedaży</div>
            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    autocomplete="off"
                    formControlName="correctionSaleDate"
                    [satDatepicker]="correctionSaleDatepicker"
                    (focus)="detailsService.onFocusChanged($event, ['correctionSaleDate'], correctionSaleDate, dateParserService);"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <sat-datepicker #correctionSaleDatepicker></sat-datepicker>
                <sat-datepicker-toggle
                    matSuffix
                    [for]="correctionSaleDatepicker"
                ></sat-datepicker-toggle>
                <mat-error *ngIf="correctionSaleDate.hasError('required')">
                    Wprowadź datę
                    <ng-template #errors>
                        Nie wprowadzono daty sprzedaży
                    </ng-template>
                </mat-error>
                <mat-error *ngIf="!correctionSaleDate.hasError('required') && correctionSaleDate.hasError('matDatepickerParse')">
                    Wprowadź datę
                    <ng-template #errors>
                        Wprowadzono niepoprawną datę sprzedaży
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="input-wrapper correctionPaymentDeadline">
            <div class="label">Termin płatności</div>
            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    autocomplete="off"
                    formControlName="correctionPaymentDeadline"
                    [satDatepicker]="correctionPaymentDeadlinepicker"
                    (focus)="detailsService.onFocusChanged($event, ['correctionPaymentDeadline'], correctionPaymentDeadline, dateParserService);"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <sat-datepicker #correctionPaymentDeadlinepicker></sat-datepicker>
                <sat-datepicker-toggle
                    matSuffix
                    [for]="correctionPaymentDeadlinepicker"
                ></sat-datepicker-toggle>
                <mat-error *ngIf="correctionPaymentDeadline.hasError('required')">
                    Wprowadź datę
                    <ng-template #errors>
                        Nie wprowadzono daty sprzedaży
                    </ng-template>
                </mat-error>
                <mat-error *ngIf="!correctionPaymentDeadline.hasError('required') && correctionPaymentDeadline.hasError('matDatepickerParse')">
                    Wprowadź datę
                    <ng-template #errors>
                        Wprowadzono niepoprawną datę sprzedaży
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="input-wrapper correctionPaymentMethod">
            <div class="label">Sposób płatności</div>
            <mat-form-field floatLabel="never" class="custom">
                <mat-select
                    matInput
                    formControlName="correctionPaymentMethod"
                    (focus)="detailsService.onFocusChanged($event, ['correctionPaymentMethod'], correctionPaymentMethod, paymentMethodParserService)"
                    (focusout)="detailsService.onFocusChanged()"
                >
                    <mat-option *ngFor="let paymentMethod of availablePaymentMethods" [value]="paymentMethod.value">
                        {{ paymentMethod.display }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="input-wrapper isDuplicate" [style.display]="'none'">
            <mat-checkbox
                id="isDuplicate"
                color="primary"
                formControlName="isDuplicate"
                (focus)="detailsService.onFocusChanged($event, ['isDuplicate'], isDuplicate)"
                (focusout)="detailsService.onFocusChanged()"
            >
                Duplikat
            </mat-checkbox>
        </div>

        <div class="input-wrapper duplicateDate" [style.visibility]="isDuplicate.value ? 'visible' : 'hidden'">
            <div class="label">Data duplikatu</div>

            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    autocomplete="off"
                    formControlName="duplicateDate"
                    [satDatepicker]="duplicateDatepicker"
                    (focus)="detailsService.onFocusChanged($event, ['duplicateDate'], duplicateDate, dateParserService);"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <sat-datepicker #duplicateDatepicker></sat-datepicker>
                <sat-datepicker-toggle
                    matSuffix
                    [for]="duplicateDatepicker"
                ></sat-datepicker-toggle>
                <mat-error *ngIf="duplicateDate.hasError('required')">
                    Wprowadź datę
                    <ng-template #errors>
                        Nie wprowadzono daty duplikatu
                    </ng-template>
                </mat-error>
                <mat-error *ngIf="!duplicateDate.hasError('required') && duplicateDate.hasError('matDatepickerParse')">
                    Wprowadź datę
                    <ng-template #errors>
                        Wprowadzono niepoprawną datę duplikatu
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</form>
