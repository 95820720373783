import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
    templateUrl: './activate-account.component.html',
    styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit {

    public isActivating = false;
    public isActivated = false;
    public isError = false;
    public email = '';
    public token = '';

    public form: FormGroup = new FormGroup({
        password: new FormControl('', [Validators.required, Validators.minLength(6)])
    });

    public get password() { return this.form.get('password') as FormControl; }

    constructor(
        private title: Title,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
    ) { }

    public ngOnInit(): void {

        this.title.setTitle(`Aktywacja konta | ${environment.applicationTabName}`);

        this.activatedRoute.queryParams
            .pipe(take(1))
            .subscribe(params => {

                this.email = params.email;
                this.token = params.token;
            });
    }

    public activate(): void {
        this.isActivating = true;

        this.authService.activateAccount(this.email, this.token, this.password.value).subscribe(
            _ => {
                this.isActivating = false;
                this.isActivated = true;
            },
            _ => {
                this.isActivating = false;
                this.isActivated = false;
                this.isError = true;
            }
        );
    }
}
