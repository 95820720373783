<digica-split-attachment-filters
    (filtersSelected)="onFiltersSelected($event)"
></digica-split-attachment-filters>

<digica-table-reload
    content="Do systemu zostały wgrane nowe pliki do podziału"
    [show]="areNewDocumentGroupsAvailable"
    (refresh)="reloadResults()"
></digica-table-reload>

<div class="container" #tableWrapper>
    <div
        class="split-group"
        *ngFor="let dayCollection of groupedItems; trackBy: documentGroupTrackBy"
    >
        <div class="title">{{ dayCollection.day | date: "d LLLL yyy" }}</div>

        <div class="items-container">
            <div
                class="item"
                *ngFor="let item of dayCollection.value; trackBy: groupedItemsTrackBy"
                [class.can-activate]="canActivate(item)"
                [class.locked]="isItemLocked(item)"
                [class.generating-preview]="item.status === DocumentStatusEnum.GeneratingPreview"
                [class.splitted]="item.status === DocumentStatusEnum.Splitted"
                [class.deleted]="item.status === DocumentStatusEnum.Deleted"
            >
                <div class="header">
                    <div class="user-name">
                        <div class="ellipsis" [matTooltip]="item.uploaderNickname">
                            <span>{{ item.uploaderNickname }}</span>
                        </div>
                    </div>

                    <div class="time">
                        dodano {{ item.uploadDatetime | date: "HH:mm" }}
                    </div>
                </div>

                <a
                    class="preview"
                    [routerLink]="canActivate(item) ? ['/split', item.id] : []"
                    [matTooltip]="getTooltipText(item)"
                    [matTooltipDisabled]="getTooltipText(item) === null"
                >
                    <div class="menu">
                        <button
                            mat-icon-button
                            [matMenuTriggerFor]="menu"
                            (click)="openMenu($event)"
                        >
                            <span class="icon more white"></span>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="delete(item)">
                                <span class="icon delete"></span>
                                <span>Usuń</span>
                            </button>
                        </mat-menu>
                    </div>

                    <img
                        *ngIf="item.status !== DocumentStatusEnum.GeneratingPreview"
                        [src]="getImageUrl(item) | secure | async"
                        alt="#{{ item.id }}"
                    />

                    <div class="actions">
                        <button mat-stroked-button class="split-button">
                            Podziel plik
                        </button>
                    </div>

                    <div class="lock-wrapper">
                        <span class="icon lock"></span>
                    </div>

                    <div class="generating-preview-wrapper">
                        <div class="loading-container">
                            <span class="icon loading"></span>
                        </div>

                        <div class="generating-info">
                            Trwa generowanie podglądu
                        </div>
                    </div>

                    <div class="splitted-wrapper">
                        <div class="splitted-info">
                            Dokument został już podzielony
                        </div>
                    </div>

                    <div class="deleted-wrapper">
                        <div class="deleted-info">
                            Dokument został usunięty
                        </div>
                    </div>

                    <div class="hover-background"></div>
                </a>
            </div>
        </div>
    </div>

    <div class="table">
        <div class="loading-status">
            <div *ngIf="isLoading">
                <div class="icon-wrapper loading">
                    <span class="icon loading"></span>
                </div>
                <span class="text-wrapper">
                    Trwa ładowanie plików do podziału
                </span>
            </div>

            <div *ngIf="isEveryDocumentGroupLoaded">
                <span class="text-wrapper">
                    Załadowano wszystkie <b>{{ countResults() }}</b> plików do podziału
                </span>
            </div>
        </div>
    </div>
</div>

<router-outlet></router-outlet>
