<form [formGroup]="formGroup">

    <mat-divider></mat-divider>

    <div class="inputs-container main">
        <div class="input-wrapper prepaymentInvoiceNumber">
            <div class="label">Numer faktury zaliczkowej</div>
            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    formControlName="prepaymentInvoiceNumber"
                    autocomplete="off"
                    (focus)="detailsService.onFocusChanged($event, ['prepaymentInvoiceNumber'], prepaymentInvoiceNumber)"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <mat-error *ngIf="prepaymentInvoiceNumber.hasError('required')">
                    Wprowadź numer
                    <ng-template #errors>
                        Nie wprowadzono numeru faktury zaliczkowiej
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="input-wrapper prepaymentPaymentMethod">
            <div class="label">Sposób płatności</div>
            <mat-form-field floatLabel="never" class="custom">
                <mat-select
                    matInput
                    formControlName="prepaymentPaymentMethod"
                    (focus)="detailsService.onFocusChanged($event, ['prepaymentPaymentMethod'], prepaymentPaymentMethod, paymentMethodParserService)"
                    (focusout)="detailsService.onFocusChanged()"
                >
                    <mat-option *ngFor="let paymentMethod of availablePaymentMethods" [value]="paymentMethod.value">
                        {{ paymentMethod.display }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="input-wrapper duplicateDate" [style.visibility]="isDuplicate.value ? 'visible' : 'hidden'">
            <div class="label">Data duplikatu</div>

            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    autocomplete="off"
                    formControlName="duplicateDate"
                    [satDatepicker]="duplicateDatepicker"
                    (focus)="detailsService.onFocusChanged($event, ['duplicateDate'], duplicateDate, dateParserService);"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <sat-datepicker #duplicateDatepicker></sat-datepicker>
                <sat-datepicker-toggle
                    matSuffix
                    [for]="duplicateDatepicker"
                ></sat-datepicker-toggle>
                <mat-error *ngIf="duplicateDate.hasError('required')">
                    Wprowadź datę
                    <ng-template #errors>
                        Nie wprowadzono daty duplikatu
                    </ng-template>
                </mat-error>
                <mat-error *ngIf="!duplicateDate.hasError('required') && duplicateDate.hasError('matDatepickerParse')">
                    Wprowadź datę
                    <ng-template #errors>
                        Wprowadzono niepoprawną datę duplikatu
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="inputs-container dates">
        <div class="input-wrapper prepaymentIssueDate">
            <div class="label">Data wystawienia</div>
            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    autocomplete="off"
                    formControlName="prepaymentIssueDate"
                    [satDatepicker]="prepaymentIssueDatepicker"
                    (focus)="detailsService.onFocusChanged($event, ['prepaymentIssueDate'], prepaymentIssueDate, dateParserService);"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <sat-datepicker #prepaymentIssueDatepicker></sat-datepicker>
                <sat-datepicker-toggle
                    matSuffix
                    [for]="prepaymentIssueDatepicker"
                ></sat-datepicker-toggle>
                <mat-error *ngIf="prepaymentIssueDate.hasError('required')">
                    Wprowadź datę
                    <ng-template #errors>
                        Nie wprowadzono daty wystawienia
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="input-wrapper prepaymentSaleDate">
            <div class="label">Data sprzedaży</div>
            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    autocomplete="off"
                    formControlName="prepaymentSaleDate"
                    [satDatepicker]="prepaymentSaleDatepicker"
                    (focus)="detailsService.onFocusChanged($event, ['prepaymentSaleDate'], prepaymentSaleDate, dateParserService);"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <sat-datepicker #prepaymentSaleDatepicker></sat-datepicker>
                <sat-datepicker-toggle
                    matSuffix
                    [for]="prepaymentSaleDatepicker"
                ></sat-datepicker-toggle>
                <mat-error *ngIf="prepaymentSaleDate.hasError('required')">
                    Wprowadź datę
                    <ng-template #errors>
                        Nie wprowadzono daty sprzedaży
                    </ng-template>
                </mat-error>
                <mat-error *ngIf="!prepaymentSaleDate.hasError('required') && prepaymentSaleDate.hasError('matDatepickerParse')">
                    Wprowadź datę
                    <ng-template #errors>
                        Wprowadzono niepoprawną datę sprzedaży
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="input-wrapper prepaymentPaymentDeadline">
            <div class="label">Termin płatności</div>
            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    autocomplete="off"
                    formControlName="prepaymentPaymentDeadline"
                    [satDatepicker]="prepaymentPaymentDeadlinePicker"
                    (focus)="detailsService.onFocusChanged($event, ['prepaymentPaymentDeadline'], prepaymentPaymentDeadline, dateParserService);"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <sat-datepicker #prepaymentPaymentDeadlinePicker></sat-datepicker>
                <sat-datepicker-toggle
                    matSuffix
                    [for]="prepaymentPaymentDeadlinePicker"
                ></sat-datepicker-toggle>
                <mat-error *ngIf="prepaymentPaymentDeadline.hasError('matDatepickerParse')">
                    Wprowadź datę
                    <ng-template #errors>
                        Nie wprowadzono terminu płatności
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="input-wrapper prepaymentCurrency">
            <div class="label">Waluta</div>
            <mat-form-field floatLabel="never" class="custom">
                <input
                    matInput
                    autocomplete="off"
                    formControlName="prepaymentCurrency"
                    (focus)="detailsService.onFocusChanged($event, ['prepaymentCurrency'], prepaymentCurrency)"
                    (focusout)="detailsService.onFocusChanged()"
                />
                <mat-error *ngIf="prepaymentCurrency.hasError('required')">
                    Wprowadź walutę
                    <ng-template #errors>
                        Nie wprowadzono waluty
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="checkboxes" [style.display]="'none'">
        <div class="input-wrapper prepaymentSplitPayment">
            <mat-checkbox id="prepaymentSplitPayment"
                          color="primary"
                          formControlName="prepaymentSplitPayment"
                          (focus)="detailsService.onFocusChanged($event, ['prepaymentSplitPayment'], prepaymentSplitPayment)"
                          (focusout)="detailsService.onFocusChanged()">
                Podzielona płatność
            </mat-checkbox>
        </div>

        <div class="input-wrapper isDuplicate">
            <mat-checkbox id="isDuplicate"
                          color="primary"
                          formControlName="isDuplicate"
                          (focus)="detailsService.onFocusChanged($event, ['isDuplicate'], isDuplicate)"
                          (focusout)="detailsService.onFocusChanged()">
                Duplikat
            </mat-checkbox>
        </div>
    </div>
</form>
