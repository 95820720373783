import { Injectable } from '@angular/core';

enum StorageType {
    LocalStorage,
    None
}


@Injectable({
    providedIn: 'root'
})
export class StorageService {

    private usedStorage: StorageType = StorageType.None;
    constructor() {

        if (this.isLocalStorageAvailable()) {
            this.usedStorage = StorageType.LocalStorage;
        }
    }

    public set(key: string, value: any) {

        const saveValue = JSON.stringify(value);

        switch (this.usedStorage) {
            case StorageType.LocalStorage:
                localStorage.setItem(key, saveValue);
                break;

            default:
                break;
        }
    }

    public get(key: string): any {

        let value: string = null;

        switch (this.usedStorage) {
            case StorageType.LocalStorage:
                value = localStorage.getItem(key);
                break;

            default:
                break;
        }

        return JSON.parse(value);
    }

    public delete(key: string): void {

        switch (this.usedStorage) {
            case StorageType.LocalStorage:
                localStorage.removeItem(key);
                break;

            default:
                break;
        }
    }

    public isLocalStorageAvailable(): boolean {

        try {
            if (typeof localStorage === 'undefined') {
                return false;
            }

            localStorage.setItem('feature_test', 'yes');
            if (localStorage.getItem('feature_test') === 'yes') {
                localStorage.removeItem('feature_test');
                return true;
            }

            return false;
        } catch (e) {
            return false;
        }
    }
}
