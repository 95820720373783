<div class="container">
    <header class="logo-container">
        <img src="/assets/logos/digica_wText_horizontal.svg" alt="Digica">
    </header>

    <div class="auth-container">
        <form [formGroup]="form" (ngSubmit)="submit()">
            <div class="auth-box">
                <h1>Nie pamiętam hasła</h1>

                <ng-container *ngIf="!isSubmitted">
                    <div class="input-wrapper">
                        <div class="label">
                            E-mail
                        </div>

                        <mat-form-field floatLabel="never" class="custom">
                            <input matInput formControlName="email" />
                            <mat-error *ngIf="email.hasError('required')">
                                E-mail jest wymagany
                            </mat-error>
                            <mat-error *ngIf="email.hasError('email')">
                                Wprowadzony e-mail jest niepoprawny
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <button
                        type="submit"
                        mat-flat-button
                        color="primary"
                        class="submit-button"
                    >
                        Przypomnij hasło
                    </button>
                </ng-container>
                <ng-container *ngIf="isSubmitted">
                    <div class="status">
                        <div class="icon-wrapper success">
                            <span class="icon success"></span>
                        </div>

                        <div class="text">
                            Na podany adres e-mail został wysłany link do zmiany hasła, możesz teraz zamknąć tę kartę.
                        </div>
                    </div>
                </ng-container>

                <div class="links">
                    <a [routerLink]="['/login']">Przejdź do logowania</a>
                </div>
            </div>
        </form>
    </div>
</div>
