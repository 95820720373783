import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'digica-layout-default',
  templateUrl: './layout-default.component.html',
  styleUrls: ['./layout-default.component.scss']
})
export class LayoutDefaultComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
