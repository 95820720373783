<div class="container">
    <div class="background">
        <div class="section-header no-margin">Ustawienia organizacji</div>
    </div>
</div>

<div class="container">
    <div class="description">
        <span class="text">
            Jako administrator, masz możliwość zmiany ustawień organizacji.
        </span>
    </div>

    <form [formGroup]="formGroup" (ngSubmit)="submit()">
        
        <div class="setting-wrapper">
            <div class="setting-input">
                <div class="label">Skrót organizacji</div>
                <mat-form-field
                    floatLabel="never"
                    class="custom"
                    appearance="standard"
                >
                    <input
                        type="text"
                        matInput
                        formControlName="emailSuffix"
                    />
                    <mat-error *ngIf="emailSuffix.hasError('minlength')">
                        Skrót musi składać się z minimum {{ emailSuffix.errors.minlength.requiredLength }} znaków
                    </mat-error>
                    <mat-error *ngIf="emailSuffix.hasError('pattern')">
                        Skrót może składać się tylko z małych liter
                    </mat-error>
                    <mat-error *ngIf="emailSuffix.hasError('emailSuffixTaken')">
                        Wprowadzony skrót jest zajęty
                    </mat-error>
                    <mat-error *ngIf="emailSuffix.hasError('errorOccurred')">
                        Wystąpił błąd spróbuj wprowadzić inny skrót
                    </mat-error>
                    <mat-hint>
                        Adres do przesyłania dokumentów: <strong>{{ userMailPrefix }}_{{ emailSuffix.value }}@{{ mailDomain }}</strong>
                    </mat-hint>
                </mat-form-field>
            </div>
        </div>

        <div class="form-actions">
            <button
                mat-raised-button
                color="primary"
                type="submit"
                class="submit-button with-loader"
                [class.is-loading]="isSaving"
            >
                <div class="content">
                    Zapisz
                </div>
                <div class="loader">
                    <div class="loader-wrapper">
                        <span class="icon loading"></span>
                    </div>
                </div>
            </button>

            <div class="status">
                <span *ngIf="isSaved">Zapisano zmiany pomyślnie</span>
            </div>
        </div>
    </form>
</div>

<div class="container">
    <div class="background">
        <div class="section-header no-margin">Użytkownicy organizacji</div>
    </div>
</div>

<div class="container">
    <div class="description">
        <span class="text">
            Jako administrator, masz możliwość zarządzania użytkownikami systemu.
        </span>
        <div class="call-to-action">
            <button mat-stroked-button color="primary" (click)="openAddOrganizationUserDialog()">Dodaj nowego użytkownika</button>
        </div>
    </div>
    
    <table mat-table class="user-list" [dataSource]="dataSource">
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let item">{{ item.email }}</td>
        </ng-container>
    
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Nazwa</th>
            <td mat-cell *matCellDef="let item" [title]="item.nickname">{{ item.nickname }}</td>
        </ng-container>
    
        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>Rola</th>
            <td mat-cell *matCellDef="let item">
                <span>
                    <digica-user-role [role]="item.role"></digica-user-role>
                </span>
    
                <button mat-icon-button [matMenuTriggerFor]="menu" color="primary">
                    <span class="icon more"></span>
                </button>
                <mat-menu #menu="matMenu">
                    <button
                        mat-menu-item
                        *ngFor="let enum of UserRoleEnum | enumToArray"
                        (click)="changeUserRole(item.userId, enum)"
                        [disabled]="item.role == enum"
                    >
                        <digica-user-role [role]="enum"></digica-user-role>
                    </button>
                </mat-menu>
            </td>
        </ng-container>
    
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button color="warn" (click)="deleteUser(item.userId)" *ngIf="item.userId !== userId">
                    <span class="icon remove-row"></span>
                </button>
            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="trustedContactColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: trustedContactColumns"></tr>
    </table>
</div>
