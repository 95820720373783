import {
    Component, SimpleChanges, OnChanges, Input, ElementRef, TemplateRef, ViewChildren, QueryList, OnInit
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { DocumentModel } from 'src/app/contract/document/document.model';
import { DocumentTypeEnum } from 'src/app/contract/document/document-type.enum';
import { IDocumentDetails } from '../document-details.interface';
import { DetailsService } from '../services/details/details.service';
import { ApprovementTypeEnum } from 'src/app/contract/document/approvement-type.enum';
import { DocumentStatusEnum } from 'src/app/contract/document/document-status.enum';
import { AutomationEntryDocumentResultEnum } from 'src/app/automations/models/automation-entry-document-result.enum';

@Component({
    selector: 'digica-document-base-information',
    templateUrl: './document-base-information.component.html',
    styleUrls: ['./document-base-information.component.scss']
})
export class DocumentBaseInformationComponent implements IDocumentDetails, OnChanges, OnInit {

    @Input() public documentModel: DocumentModel;
    @Input() public isEditable: boolean;

    @ViewChildren('errors') errors: QueryList<TemplateRef<ElementRef>>;

    public DocumentTypeEnum = DocumentTypeEnum;
    public DocumentStatusEnum = DocumentStatusEnum;
    public ApprovementTypeEnum = ApprovementTypeEnum;
    public AutomationEntryDocumentResultEnum = AutomationEntryDocumentResultEnum;

    public formGroup = new FormGroup({
        documentType: new FormControl(DocumentTypeEnum.PurchaseInvoice),
        receiptDate: new FormControl('', [Validators.required]),
    });
    public get documentType() { return this.formGroup.get('documentType') as FormControl; }
    public get receiptDate() { return this.formGroup.get('receiptDate') as FormControl; }

    constructor(
        private detailsService: DetailsService,
    ) { }

    public ngOnInit(): void {
        this.detailsService.documentTypeControl = this.documentType;
    }

    public ngOnChanges(changes: SimpleChanges): void {

        if (changes.documentModel && changes.documentModel.currentValue) {

            this.documentType.setValue(this.documentModel.documentType);
            this.receiptDate.setValue(this.documentModel.receiptDate);

            this.detailsService.markFormGroupTouched(this.formGroup);
        }

        this.detailsService.changeEnabledFormStateBasingOnIsEditable(this.isEditable, this.formGroup);
    }

    public assignDocumentModelValues(documentModel: DocumentModel): void {
        documentModel.documentType = this.documentType.value;
        documentModel.receiptDate = this.receiptDate.value;
    }

    public canDeactivate = () => this.formGroup.pristine;
    public canConfirm = () => true;
    public getErrors = (): TemplateRef<ElementRef<any>>[] => this.errors.toArray();

    public documentTypeChanged(_: MatSelectChange): void {
        this.documentModel.documentType = this.documentType.value;
    }
}
