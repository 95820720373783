import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './no-internet-dialog.component.html',
    styleUrls: ['./no-internet-dialog.component.scss']
})
export class NoInternetDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<NoInternetDialogComponent>,
    ) { }

    public refreshPage(): void {
        window.location.reload();
    }
}
