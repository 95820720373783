import { Component, HostListener, OnInit, ViewChild, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ScanPageMarkerTypeEnum } from './scan-page-marker-type.enum';
import { ScanPageMarker } from './scan-page-marker';
import { FormControl } from '@angular/forms';
import { SatDatepicker } from 'saturn-datepicker';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ImageService } from 'src/app/services/image-service/image.service';
import { SplitZoomComponent } from '../split-zoom/split-zoom.component';
import { Page } from 'src/app/shared/models/page';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { DocumentGroupService } from 'src/app/services/document-group-service/document-group.service';
import { DocumentDeleteDialogComponent } from 'src/app/documents/document-delete-dialog/document-delete-dialog.component';
import { DocumentDeleteDialogData } from 'src/app/documents/document-delete-dialog/document-delete-dialog.data';

export enum KEY_CODE {
    UP_ARROW = 'ArrowUp',
    DOWN_ARROW = 'ArrowDown',
    RIGHT_ARROW = 'ArrowRight',
    LEFT_ARROW = 'ArrowLeft',
    SPACE = ' ',
    BACKSPACE = 'Backspace',
    ENTER = 'Enter',
    ESCAPE = 'Escape',
}

export enum IE_KEY_CODE {
    UP_ARROW = 'Up',
    DOWN_ARROW = 'Down',
    RIGHT_ARROW = 'Right',
    LEFT_ARROW = 'Left',
    SPACE = 'Spacebar',
    ESCAPE = 'Esc',
}

@Component({
    templateUrl: './split-dialog.component.html',
    styleUrls: ['./split-dialog.component.scss'],
})
export class SplitDialogComponent implements OnInit, OnDestroy {

    public pagesCount = 1;
    public currentPage = 1;
    public saved = false;
    public isLoading = false;
    public pagesArray: Array<Page>;
    public redirectPath = ['/split'];

    public pagesContainerOffset: number;
    public pagesNavigationOffset: number;
    private shouldCallTurnOffEdit = false;
    private turnOnEditSubscription: Subscription;
    private refreshEditStatusInterval: NodeJS.Timer;

    public markers = new Array<ScanPageMarker>();
    public ScanPageMarkerTypeEnum = ScanPageMarkerTypeEnum;

    @ViewChild(SplitZoomComponent, { static: true }) zoomComponent: SplitZoomComponent;
    @ViewChild(SatDatepicker) picker: SatDatepicker<Date>;
    public receiptDate = new FormControl(moment().format('L'));
    public selectedReceiptDate = new Date();
    public isConfirming = false;
    public isSearchingNext = false;
    public isError = false;

    constructor(
        private router: Router,
        private imageService: ImageService,
        private dialog: MatDialog,
        private titleService: Title,
        private documentGroupService: DocumentGroupService,
        public dialogRef: MatDialogRef<SplitDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public id: number,
    ) { }

    public ngOnInit(): void {

        this.titleService.setTitle(`Dzielenie dokumentu | ${environment.applicationTabName}`);

        this.clearSubscriptions();
        this.resetSplitData();

        this.enterEditMode();
        this.loadDocumentGroup();
        this.generatePagesArray();
        this.setCurrentPage(1);
    }

    private generatePagesArray(): void {

        this.pagesArray = [...Array(this.pagesCount).keys()].map(i => ({
            num: i + 1,
            isLoaded: false,
            source: null,
            subscription: null,
        }));
    }

    private resetSplitData(): void {

        this.pagesCount = 1;
        this.currentPage = 1;
        this.zoomComponent.zoomOut();
        this.saved = false;
        this.isLoading = false;
        this.shouldCallTurnOffEdit = false;
        this.markers = [];
    }

    public ngOnDestroy(): void {
        this.clearSubscriptions();
    }

    private clearSubscriptions() {

        if (this.refreshEditStatusInterval) {
            clearInterval(this.refreshEditStatusInterval);
        }

        if (this.turnOnEditSubscription) {
            this.turnOnEditSubscription.unsubscribe();
        }

        if (this.shouldCallTurnOffEdit) {
            this.documentGroupService.turnOffEdit(this.id).subscribe();
        }

        if (this.pagesArray) {
            for (const page of this.pagesArray) {

                if (page.subscription) {
                    page.subscription.unsubscribe();
                }
            }
        }
    }

    public loadDocumentGroup(): void {

        this.isLoading = true;

        this.documentGroupService.getDocumentGroup(this.id).subscribe(
            documentGroup => {
                this.pagesCount = documentGroup.pageCount;
                this.generatePagesArray();
                this.setCurrentPage(1);

                if (documentGroup.receiptDate) {
                    this.receiptDate.setValue(moment(documentGroup.receiptDate).format('L'));
                    this.selectedReceiptDate = moment(documentGroup.receiptDate).toDate();
                }

                this.isLoading = false;
            }
        );
    }

    @HostListener('window:keyup', ['$event'])
    public keyboardEvent(event: KeyboardEvent) {

        if (this.isLoading) {
            return;
        }

        const target = event.target as HTMLElement;
        if (target.nodeName === 'INPUT' || target.nodeName === 'SELECT') {
            return;
        }

        switch (event.key) {
            case KEY_CODE.UP_ARROW:
            case IE_KEY_CODE.UP_ARROW:
                this.markCurrentPage(ScanPageMarkerTypeEnum.DOCUMENT);
                break;

            case KEY_CODE.DOWN_ARROW:
            case IE_KEY_CODE.DOWN_ARROW:
                this.markCurrentPage(ScanPageMarkerTypeEnum.ATTACHMENT);
                break;

            case KEY_CODE.BACKSPACE:
                this.markCurrentPage(ScanPageMarkerTypeEnum.SKIP);
                break;

            case KEY_CODE.RIGHT_ARROW:
            case IE_KEY_CODE.RIGHT_ARROW:
                this.setCurrentPage(this.currentPage + 1);
                break;

            case KEY_CODE.LEFT_ARROW:
            case IE_KEY_CODE.LEFT_ARROW:
                this.setCurrentPage(this.currentPage - 1);
                break;

            case KEY_CODE.SPACE:
            case IE_KEY_CODE.SPACE:
                const page = this.pagesArray.find(x => x.num === this.currentPage);
                this.zoomComponent.toggleZoom(page);
                break;

            case KEY_CODE.ENTER:
                if (!this.saved) {
                    this.confirm();
                } else {
                    this.navigateToNext();
                }

                break;

            case KEY_CODE.ESCAPE:
            case IE_KEY_CODE.ESCAPE:
                if (this.saved) {
                    this.navigateToList();
                }
                break;

            default:
                break;
        }

    }

    @HostListener('window:keydown', ['$event'])
    public keyDownEvent(event: KeyboardEvent) {

        switch (event.key) {

            case KEY_CODE.BACKSPACE:

                const target = event.target as HTMLElement;
                if (target.nodeName !== 'INPUT' && target.nodeName !== 'SELECT') {
                    event.preventDefault();
                }
                break;

            default:
                break;
        }
    }

    public confirm(): void {

        if (this.currentPage !== this.pagesCount + 1 || this.isConfirming) {
            return;
        }

        const markers = this.markers.sort((a, b) => a.page - b.page);
        const receiptDate = moment(this.receiptDate.value, 'L').toDate();

        this.isError = false;
        this.isConfirming = true;

        this.documentGroupService.setScanMarkers(this.id, receiptDate, markers).subscribe(
            _ => {
                this.isConfirming = false;
                this.saved = true;
                this.setCurrentPage(this.pagesCount + 1);
            },
            _ => {
                this.isConfirming = false;
                this.isError = true;
            }
        );
    }

    public markCurrentPage(markerType: ScanPageMarkerTypeEnum) {

        if (this.saved || this.currentPage > this.pagesCount) {
            return;
        }

        const pageMarker = this.markers.find(x => x.page === this.currentPage);
        if (!pageMarker) {

            const marker = new ScanPageMarker();
            marker.page = this.currentPage;
            marker.type = markerType;
            this.markers.push(marker);

        } else if (pageMarker.type === markerType) {

            this.markers = this.markers.filter(x => x.page !== this.currentPage);

        } else {

            pageMarker.type = markerType;
        }

        this.setCurrentPage(this.currentPage + 1);
    }

    public markAsSinglePage() {

        this.markers = [];
        for (let i = 1; i <= this.pagesCount; i++) {

            const marker = new ScanPageMarker();
            marker.page = i;
            marker.type = ScanPageMarkerTypeEnum.DOCUMENT;

            this.markers.push(marker);
        }

        this.setCurrentPage(this.pagesCount + 1);
    }

    public setCurrentPage(page: number) {

        if (page < 1 || page > this.pagesCount + 1 || this.saved) {
            return;
        }

        this.currentPage = page;
        this.zoomComponent.zoomOut();
        this.updateOffsets();

        const startPage = Math.max(page - 2, 1);
        const endPage = Math.min(page + 2, this.pagesCount);

        for (let i = startPage; i <= endPage; i += 1) {
            const pageObject = this.pagesArray[i - 1];
            this.loadPagePreview(pageObject);
        }
    }

    private updateOffsets(): void {

        const pageWidth = window.innerWidth / 4;
        this.pagesContainerOffset = (pageWidth * 3 / 2) - ((this.currentPage - 1) * pageWidth);

        const navigationWidth = 47 + 8;
        this.pagesNavigationOffset = (window.innerWidth / 2) - (navigationWidth / 2) - ((this.currentPage - 1) * navigationWidth);
    }

    public getPageMarkerType(page: number): ScanPageMarkerTypeEnum {

        const marker = this.markers.find(x => x.page === page);
        return marker ? marker.type : null;
    }

    public getPageTransform(page: number): number {

        const marker = this.getPageMarkerType(page);
        if (marker === null) {
            return 0;
        }

        if (marker === ScanPageMarkerTypeEnum.DOCUMENT) {
            return -16;
        } else if (marker === ScanPageMarkerTypeEnum.ATTACHMENT) {
            return 16;
        }

        return 0;
    }

    public navigateToList(): void {
        this.dialogRef.close(false);
    }

    public navigateToNext(): void {

        this.isSearchingNext = true;
        this.documentGroupService.getNextDocument(this.id).subscribe(ids => {
            this.isSearchingNext = false;

            this.dialogRef.close(false);
            if (ids.length === 1) {
                this.dialogRef.close(false);
                this.router.navigate([...this.redirectPath, ids[0].id]);
            }
        });
    }

    public onReceiptDateSelect(date: moment.Moment): void {
        this.receiptDate.setValue(date.format('L'));
        this.selectedReceiptDate = new Date(date.toDate());
    }

    public getMarkersError(): string {

        const markers = this.markers.filter(x => x.type === ScanPageMarkerTypeEnum.DOCUMENT).length;
        if (markers === 0) {
            return 'Musisz oznaczyć minimum jeden dokument';
        }

        for (let page = 1; page < this.pagesCount; page++) {
            const marker = this.markers.find(x => x.page === page);
            if (!marker) {
                return `Strona ${page} nie została oznaczona`;
            }

            if (marker.type === ScanPageMarkerTypeEnum.DOCUMENT || marker.type === ScanPageMarkerTypeEnum.ATTACHMENT) {
                break;
            }
        }

        return null;
    }

    @HostListener('window:resize')
    public onResize() {
        this.updateOffsets();
    }

    private enterEditMode(): void {

        if (this.refreshEditStatusInterval) {
            clearInterval(this.refreshEditStatusInterval);
        }

        this.tryTurnOnEdit();
        this.refreshEditStatusInterval = setInterval(
            () => this.tryTurnOnEdit(), 60000
        );
    }

    private tryTurnOnEdit(): void {

        this.shouldCallTurnOffEdit = true;
        this.turnOnEditSubscription = this.documentGroupService.turnOnEdit(this.id).subscribe(
            _ => { },
            error => {
                if (error.status === 409) {
                    this.shouldCallTurnOffEdit = false;
                    this.dialogRef.close(false);
                }
            }
        );
    }

    public loadPagePreview(page: { num: number, isLoaded: boolean, source: string | ArrayBuffer, subscription: Subscription }): void {

        if (page.isLoaded || page.subscription !== null) {
            return;
        }

        const url = this.documentGroupService.getPageImageUrl(this.id, page.num, 'small');
        page.subscription = this.imageService.getImageSource(url, result => {
            page.source = result;
        });
    }

    public goToLastPage(): void {
        this.setCurrentPage(this.pagesCount);
    }

    public goToFirstPage(): void {
        this.setCurrentPage(1);
    }

    public deleteDocumentGroup(): void {

        const data: DocumentDeleteDialogData = { documentNumbers: [null] };
        const dialogRef = this.dialog.open(DocumentDeleteDialogComponent, { data, autoFocus: false });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.documentGroupService.delete([this.id]).subscribe(_ => {
                    this.dialogRef.close(false);
                });
            }
        });
    }
}
