<div class="reload-container" [@slide]="show">
    <div class="reload-container__content">
        {{ content }}
    </div>

    <div class="reload-container__actions">
        <button mat-stroked-button color="primary" (click)="refreshClicked()">
            Odśwież
        </button>
    </div>
</div>
