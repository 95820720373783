import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatCardModule,
    MatSortModule,
    MatMenuModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatSelectModule,
    MatCheckboxModule,
    MatExpansionModule,
} from '@angular/material';

import { SatDatepickerModule, SatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE, DateAdapter } from 'saturn-datepicker';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';

import localePL from '@angular/common/locales/pl';
import { registerLocaleData, DecimalPipe } from '@angular/common';
import { SplitAttachmentDialogContainerComponent } from './sp-attachment/sp-attachment-container/sp-attachment-dialog-container.component';
import { SplitAttachmentDialogComponent } from './sp-attachment/sp-attachment-dialog/sp-attachment-dialog.component';
import { SplitAttachmentFiltersComponent } from './sp-attachment/sp-attachment-filters/sp-attachment-filters.component';
import { SplitAttachmentListComponent } from './sp-attachment/sp-attachment-list/sp-attachment-list.component';
import { SplitAttachmentZoomComponent } from './sp-attachment/sp-attachment-zoom/sp-attachment-zoom.component';
registerLocaleData(localePL, 'pl');

moment.locale('pl');
moment.updateLocale('pl', {
    longDateFormat: {
        LT: 'h:mm A',
        LTS: 'h:mm:ss A',
        L: 'DD.MM.YYYY',
        l: 'DD.MM.YYYY',
        LL: 'MMMM Do YYYY',
        ll: 'MMM D YYYY',
        LLL: 'MMMM Do YYYY LT',
        lll: 'MMM D YYYY LT',
        LLLL: 'dddd, MMMM Do YYYY LT',
        llll: 'ddd, MMM D YYYY LT'
    }
});

import { NavigationComponent } from './shared/navigation/navigation.component';
import { HeaderComponent } from './shared/header/header.component';
import { SplitListComponent } from './split/split-list/split-list.component';
import { SplitFiltersComponent } from './split/split-filters/split-filters.component';
import { AnalyzingDocumentsComponent } from './shared/analyzing-documents/analyzing-documents.component';
import { LayoutDefaultComponent } from './shared/layout-default/layout-default.component';
import { LoginComponent } from './shared/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { SettingsPageComponent } from './settings/settings-page/settings-page.component';
import { SettingsOrganizationComponent } from './settings/organization/settings-organization/settings-organization.component';
import { SettingsTrustedContactsComponent } from './settings/uploading/settings-trusted-contacts/settings-trusted-contacts.component';
import { UserRoleComponent } from './shared/user-role/user-role.component';
import { UnauthorizedInterceptor } from './shared/interceptors/unauthorized.interceptor';
import { SplitZoomComponent } from './split/split-zoom/split-zoom.component';
import { SettingsEmailsComponent } from './settings/uploading/settings-emails/settings-emails.component';
import { ResetPasswordComponent } from './shared/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './shared/forgot-password/forgot-password.component';
import { NoLocalStorageComponent } from './shared/errors/no-local-storage/no-local-storage.component';
import {
    AddOrganizationUserDialogComponent
} from './settings/organization/add-organization-user-dialog/add-organization-user-dialog.component';
import { RegisterComponent } from './shared/register/register.component';
import { SettingsAccountComponent } from './settings/account/settings-account/settings-account.component';
import { ChangePasswordDialogComponent } from './settings/account/change-password-dialog/change-password-dialog.component';
import { ActivateAccountComponent } from './shared/activate-account/activate-account.component';
import { SplitDialogComponent } from './split/split-dialog/split-dialog.component';
import { SplitDialogContainerComponent } from './split/split-dialog-container/split-dialog-container.component';
import { DocumentDetailsModule } from './document-details/document-details.module';
import { DocumentsModule } from './documents/documents.module';
import { SharedModule } from './shared/shared.module';


@NgModule({
    declarations: [
        AppComponent,
        NavigationComponent,
        HeaderComponent,
        SplitListComponent,
        SplitFiltersComponent,
        AnalyzingDocumentsComponent,
        LayoutDefaultComponent,
        LoginComponent,
        RegisterComponent,
        SplitDialogComponent,
        SettingsPageComponent,
        SettingsOrganizationComponent,
        SettingsTrustedContactsComponent,
        UserRoleComponent,
        SplitZoomComponent,
        SettingsEmailsComponent,
        ResetPasswordComponent,
        ForgotPasswordComponent,
        NoLocalStorageComponent,
        AddOrganizationUserDialogComponent,
        SettingsAccountComponent,
        ChangePasswordDialogComponent,
        ActivateAccountComponent,
        SplitDialogContainerComponent,
        SplitAttachmentDialogContainerComponent,
        SplitAttachmentDialogComponent,
        SplitAttachmentFiltersComponent,
        SplitAttachmentListComponent,
        SplitAttachmentZoomComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        DocumentDetailsModule,
        DocumentsModule,
        SharedModule,

        SatDatepickerModule,
        SatNativeDateModule,

        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatCardModule,
        MatSortModule,
        MatMenuModule,
        MatDividerModule,
        MatAutocompleteModule,
        MatOptionModule,
        MatDialogModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatSelectModule,
        MatCheckboxModule,
        MatExpansionModule,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'pl' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorizedInterceptor,
            multi: true
        },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        DecimalPipe,
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        AddOrganizationUserDialogComponent,
        SplitDialogComponent,
    ],
})
export class AppModule { }
