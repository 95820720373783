import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl, FormGroup, FormArray, FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AmountFormatterService } from '../formatters/amount-formatter-service/amount-formatter.service';

@Injectable({
    providedIn: 'root'
})
export class ValidationService {

    constructor(
        private amountFormatterService: AmountFormatterService,
    ) { }

    public isNipValid(nip: string) {

        nip = nip.replace(/[^0-9]/g, '');
        const reg = /^[0-9]{10}$/;
        if (!reg.test(nip)) {
            return false;
        }

        const digits = nip.split('');
        const checksum = (
            6 * parseInt(digits[0], 10) +
            5 * parseInt(digits[1], 10) +
            7 * parseInt(digits[2], 10) +
            2 * parseInt(digits[3], 10) +
            3 * parseInt(digits[4], 10) +
            4 * parseInt(digits[5], 10) +
            5 * parseInt(digits[6], 10) +
            6 * parseInt(digits[7], 10) +
            7 * parseInt(digits[8], 10)
        ) % 11;

        return parseInt(digits[9], 10) === checksum;
    }

    public isAccountValid(account: string): boolean {

        const CODE_LENGTHS = {
            AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
            CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
            FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
            HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
            LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
            MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
            RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
        };

        const iban = account.toUpperCase().replace(/[^A-Z0-9]/g, '');
        const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);

        if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
            return false;
        }

        const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, letter => (letter.charCodeAt(0) - 55).toString());

        let part = digits.slice(0, 2);
        let checksum = 0;

        for (let offset = 2; offset < digits.length; offset += 7) {
            const fragment = part + digits.substring(offset, offset + 7);
            checksum = parseInt(fragment, 10) % 97;
            part = checksum.toString();
        }

        return checksum === 1;
    }

    public isAmountValid(amount: string): boolean {

        if (amount === null) {
            return true;
        }

        amount = amount.replace(/\,/g, '.').replace(/\s/g, '');
        return /^\-?\d+(\.)?(\d{1,2})?$/.test(amount);
    }

    public taxNumberValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {

            if (control.value === '') {
                return null;
            }

            const isValid = this.isNipValid(control.value);
            return isValid ? null : { invalidTaxNumber: true };
        };
    }

    public accountValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {

            let value = control.value;
            if (value === '') {
                return null;
            }

            const code = value.substring(0, 2);
            if (!(/^[a-zA-Z]{2}$/.test(code))) {
                value = `${environment.defaultIbanCode}${value}`;
            }

            const isValid = this.isAccountValid(value);
            return isValid ? null : { invalidAccount: true };
        };
    }

    public amountValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {

            if (control.value === '') {
                return null;
            }

            const isValid = this.isAmountValid(control.value);
            return isValid ? null : { invalidAmount: true };
        };
    }

    public summaryValidator(type: string): ValidatorFn {
        return (form: AbstractControl): { [key: string]: any } | null => {

            const formGroup = form as FormGroup;
            const vatRows = formGroup.get('vatRows') as FormArray;

            let total = 0;
            for (const control of vatRows.controls) {
                const vatRowGroup = control as FormGroup;
                const vatControl = vatRowGroup.get(type) as FormControl;

                const value = parseFloat(this.amountFormatterService.parse(vatControl.value));
                total += value;
            }

            const totalControl = formGroup.get(`amount${type.charAt(0).toUpperCase()}${type.substring(1)}`) as FormControl;
            const totalValue = parseFloat(this.amountFormatterService.parse(totalControl.value));
            const notEqual = Math.abs(totalValue - total) > 0.005;

            return notEqual ? {
                [`invalid${type.charAt(0).toUpperCase()}${type.substring(1)}Summary`]: true
            } : null;
        };
    }

    public totalValueValidator(): ValidatorFn {
        return (form: AbstractControl): { [key: string]: any } | null => {

            const formGroup = form as FormGroup;

            const amountNet = formGroup.get(`amountNet`) as FormControl;
            const amountVat = formGroup.get(`amountVat`) as FormControl;
            const amountGross = formGroup.get(`amountGross`) as FormControl;

            const net = parseFloat(this.amountFormatterService.parse(amountNet.value));
            const vat = parseFloat(this.amountFormatterService.parse(amountVat.value));
            const gross = parseFloat(this.amountFormatterService.parse(amountGross.value));

            const notEqual = Math.abs(net + vat - gross) > 0.005;
            return notEqual ? { invalidTotalSummary: true } : null;
        };
    }

    public vatRowValidator(): ValidatorFn {
        return (row: AbstractControl): { [key: string]: any } | null => {

            const rowFormGroup = row as FormGroup;

            const netControl = rowFormGroup.get('net') as FormControl;
            const rateControl = rowFormGroup.get('rate') as FormControl;
            const vatControl = rowFormGroup.get('vat') as FormControl;
            const grossControl = rowFormGroup.get('gross') as FormControl;

            let rateValue = 0;
            if (!isNaN(rateControl.value)) {
                rateValue = parseInt(rateControl.value, 10);
            }

            const netValue = parseFloat(this.amountFormatterService.parse(netControl.value));
            const vatValue = parseFloat(this.amountFormatterService.parse(vatControl.value));
            const grossValue = parseFloat(this.amountFormatterService.parse(grossControl.value));

            const netVat = Math.round(netValue * rateValue) / 100 === vatValue;
            const grossNet = Math.round(grossValue * 10000 / (100 + rateValue)) / 100 === netValue;
            const grossVat = Math.round(grossValue * rateValue * 100 / (100 + rateValue)) / 100 === vatValue;

            if (grossNet && grossVat) {
                return null;
            }

            if (!netControl.value && !vatControl.value && !grossControl.value) {
                return null;
            }

            if (grossVat && !grossNet && !netVat) {
                return { invalidNetValue: true };
            } else if (netVat && !grossNet && !grossVat) {
                return { invalidGrossValue: true };
            } else if (grossNet && !grossVat && !netVat) {
                return { invalidVatValue: true };
            } else if (!grossVat && !grossNet && !netVat) {
                return { invalidNetValue: true };
            } else if (netVat && grossVat && !grossNet) {
                return { invalidGrossValue: true };
            }

            return null;
        };
    }
}
