import { Component, Input } from '@angular/core';

@Component({
    selector: 'digica-feature-name',
    templateUrl: './feature-name.component.html'
})
export class FeatureNameComponent {

    @Input() feature: string;
}
