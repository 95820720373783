import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user-service/user.service';
import { UserRoleEnum } from 'src/app/contract/user/user-role.enum';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { map, filter } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'digica-settings-page',
    templateUrl: './settings-page.component.html',
    styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent implements OnInit {

    public role: Observable<UserRoleEnum>;
    public UserRoleEnum = UserRoleEnum;

    constructor(
        private userService: UserService,
        private titleService: Title,
    ) { }

    public ngOnInit(): void {

        this.titleService.setTitle(`Ustawienia | ${environment.applicationTabName}`);

        this.role = this.userService.userExtract.pipe(
            filter(x => x != null),
            map(x => x.role)
        );
    }
}
