import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Page } from 'src/app/shared/models/page';
import { ImageService } from 'src/app/services/image-service/image.service';
import { DocumentGroupService } from 'src/app/services/document-group-service/document-group.service';

@Component({
    selector: 'digica-split-zoom',
    templateUrl: './split-zoom.component.html',
    styleUrls: ['./split-zoom.component.scss'],
    animations: [
        trigger('zoom', [
            state('zoomOut', style({
                transform: 'scale(0.5)',
                opacity: 0.0,
            })),
            state('zoomIn', style({
                transform: 'scale(1)',
                opacity: 1.0,
            })),
            transition('zoomIn => zoomOut', animate('300ms')),
            transition('zoomOut => zoomIn', animate('300ms'))
        ]),
    ]
})
export class SplitZoomComponent implements OnInit, OnDestroy {

    @Input() documentGroupId: number;

    public zoomState = 'zoomOut';
    public zoomPage = new Page();
    public thumbPage: Page;

    constructor(
        private imageService: ImageService,
        private documentGroupService: DocumentGroupService,
    ) { }

    public ngOnInit(): void {
    }

    public ngOnDestroy(): void {
        this.clearSubscriptions();
    }

    private clearSubscriptions(): void {

        if (this.zoomPage.subscription) {
            this.zoomPage.subscription.unsubscribe();
        }
    }

    public toggleZoom(page: Page): void {
        if (page === null || !page.isLoaded) {
            return;
        }

        this.clearSubscriptions();
        this.zoomState = this.zoomState === 'zoomOut' ? 'zoomIn' : 'zoomOut';

        if (this.zoomState === 'zoomIn') {

            this.thumbPage = page;
            this.zoomPage.source = null;

            const url = this.documentGroupService.getPageImageUrl(this.documentGroupId, page.num, 'big');
            this.zoomPage.subscription = this.imageService.getImageSource(url, result => {
                this.zoomPage.source = result;
            });
        }
    }

    public zoomOut(): void {
        this.zoomState = 'zoomOut';
        this.clearSubscriptions();
    }
}
