
import { Area } from './area';

export class VATFeatureArea {
    net: Area;
    rate: Area;
    vat: Area;
    gross: Area;

    constructor() {
        this.net = new Area();
        this.rate = new Area();
        this.vat = new Area();
        this.gross = new Area();
    }
}

export class InvoiceFeatureArea {
    invoiceNumber: Area;
    toPay: Area;
    issueDate: Area;
    paymentDeadline: Area;
    amountPayed: Area;
    payedOn: Area;
    daysOverdue: Area;
    interestToPay: Area;

    constructor() {
        this.invoiceNumber = new Area();
        this.toPay = new Area();
        this.issueDate = new Area();
        this.paymentDeadline = new Area();
        this.amountPayed = new Area();
        this.payedOn = new Area();
        this.daysOverdue = new Area();
        this.interestToPay = new Area();
    }
}

export class FeatureAreas {

    vatRows: Array<VATFeatureArea>;
    correctionVatRows: Array<VATFeatureArea>;
    billingVatRows: Array<VATFeatureArea>;
    prepaymentVatRows: Array<VATFeatureArea>;
    /*provisionalVatRows: Array<VATFeatureArea>;*/
    noteInvoiceListRows: Array<InvoiceFeatureArea>;
}
