import { Injectable } from '@angular/core';
import { IParser } from '../iparser';

@Injectable({
    providedIn: 'root'
})
export class AmountParserService implements IParser {

    constructor() { }

    public parse(value: string): string {

        let match = value.match(/(?<integer>((\d|\.|,| )+)+)(( |\.|,)(?<decimal>\d{2})($| |[a-zA-Z]))/);
        if (match !== null) {
            return `${match.groups.integer.replace(/\s|\.|,/g, '')}.${match.groups.decimal}`;
        }

        match = value.match(/(?<integer>(\d|\.|,| )+)/);
        if (match !== null) {
            return `${match.groups.integer.replace(/\s|\.|,/g, '')}`;
        }

        return value;
    }
}
