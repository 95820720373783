import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatButtonModule,
    MatCheckboxModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatTooltipModule
} from '@angular/material';

import { RouterModule } from '@angular/router';
import { SatDatepickerModule } from 'saturn-datepicker';
import { SharedModule } from '../shared/shared.module';
import { ConnectedDocumentComponent } from './connected-document/connected-document.component';
import { ConnectedDocumentsListComponent } from './connected-documents-list/connected-documents-list.component';
import { DocumentBaseInformationComponent } from './document-base-information/document-base-information.component';
import { DocumentDetailsContractorsComponent } from './document-details-contractors/document-details-contractors.component';
import {
    DocumentDetailsCorrectionInvoiceBaseComponent
} from './document-details-correction-invoice-base/document-details-correction-invoice-base.component';
import { DocumentDetailsInvoiceBaseComponent } from './document-details-invoice-base/document-details-invoice-base.component';
import { DocumentDetailsVatTableComponent } from './document-details-vat-table/document-details-vat-table.component';
import { DocumentDuplicatesComponent } from './document-duplicates/document-duplicates.component';
import {
    DocumentDetailsAccountingNoteBaseComponent
} from './document-details-accounting-note-base/document-details-accounting-note-base.component';
import {
    DocumentDetailsNoteInvoiceTableComponent
} from './document-details-note-invoice-table/document-details-note-invoice-table.component';
import {
    DocumentDetailsPrepaymentInvoiceBaseComponent
} from './document-details-prepayment-invoice-base/document-details-prepayment-invoice-base.component';
import {
    DocumentDetailsBillingInvoiceBaseComponent
} from './document-details-billing-invoice-base/document-details-billing-invoice-base.component';
// import {
//    DocumentDetailsProvisionalInvoiceBaseComponent
// } from './document-details-provisional-invoice-base/document-details-provisional-invoice-base.component';

const declarations = [
    DocumentDetailsBillingInvoiceBaseComponent,
    DocumentDetailsPrepaymentInvoiceBaseComponent,
    DocumentDetailsVatTableComponent,
    // DocumentDetailsProvisionalInvoiceBaseComponent,
    DocumentDetailsInvoiceBaseComponent,
    DocumentDetailsContractorsComponent,
    DocumentBaseInformationComponent,
    DocumentDetailsCorrectionInvoiceBaseComponent,
    DocumentDetailsAccountingNoteBaseComponent,
    DocumentDetailsNoteInvoiceTableComponent,
    DocumentDuplicatesComponent,
    ConnectedDocumentComponent,
    ConnectedDocumentsListComponent,
];

@NgModule({
    declarations: [
        ...declarations,
    ],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,

        SatDatepickerModule,
        MatInputModule,
        MatFormFieldModule,
        MatDividerModule,
        MatButtonModule,
        MatOptionModule,
        MatSelectModule,
        MatCheckboxModule,
        MatTooltipModule,
    ],
    exports: [
        ...declarations,
    ],
    entryComponents: [
    ],
})
export class DocumentDetailsModule { }
