import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DocumentConnection } from 'src/app/contract/document/document-connection.model';
import { DocumentExtract } from 'src/app/contract/document/document-extract';
import { ConnectDocumentModel } from './connect-document.model';

@Injectable({
    providedIn: 'root'
})
export class ConnectedDocumentService {

    public openEvent$ = new Subject<ConnectDocumentModel>();
    private onClose: Subject<DocumentExtract>;

    public openConnectingDocument(documentId: number, documentConnections: Array<DocumentConnection>): Subject<DocumentExtract> {

        this.openEvent$.next({ documentId, documentConnections });
        this.onClose = new Subject<any>();

        return this.onClose;
    }

    public closeConnectingDocument(): void {
        this.onClose.next(null);
        this.onClose.complete();
    }

    public selectConnectedDocument(documentExtract: DocumentExtract): void {
        this.onClose.next(documentExtract);
        this.onClose.complete();
    }
}
