<div class="background gray">

    <div class="container menu">
        <div class="label-container">
            <span class="label">Wyświetl</span>
        </div>

        <div class="menu-items">
            <a (click)="setShowFilter('all')" [class.active]="isShowFilterActive('all')">
                Wszystkie pliki
            </a>

            <a (click)="setShowFilter('my')" [class.active]="isShowFilterActive('my')">
                Moje pliki
            </a>

            <a (click)="setShowFilter('e-invoices')" [class.active]="isShowFilterActive('e-invoices')">
                E-faktury
            </a>
        </div>
    </div>

</div>