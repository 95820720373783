import { Component, ElementRef, OnChanges, OnDestroy, OnInit, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Input } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { DetailsService } from '../services/details/details.service';
import { IDocumentDetails } from '../document-details.interface';
import { Subscription } from 'rxjs';
import { PaymentMethodParserService } from 'src/app/services/parsers/payment-method-parser-service/payment-method-parser.service';
import { DateParserService } from 'src/app/services/parsers/date-parser-service/date-parser.service';
import { DocumentModel } from 'src/app/contract/document/document.model';

@Component({
    selector: 'digica-document-details-prepayment-invoice-base',
    templateUrl: './document-details-prepayment-invoice-base.component.html',
    styleUrls: ['./document-details-prepayment-invoice-base.component.scss']
})
export class DocumentDetailsPrepaymentInvoiceBaseComponent implements IDocumentDetails, OnInit, OnDestroy, OnChanges {

    @Input() public documentModel: DocumentModel;
    @Input() public isEditable: boolean;

    @ViewChildren('errors') errors: QueryList<TemplateRef<ElementRef>>;

    public availablePaymentMethods = [
        { value: 'Karta', display: 'Karta' },
        { value: 'Przelew', display: 'Przelew' },
        { value: 'Gotówka', display: 'Gotówka' },
        { value: 'Rekompensata', display: 'Rekompensata' },
        { value: 'Przedpłata', display: 'Przedpłata'}
    ];

    public formGroup = new FormGroup({
        prepaymentInvoiceNumber: new FormControl('', [Validators.required]),
        prepaymentIssueDate: new FormControl('', []),
        prepaymentCurrency: new FormControl('', []),
        prepaymentPaymentMethod: new FormControl(''),
        prepaymentSplitPayment: new FormControl(false),
        prepaymentSaleDate: new FormControl('', [Validators.required]),
        prepaymentPaymentDeadline: new FormControl('', []),
        isDuplicate: new FormControl(false),
        duplicateDate: new FormControl()
    });

    public get prepaymentInvoiceNumber() { return this.formGroup.get('prepaymentInvoiceNumber') as FormControl; }
    public get prepaymentIssueDate() { return this.formGroup.get('prepaymentIssueDate') as FormControl; }
    public get prepaymentCurrency() { return this.formGroup.get('prepaymentCurrency') as FormControl; }
    public get prepaymentPaymentMethod() { return this.formGroup.get('prepaymentPaymentMethod') as FormControl; }
    public get prepaymentSplitPayment() { return this.formGroup.get('prepaymentSplitPayment') as FormControl; }
    public get prepaymentSaleDate() { return this.formGroup.get('prepaymentSaleDate') as FormControl; }
    public get prepaymentPaymentDeadline() { return this.formGroup.get('prepaymentPaymentDeadline') as FormControl; }
    public get isDuplicate() { return this.formGroup.get('isDuplicate') as FormControl; }
    public get duplicateDate() { return this.formGroup.get('duplicateDate') as FormControl; }

    private paymentMethodValueChangesSubscription: Subscription;
    private isDuplicateSubscription: Subscription;

    constructor(
        public detailsService: DetailsService,
        public paymentMethodParserService: PaymentMethodParserService,
        public dateParserService: DateParserService
    ) { }

    public ngOnInit(): void {
        this.detailsService.currencyValue = this.prepaymentCurrency.valueChanges;
        this.detailsService.paymentMethodValueChanges = this.prepaymentPaymentMethod.valueChanges;
        this.detailsService.documentNumberControl = this.prepaymentInvoiceNumber;

        this.isDuplicateSubscription = this.isDuplicate.valueChanges.subscribe(value => {
            this.duplicateDate.clearValidators();

            if (value) {
                this.duplicateDate.setValidators([Validators.required]);
            }

            this.duplicateDate.updateValueAndValidity();
        });
    }

    public ngOnDestroy(): void {

        if (this.paymentMethodValueChangesSubscription) {
            this.paymentMethodValueChangesSubscription.unsubscribe();
        }

        if (this.isDuplicateSubscription) {
            this.isDuplicateSubscription.unsubscribe();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {

        if (changes.documentModel && changes.documentModel.currentValue) {
            this.detailsService.updateFormControl(this.prepaymentCurrency, this.documentModel.prepaymentCurrency);
            this.detailsService.updateFormControl(this.prepaymentInvoiceNumber, this.documentModel.prepaymentInvoiceNumber);
            this.detailsService.updateFormControl(this.prepaymentPaymentMethod, this.documentModel.prepaymentPaymentMethod);
            this.detailsService.updateFormControl(this.prepaymentSplitPayment, this.documentModel.prepaymentSplitPayment);
            this.detailsService.updateFormControl(this.prepaymentIssueDate, this.documentModel.prepaymentIssueDate);
            this.detailsService.updateFormControl(this.prepaymentSaleDate, this.documentModel.prepaymentSaleDate);
            this.detailsService.updateFormControl(this.prepaymentPaymentDeadline, this.documentModel.prepaymentPaymentDeadline);
            this.detailsService.updateFormControl(this.isDuplicate, this.documentModel.isDuplicate);
            this.detailsService.updateFormControl(this.duplicateDate, this.documentModel.duplicateDate);
            this.detailsService.markFormGroupTouched(this.formGroup);
        }

        this.detailsService.changeEnabledFormStateBasingOnIsEditable(this.isEditable, this.formGroup);
    }

    public assignDocumentModelValues(documentModel: DocumentModel): void {

        this.detailsService.updateDocumentModelFeature(documentModel, 'prepaymentCurrency', this.prepaymentCurrency.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'prepaymentInvoiceNumber', this.prepaymentInvoiceNumber.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'prepaymentPaymentMethod', this.prepaymentPaymentMethod.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'prepaymentSplitPayment', this.prepaymentSplitPayment.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'prepaymentIssueDate', this.prepaymentIssueDate.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'prepaymentSaleDate', this.prepaymentSaleDate.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'prepaymentPaymentDeadline', this.prepaymentPaymentDeadline.value);
        this.detailsService.updateDocumentModelFeature(documentModel, 'isDuplicate', this.isDuplicate.value);

        if (this.isDuplicate.value) {
            this.detailsService.updateDocumentModelFeature(documentModel, 'duplicateDate', this.duplicateDate.value);
        }
    }

    public canDeactivate = () => this.formGroup.pristine;
    public canConfirm = () => true;
    public getErrors = (): TemplateRef<ElementRef<any>>[] => this.errors.toArray();
}
