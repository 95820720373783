
<digica-connected-document></digica-connected-document>

<div class="details" (keyup.alt)="onAltPressed($event)">
    <div class="header">
        <div class="placeloader black" *ngIf="!documentModel"></div>
        <h2 *ngIf="documentModel && isEditable">Uzupełnij dane</h2>
        <h2 *ngIf="documentModel && !isEditable">
            Podgląd archiwalny
            <button
                mat-stroked-button
                color="primary"
                (click)="enableArchiveEdit()"
            >
                Włącz edycje
            </button>
        </h2>

        <div class="actions">
            <a (click)="downloadOriginalFile()">
                <span class="icon download"></span>
            </a>
            <a (click)="deleteDocument()" *ngIf="isEditable">
                <span class="icon delete"></span>
            </a>
            <a (click)="closeDialog()">
                <span class="icon close"></span>
            </a>
        </div>
    </div>

    <form [formGroup]="formGroup" (ngSubmit)="onSubmit(true)">

        <digica-document-base-information
            #documentDetails
            [documentModel]="documentModel"
            [isEditable]="isEditable"
        ></digica-document-base-information>

        <digica-document-duplicates
            *ngIf="!documentModel || documentModel.currentStatus.value !== DocumentStatusEnum.Archived"
            #documentDetails
            [documentModel]="documentModel"
            [isEditable]="isEditable"
        ></digica-document-duplicates>

        <digica-connected-documents-list
            #documentDetails
            [documentModel]="documentModel"
            [isEditable]="isEditable"
        ></digica-connected-documents-list>

        <!-- PurchaseInvoice -->
        <digica-document-details-invoice-base
            *ngIf="!documentModel || documentModel.documentType === DocumentTypeEnum.PurchaseInvoice"
            #documentDetails
            [documentModel]="documentModel"
            [isEditable]="isEditable"
        ></digica-document-details-invoice-base>

        <digica-document-details-contractors
            *ngIf="!documentModel || documentModel.documentType === DocumentTypeEnum.PurchaseInvoice"
            #documentDetails
            [documentModel]="documentModel"
            [isEditable]="isEditable"
        ></digica-document-details-contractors>

        <digica-document-details-vat-table
            *ngIf="!documentModel || documentModel.documentType === DocumentTypeEnum.PurchaseInvoice"
            #documentDetails
            [documentModel]="documentModel"
            [isEditable]="isEditable"
        ></digica-document-details-vat-table>

        <!-- InvoiceCorrection -->
        <digica-document-details-correction-invoice-base
            *ngIf="documentModel && documentModel.documentType === DocumentTypeEnum.InvoiceCorrection"
            #documentDetails
            [documentModel]="documentModel"
            [isEditable]="isEditable"
        ></digica-document-details-correction-invoice-base>

        <digica-document-details-contractors
            *ngIf="documentModel && documentModel.documentType === DocumentTypeEnum.InvoiceCorrection"
            #documentDetails
            [documentModel]="documentModel"
            [isEditable]="isEditable"
            [taxNumberFeatureName]="'correctionSellerTaxNumber'"
            [accountFeatureName]="'correctionSellerAccount'"
            [nameFeatureName]="'correctionSellerName'"
            [streetFeatureName]="'correctionSellerStreet'"
            [addressFeatureName]="'correctionSellerAddress'"
            [isInputRequired]="false"
        ></digica-document-details-contractors>

        <digica-document-details-vat-table
            *ngIf="documentModel && documentModel.documentType === DocumentTypeEnum.InvoiceCorrection"
            #documentDetails
            [documentModel]="documentModel"
            [isEditable]="isEditable"
            [amountNetFeatureName]="'correctionAmountNet'"
            [amountVatFeatureName]="'correctionAmountVat'"
            [amountGrossFeatureName]="'correctionAmountGross'"
            [vatRowsFeatureName]="'correctionVatRows'"
            [isInputRequired]="false"
        ></digica-document-details-vat-table>

        <!-- PrepaymentInvoice -->
        <digica-document-details-prepayment-invoice-base
            *ngIf="documentModel && documentModel.documentType === DocumentTypeEnum.PrepaymentInvoice"
            #documentDetails
            [documentModel]="documentModel"
            [isEditable]="isEditable"
        ></digica-document-details-prepayment-invoice-base>

        <digica-document-details-contractors
            *ngIf="documentModel && documentModel.documentType === DocumentTypeEnum.PrepaymentInvoice"
            #documentDetails
            [documentModel]="documentModel"
            [isEditable]="isEditable"
            [taxNumberFeatureName]="'prepaymentSellerTaxNumber'"
            [accountFeatureName]="'prepaymentSellerAccount'"
            [nameFeatureName]="'prepaymentSellerName'"
            [streetFeatureName]="'prepaymentSellerStreet'"
            [addressFeatureName]="'prepaymentSellerAddress'"
            [isInputRequired]="false"
        ></digica-document-details-contractors>

        <digica-document-details-vat-table
            *ngIf="documentModel && documentModel.documentType === DocumentTypeEnum.PrepaymentInvoice"
            #documentDetails
            [documentModel]="documentModel"
            [isEditable]="isEditable"
            [amountNetFeatureName]="'prepaymentAmountNet'"
            [amountVatFeatureName]="'prepaymentAmountVat'"
            [amountGrossFeatureName]="'prepaymentAmountGross'"
            [vatRowsFeatureName]="'prepaymentVatRows'"
            [isInputRequired]="false"
        ></digica-document-details-vat-table>

        <!-- BillingInvoice -->
        <digica-document-details-billing-invoice-base
            *ngIf="documentModel && documentModel.documentType === DocumentTypeEnum.BillingInvoice"
            #documentDetails
            [documentModel]="documentModel"
            [isEditable]="isEditable"
        ></digica-document-details-billing-invoice-base>

        <digica-document-details-contractors
        *ngIf="documentModel && documentModel.documentType === DocumentTypeEnum.BillingInvoice"
            #documentDetails
            [documentModel]="documentModel"
            [isEditable]="isEditable"
            [taxNumberFeatureName]="'billingSellerTaxNumber'"
            [accountFeatureName]="'billingSellerAccount'"
            [nameFeatureName]="'billingSellerName'"
            [streetFeatureName]="'billingSellerStreet'"
            [addressFeatureName]="'billingSellerAddress'"
            [isInputRequired]="false"
        ></digica-document-details-contractors>

        <digica-document-details-vat-table
            *ngIf="documentModel && documentModel.documentType === DocumentTypeEnum.BillingInvoice"
            #documentDetails
            [documentModel]="documentModel"
            [isEditable]="isEditable"
            [amountNetFeatureName]="'billingAmountNet'"
            [amountVatFeatureName]="'billingAmountVat'"
            [amountGrossFeatureName]="'billingAmountGross'"
            [vatRowsFeatureName]="'billingVatRows'"
            [isInputRequired]="false"
        ></digica-document-details-vat-table>

        <!-- Provisional Invoice -->
        <!--<digica-document-details-provisional-invoice-base
            *ngIf="documentModel && documentModel.documentType === DocumentTypeEnum.ProvisionalInvoice"
            #documentDetails
            [documentModel]="documentModel"
            [isEditable]="isEditable"
        ></digica-document-details-provisional-invoice-base>

        <digica-document-details-contractors
            *ngIf="documentModel && documentModel.documentType === DocumentTypeEnum.ProvisionalInvoice"
            #documentDetails
            [documentModel]="documentModel"
            [isEditable]="isEditable"
            [taxNumberFeatureName]="'provisionalSellerTaxNumber'"
            [accountFeatureName]="'provisionalSellerAccount'"
            [nameFeatureName]="'provisionalSellerName'"
            [streetFeatureName]="'provisionalSellerStreet'"
            [addressFeatureName]="'provisionalSellerAddress'"
            [isInputRequired]="false"
        ></digica-document-details-contractors>

        <digica-document-details-vat-table
            *ngIf="documentModel && documentModel.documentType === DocumentTypeEnum.ProvisionalInvoice"
            #documentDetails
            [documentModel]="documentModel"
            [isEditable]="isEditable"
            [amountNetFeatureName]="'provisionalAmountNet'"
            [amountVatFeatureName]="'provisionalAmountVat'"
            [amountGrossFeatureName]="'provisionalAmountGross'"
            [vatRowsFeatureName]="'provisionalVatRows'"
            [isInputRequired]="false"
        ></digica-document-details-vat-table>-->


        <!-- Accounting Note -->
        <digica-document-details-accounting-note-base
            *ngIf="documentModel && documentModel.documentType === DocumentTypeEnum.AccountingNote"
            #documentDetails
            [documentModel]="documentModel"
            [isEditable]="isEditable"
        ></digica-document-details-accounting-note-base>

        <digica-document-details-contractors
            *ngIf="documentModel && documentModel.documentType === DocumentTypeEnum.AccountingNote"
            #documentDetails
            [documentModel]="documentModel"
            [isEditable]="isEditable"
            [taxNumberFeatureName]="'noteTaxNumber'"
            [accountFeatureName]="'noteAccountNumber'"
            [nameFeatureName]="'noteSellerName'"
            [streetFeatureName]="'noteSellerStreet'"
            [addressFeatureName]="'noteSellerAddress'"
            [isInputRequired]="false"
        ></digica-document-details-contractors>

        <!-- Unwanted feature -->
        <!-- <digica-document-details-note-invoice-table
            *ngIf="documentModel && documentModel.documentType === DocumentTypeEnum.AccountingNote"
            #documentDetails
            [documentModel]="documentModel"
            [isEditable]="isEditable"
        ></digica-document-details-note-invoice-table> -->

        <div class="actions-wrapper" *ngIf="isEditable">
            <div class="confirm-wrapper">
                <button
                    mat-button
                    class="secondary-button with-loader"
                    color="primary"
                    type="button"
                    (click)="onSubmit(false)"
                    [class.is-loading]="isSaving"
                >
                    <div class="content">
                        Zapisz
                    </div>
                    <div class="loader">
                        <div class="loader-wrapper">
                            <span class="icon loading"></span>
                        </div>
                    </div>
                </button>

                <button
                    mat-flat-button
                    color="primary"
                    class="primary-button with-loader"
                    [class.is-loading]="isConfirming"
                    [disabled]="!canConfirm()"
                    *ngIf="showConfirmButton"
                >
                    <div class="content">
                        Potwierdź
                    </div>
                    <div class="loader">
                        <span class="icon loading"></span>
                    </div>
                </button>
            </div>
        </div>

        <div class="form-errors">
            <mat-error *ngIf="formGroup.hasError('errorOccurred')">
                Wystąpił błąd, nie powiodło się zapisywanie
            </mat-error>
        </div>
    </form>
</div>

<div class="preview">
    <div class="preview-container">
        <div class="preview-page">
            <digica-document-image
                *ngIf="id"
                [documentId]="id"
                [originalWidth]="documentModel && documentModel.width || 2481"
                [originalHeight]="documentModel && documentModel.height || 3508"
            ></digica-document-image>

            <div class="feature-page-switcher-container">
                <div
                    class="feature-page-switcher-box"
                    *ngIf="
                        interactiveImageService.featurePageSwitcher.value
                        && interactiveImageService.featurePageSwitcher.value.focusedFeatureReturnPage === null
                        && interactiveImageService.featurePageSwitcher.value.focusedFeaturePage !== null
                    "
                >
                    Wartość wykryta na stronie {{ interactiveImageService.featurePageSwitcher.value.focusedFeaturePage }}.
                    Naciśnij <div class="button">ALT</div> aby podświetlić.
                </div>
                <div
                    class="feature-page-switcher-box"
                    *ngIf="
                        interactiveImageService.featurePageSwitcher.value
                        && interactiveImageService.featurePageSwitcher.value.focusedFeatureReturnPage !== null
                    "
                >
                    Naciśnij <div class="button">ALT</div> aby powrócić na poprzednią stronę.
                </div>
            </div>
        </div>

        <div class="preview-menu">
            <digica-document-page-thumbnail-list
                *ngIf="id"
                [documentId]="id"
                [pagesCount]="documentModel && documentModel.numberOfPages || 1"
                (pageNavigation)="onPageNavigation($event)"
            ></digica-document-page-thumbnail-list>

            <div class="actions">
                <button
                    mat-stroked-button
                    color="primary"
                    class="show-attachments"
                    (click)="openAttachmentListDialog()"
                    [disabled]="!documentModel"
                >
                    Pokaż załączniki
                </button>
            </div>
        </div>
    </div>
</div>
