import { Injectable } from '@angular/core';
import { IParser } from '../iparser';

@Injectable({
    providedIn: 'root'
})
export class VatRateParserService implements IParser {

    constructor() { }

    public parse(value: string): string {

        if (/zw/i.test(value)) {
            return 'zw';
        }

        if (/np/i.test(value)) {
            return 'np';
        }

        if (/0/.test(value)) {
            return '0';
        }

        if (/5/.test(value)) {
            return '5';
        }

        if (/8/.test(value)) {
            return '8';
        }

        if (/23/.test(value)) {
            return '23';
        }

        return '';
    }
}
