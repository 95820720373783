<div class="container">
    <header class="logo-container">
        <img src="/assets/logos/digica_wText_horizontal.svg" alt="Digica">
    </header>

    <div class="auth-container">
        <form [formGroup]="form" (ngSubmit)="submit()">
            <div class="auth-box" [class.is-registered]="isRegisterd">
                <h1>Rejestracja</h1>

                <ng-container *ngIf="!isRegisterd">
                    <mat-accordion>
                        <mat-expansion-panel class="mat-elevation-z0" [expanded]="indexExpanded == 1" [hideToggle]="true" [disabled]="true">
                            <mat-expansion-panel-header (click)="changeToStep(1)">
                                <div class="step-number">1</div>
                                Twoje dane
                            </mat-expansion-panel-header>

                            <div class="step-container">
                                <div class="input-wrapper">
                                    <div class="label">
                                        Imię i nazwisko<span class="required"></span>
                                    </div>

                                    <mat-form-field floatLabel="never" class="custom">
                                        <input matInput formControlName="nickname" />
                                        <mat-error *ngIf="nickname.hasError('required')">
                                            Wprowadź imię i nazwisko
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="input-wrapper">
                                    <div class="label">
                                        E-mail<span class="required"></span>
                                    </div>

                                    <mat-form-field floatLabel="never" class="custom">
                                        <input matInput formControlName="email" />
                                        <mat-error *ngIf="email.hasError('required')">
                                            Wprowadź email
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="input-wrapper">
                                    <div class="label">
                                        Telefon
                                    </div>

                                    <mat-form-field floatLabel="never" class="custom">
                                        <input matInput formControlName="phone" />
                                        <mat-error *ngIf="phone.hasError('pattern')">
                                            Numer telefonu musi składać się z dokładnie 9 cyfr
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                
                                <button
                                    type="button"
                                    mat-stroked-button
                                    color="primary"
                                    class="next-step-button"
                                    (click)="changeToStep(2)"
                                >
                                    Dalej
                                    <span class="icon dropdown_small"></span>
                                </button>
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel class="mat-elevation-z0" [expanded]="indexExpanded == 2" [hideToggle]="true" [disabled]="true">
                            <mat-expansion-panel-header>
                                <div class="step-number">2</div>
                                Dane twojej organizacji
                            </mat-expansion-panel-header>

                            <div class="step-container">
                                <div class="input-wrapper">
                                    <div class="label">
                                        Nazwa organizacji<span class="required"></span>
                                    </div>

                                    <mat-form-field floatLabel="never" class="custom">
                                        <input
                                            matInput
                                            formControlName="organizationName"
                                        />

                                        <mat-error *ngIf="organizationName.hasError('required')">
                                            Podaj nazwę organizacji
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="input-wrapper">
                                    <div class="label">
                                        NIP organizacji<span class="required"></span>
                                    </div>

                                    <mat-form-field floatLabel="never" class="custom">
                                        <input
                                            matInput
                                            formControlName="taxNumber"
                                        />

                                        <mat-error *ngIf="taxNumber.hasError('required')">
                                            Podaj NIP organizacji
                                        </mat-error>
                                        <mat-error *ngIf="taxNumber.hasError('invalidTaxNumber')">
                                            Niepoprawny NIP
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="input-wrapper">
                                    <div class="label">
                                        Rodzaj działalności<span class="required"></span>
                                    </div>
                                    <mat-form-field floatLabel="never" class="custom">
                                        <mat-select formControlName="typeOfActivity">
                                            <mat-option value="company">Firma</mat-option>
                                            <mat-option value="accountingOffice">Biuro rachunkowe</mat-option>
                                            <mat-option value="other">Inne</mat-option>
                                        </mat-select>

                                        <mat-error *ngIf="typeOfActivity.hasError('required')">
                                            Podaj rodzaj działalności
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="input-wrapper">
                                    <div class="label">
                                        Liczba miesięcznie przetwarzanych dokumentów
                                    </div>
                                    <mat-form-field floatLabel="never" class="custom">
                                        <mat-select formControlName="monthlyProcessedDocuments">
                                            <mat-option value="1-100">1 - 100</mat-option>
                                            <mat-option value="100-800">100 - 800</mat-option>
                                            <mat-option value="800-3000">800 - 3000</mat-option>
                                            <mat-option value="3000+">3000+</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                
                                <button
                                    type="button"
                                    mat-stroked-button
                                    color="primary"
                                    class="next-step-button"
                                    (click)="changeToStep(3)"
                                >
                                    Dalej
                                    <span class="icon dropdown_small"></span>
                                </button>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel class="mat-elevation-z0"  [expanded]="indexExpanded == 3" [hideToggle]="true" [disabled]="true">
                            <mat-expansion-panel-header>
                                <div class="step-number">3</div>
                                Podsumowanie
                            </mat-expansion-panel-header>

                            <div class="step-container">
                                <div class="input-wrapper">
                                    <mat-checkbox color="primary" formControlName="marketingAgreement"
                                        >Wyrażam zgodę na przetwarzanie moich danych w celu
                                        kontaktu marketingowego przez Dahliamatic, zgodnie z
                                        polityką prywatności.<span class="required"></span>
                                    </mat-checkbox>

                                    <mat-error class="default">
                                        <span *ngIf="marketingAgreement.hasError('required')">
                                            Musisz wyrazić zgodę
                                        </span>
                                    </mat-error>
                                </div>

                                <div class="input-wrapper">
                                    <mat-error class="default">
                                        <span *ngIf="form.errors && form.errors.errorOccurred">
                                            Wystąpił błąd podczas rejestracji, spróbuj ponownie później
                                        </span>
                                    </mat-error>
                                </div>

                                <button
                                    type="submit"
                                    mat-flat-button
                                    color="primary"
                                    class="submit-button with-loader"
                                    [class.is-loading]="isSubmitting"
                                    [disabled]="this.form.invalid"
                                >
                                    <div class="content">
                                        Zarejestruj się
                                    </div>
                                    <div class="loader">
                                        <span class="icon loading"></span>
                                    </div>
                                </button>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </ng-container>
                <ng-container *ngIf="isRegisterd">
                    <div class="status">
                        <div class="icon-wrapper success">
                            <span class="icon success"></span>
                        </div>

                        <div class="text">
                            Zarejestrowano pomyślenie! Na podany adres e-mail został wysłany link aktywacyjny, możesz teraz zamknąć tę kartę.
                        </div>
                    </div>
                </ng-container>

                <div class="links">
                    <a [routerLink]="['/login']">Przejdź do logowania</a>
                </div>
            </div>
        </form>
    </div>
</div>
