import { Injectable } from '@angular/core';
import { CallService } from '../call-service/call.service';
import { Observable } from 'rxjs';
import { TrustedContactExtract } from 'src/app/contract/user/trusted-contact-extract';

@Injectable({
    providedIn: 'root'
})
export class TrustedContactService {
    private servicePrefix = 'trustedContacts';

    constructor(private callService: CallService) {
        this.callService = callService;
    }

    public GetTrustedContacts(): Observable<TrustedContactExtract[]> {
        return this.callService.get<TrustedContactExtract[]>(`${this.servicePrefix}`);
    }

    public DeleteTrustedContact(id: number) {
        return this.callService.delete(`${this.servicePrefix}/${id}`);
    }

    public AddTrustedContact(email: string) {
        return this.callService.postForm(`${this.servicePrefix}`, { email });
    }
}
