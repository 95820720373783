import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TaxNumberFormatterService {

    constructor() { }

    public formatInput($event: any): void {

        const input = $event.target as HTMLInputElement;
        input.value = this.format(input.value);
    }

    public format(value: string): string {

        value = value.toUpperCase().replace(/[^A-Z0-9]/g, '');
        return value;
    }

    public parse(value: string): string {

        value = value.toUpperCase().replace(/[^A-Z0-9]/g, '');
        return value;
    }

    public parseWithoutCountryCode(value: string): string {

        return value.toUpperCase().replace(/[^0-9]/g, '');
    }

    public getCountryCode(value: string): string {

        if (!value.charAt(0).match(/[a-z]/i) ||
            !value.charAt(1).match(/[a-z]/i)) {
            return null;
        }

        return value.substr(0, 2).toUpperCase();
    }
}
