<h2 mat-dialog-title>Usunąć dokument?</h2>
<mat-dialog-content>
    <ng-container *ngIf="data.documentNumbers.length === 1; else documentsList">
        <p>
            Czy na pewno chcesz usunąć

            <ng-container
                *ngIf="data.documentNumbers[0] !== null; else noTitle"
            >dokument z numerem <b>{{ data.documentNumbers[0] }}</b></ng-container>?
            <ng-template #noTitle>ten dokument</ng-template>
        </p>
    </ng-container>
    <ng-template #documentsList>
        <p>Czy na pewno chcesz usunąć dokumenty z następującymi numerami?</p>

        <div *ngFor="let title of data.documentNumbers">
            <ng-container *ngIf="title">
                {{ title }}
            </ng-container>
            <ng-container *ngIf="!title">
                Nie wykryto numeru
            </ng-container>
        </div>
    </ng-template>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button color="primary" [mat-dialog-close]="false" class="size-normal">Anuluj</button>
    <button mat-button color="primary" [mat-dialog-close]="true" class="size-normal">Usuń</button>
</mat-dialog-actions>
