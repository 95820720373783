<form [formGroup]="formGroup">
    <div class="inputs-container">
        <div class="vat-table">
            <div class="headers row">
                <div class="num">
                    <div class="label">&nbsp;</div>
                </div>
                <div class="net">
                    <div class="label">Wartość netto</div>
                </div>
                <div class="rate">
                    <div class="label">Stawka VAT</div>
                </div>
                <div class="vat">
                    <div class="label">VAT</div>
                </div>
                <div class="gross">
                    <div class="label">Wartość brutto</div>
                </div>
                <div class="row-actions"></div>
            </div>

            <ng-container
                formArrayName="vatRows"
                *ngFor="let vatRow of vatRows.controls; let i = index"
            >
                <div class="row" [formGroupName]="i">
                    <input matInput hidden formControlName="id" />
                    <div class="num">{{ i + 1 }}.</div>
                    <div class="net">
                        <mat-form-field floatLabel="never" class="custom">
                            <input
                                matInput
                                formControlName="net"
                                autocomplete="off"
                                (focus)="detailsService.onFocusChanged($event, [vatRowsFeatureName, i, 'net'], getVatRowControl(i, 'net'), amountParserService, amountFormatterService)"
                                (focusout)="
                                    amountFormatterService.formatInput($event);
                                    detailsService.onFocusChanged()
                                "
                            />
                            <span matSuffix>{{ detailsService.currencyValue | async }}</span>

                            <mat-error *ngIf="getVatRowControl(i, 'net').hasError('required')">
                                Wprowadź kwotę
                                <ng-template #errors>
                                    Nie wprowadzono kwoty netto w wierszu {{ i + 1 }}
                                </ng-template>
                            </mat-error>
                            <mat-error *ngIf="getVatRowControl(i, 'net').hasError('invalidAmount')">
                                Niepoprawna kwota
                                <ng-template #errors>
                                    Wprowadzono niepoprawną kwotę netto w wierszu {{ i + 1 }}
                                </ng-template>
                            </mat-error>
                        </mat-form-field>
                        <mat-error
                            class="default to-control"
                            *ngIf="
                                !getVatRowControl(i, 'net').hasError('required')
                                && !getVatRowControl(i, 'net').hasError('invalidAmount')
                                && vatRow.hasError('invalidNetValue')
                            "
                        >
                            Niepoprawna wartość
                            <ng-template #errors>
                                Wprowadzono niepoprawną wartość kwoty netto w wierszu {{ i + 1 }}
                            </ng-template>
                        </mat-error>
                    </div>
                    <div class="rate">
                        <mat-form-field floatLabel="never" class="custom">
                            <mat-select
                                matInput
                                formControlName="rate"
                                (focus)="detailsService.onFocusChanged($event, [vatRowsFeatureName, i, 'rate'], getVatRowControl(i, 'rate'), vatRateParserService)"
                                (focusout)="detailsService.onFocusChanged()"
                            >
                                <mat-option
                                    *ngFor="let rate of availableRates"
                                    [value]="rate.value"
                                >
                                    {{ rate.display }}
                                </mat-option>
                            </mat-select>

                            <mat-error *ngIf="getVatRowControl(i, 'rate').hasError('required')">
                                Wprowadź stawkę
                                <ng-template #errors>
                                    Nie wprowadzono stawki VAT w wierszu {{ i + 1 }}
                                </ng-template>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="vat">
                        <mat-form-field floatLabel="never" class="custom">
                            <input
                                matInput
                                formControlName="vat"
                                autocomplete="off"
                                (focus)="detailsService.onFocusChanged($event, [vatRowsFeatureName, i, 'vat'], getVatRowControl(i, 'vat'), amountParserService, amountFormatterService)"
                                (focusout)="
                                    amountFormatterService.formatInput($event);
                                    detailsService.onFocusChanged()
                                "
                            />
                            <span matSuffix>{{ detailsService.currencyValue | async }}</span>

                            <mat-error *ngIf="getVatRowControl(i, 'vat').hasError('required')">
                                Wprowadź kwotę
                                <ng-template #errors>
                                    Nie wprowadzono kwoty VAT w wierszu {{ i + 1 }}
                                </ng-template>
                            </mat-error>
                            <mat-error *ngIf="getVatRowControl(i, 'vat').hasError('invalidAmount')">
                                Niepoprawna kwota
                                <ng-template #errors>
                                    Wprowadzono niepoprawną kwotę VAT w wierszu {{ i + 1 }}
                                </ng-template>
                            </mat-error>
                        </mat-form-field>
                        <mat-error
                            class="default to-control"
                            *ngIf="
                                !getVatRowControl(i, 'vat').hasError('required')
                                && !getVatRowControl(i, 'vat').hasError('invalidAmount')
                                && vatRow.hasError('invalidVatValue')
                            "
                        >
                            Niepoprawna wartość
                            <ng-template #errors>
                                Wprowadzono niepoprawną wartość kwoty VAT w wierszu {{ i + 1 }}
                            </ng-template>
                        </mat-error>
                    </div>
                    <div class="gross">
                        <mat-form-field floatLabel="never" class="custom">
                            <input
                                matInput
                                formControlName="gross"
                                autocomplete="off"
                                (focus)="detailsService.onFocusChanged($event, [vatRowsFeatureName, i, 'gross'], getVatRowControl(i, 'gross'), amountParserService, amountFormatterService)"
                                (focusout)="
                                    amountFormatterService.formatInput($event);
                                    detailsService.onFocusChanged()
                                "
                            />
                            <span matSuffix>{{ detailsService.currencyValue | async }}</span>

                            <mat-error *ngIf="getVatRowControl(i, 'gross').hasError('required')">
                                Wprowadź kwotę
                                <ng-template #errors>
                                    Nie wprowadzono kwoty brutto w wierszu {{ i + 1 }}
                                </ng-template>
                            </mat-error>
                            <mat-error *ngIf="getVatRowControl(i, 'gross').hasError('invalidAmount')">
                                Niepoprawna kwota
                                <ng-template #errors>
                                    Wprowadzono niepoprawną kwotę brutto w wierszu {{ i + 1 }}
                                </ng-template>
                            </mat-error>
                        </mat-form-field>
                        <mat-error
                            class="default to-control"
                            *ngIf="
                                !getVatRowControl(i, 'gross').hasError('required')
                                && !getVatRowControl(i, 'gross').hasError('invalidAmount')
                                && vatRow.hasError('invalidGrossValue')
                            "
                        >
                            Niepoprawna wartość
                            <ng-template #errors>
                                Wprowadzono niepoprawną wartość kwoty brutto w wierszu {{ i + 1 }}
                            </ng-template>
                        </mat-error>
                    </div>
                    <div class="row-actions">
                        <button
                            mat-icon-button
                            (click)="removeVatRow(i)"
                            type="button"
                            *ngIf="isEditable"
                        >
                            <span class="icon remove-row"></span>
                        </button>
                    </div>
                </div>
            </ng-container>

            <div class="vat-table-actions">
                <button
                    mat-stroked-button
                    color="primary"
                    (click)="addVatRowControls()"
                    type="button"
                    *ngIf="isEditable"
                >
                    Dodaj wiersz
                </button>
            </div>

            <mat-divider></mat-divider>

            <div class="row">
                <div class="num sum">
                    <span
                        *ngIf="!isEditable || areAmountsSameAsExpected()"
                    >
                        &sum;
                    </span>

                    <button
                        *ngIf="isEditable && !areAmountsSameAsExpected()"
                        mat-stroked-button
                        color="primary"
                        class="auto-fill-summary"
                        (click)="fillAmountSummary()"
                        matTooltip="Automatycznie uzupełnij podsumowanie"
                    >
                        &sum;
                    </button>
                </div>

                <div class="net">
                    <mat-form-field floatLabel="never" class="custom">
                        <input
                            matInput
                            formControlName="amountNet"
                            autocomplete="off"
                            (focus)="detailsService.onFocusChanged($event, [amountNetFeatureName], amountNet, amountParserService, amountFormatterService)"
                            (focusout)="
                                amountFormatterService.formatInput($event);
                                detailsService.onFocusChanged()
                            "
                        />
                        <span matSuffix>{{ detailsService.currencyValue | async }}</span>

                        <mat-error *ngIf="amountNet.hasError('required')">
                            Wprowadź kwotę
                            <ng-template #errors>
                                Nie wprowadzono sumarycznej kwoty netto
                            </ng-template>
                        </mat-error>
                        <mat-error *ngIf="amountNet.hasError('invalidAmount')">
                            Niepoprawna kwota
                            <ng-template #errors>
                                Wprowadzono niepoprawną sumaryczną kwotę netto
                            </ng-template>
                        </mat-error>
                    </mat-form-field>
                    <mat-error
                        class="default to-control"
                        *ngIf="
                            amountNet.errors === null &&
                            formGroup.hasError('invalidNetSummary')
                        "
                    >
                        Wartość musi być sumą kwot
                        <ng-template #errors>
                            Sumaryczna wartość netto nie jest sumą kwot netto
                        </ng-template>
                    </mat-error>
                </div>
                <div class="rate">&nbsp;</div>
                <div class="vat">
                    <mat-form-field floatLabel="never" class="custom">
                        <input
                            matInput
                            formControlName="amountVat"
                            autocomplete="off"
                            (focus)="detailsService.onFocusChanged($event, [amountVatFeatureName], amountVat, amountParserService, amountFormatterService)"
                            (focusout)="
                                amountFormatterService.formatInput($event);
                                detailsService.onFocusChanged()
                            "
                        />
                        <span matSuffix>{{ detailsService.currencyValue | async }}</span>

                        <mat-error *ngIf="amountVat.hasError('required')">
                            Wprowadź kwotę
                            <ng-template #errors>
                                Nie wprowadzono sumarycznej kwoty VAT
                            </ng-template>
                        </mat-error>
                        <mat-error *ngIf="amountVat.hasError('invalidAmount')">
                            Niepoprawna kwota
                            <ng-template #errors>
                                Wprowadzono niepoprawną sumaryczną kwotę VAT
                            </ng-template>
                        </mat-error>
                    </mat-form-field>
                    <mat-error
                        class="default to-control"
                        *ngIf="
                            amountVat.errors === null &&
                            formGroup.hasError('invalidVatSummary')
                        "
                    >
                        Wartość musi być sumą kwot
                        <ng-template #errors>
                            Sumaryczna wartość VAT nie jest sumą kwot VAT
                        </ng-template>
                    </mat-error>
                </div>
                <div class="gross">
                    <mat-form-field floatLabel="never" class="custom">
                        <input
                            matInput
                            formControlName="amountGross"
                            autocomplete="off"
                            (focus)="detailsService.onFocusChanged($event, [amountGrossFeatureName], amountGross, amountParserService, amountFormatterService)"
                            (focusout)="
                                amountFormatterService.formatInput($event);
                                detailsService.onFocusChanged()
                            "
                        />
                        <span matSuffix>{{ detailsService.currencyValue | async }}</span>

                        <mat-error *ngIf="amountGross.hasError('required')">
                            Wprowadź kwotę
                            <ng-template #errors>
                                Nie wprowadzono sumarycznej kwoty brutto
                            </ng-template>
                        </mat-error>
                        <mat-error
                            *ngIf="amountGross.hasError('invalidAmount')"
                        >
                            Niepoprawna kwota
                            <ng-template #errors>
                                Wprowadzono niepoprawną sumaryczną kwotę brutto
                            </ng-template>
                        </mat-error>
                    </mat-form-field>
                    <mat-error
                        class="default to-control"
                        *ngIf="
                            amountGross.errors === null &&
                            formGroup.hasError('invalidGrossSummary')
                        "
                    >
                        Wartość musi być sumą kwot
                        <ng-template #errors>
                            Sumaryczna wartość brutto nie jest sumą kwot brutto
                        </ng-template>
                    </mat-error>
                    <mat-error
                        class="default to-control"
                        *ngIf="
                            amountGross.errors === null &&
                            !formGroup.hasError('invalidGrossSummary') &&
                            formGroup.hasError('invalidTotalSummary')
                        "
                    >
                        Niepoprawna suma netto i vat
                        <ng-template #errors>
                            Sumaryczna wartość brutto nie jest sumą wartości netto i VAT
                        </ng-template>
                    </mat-error>
                </div>
                <div class="row-actions">&nbsp;</div>
            </div>
        </div>
    </div>
</form>
