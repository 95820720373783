import { Component, Input } from '@angular/core';
import { DocumentStatusEnum } from 'src/app/contract/document/document-status.enum';

@Component({
    selector: 'digica-document-status',
    templateUrl: './document-status.component.html',
    styleUrls: []
})
export class DocumentStatusComponent {

    @Input() documentStatus: DocumentStatusEnum;
    public DocumentStatusEnum = DocumentStatusEnum;

    constructor() { }
}
