<div class="zoomed-container" [ngStyle]="{ left: zoomState == 'zoomIn' ? '0': '100% ' }" (click)="zoomOut()">
    <div class="zoomed" [@zoom]="zoomState" [class.is-loading]="zoomPage.source === null">

        <div class="loading-container" digicaClickStopPropagation>
            <div class="loading-wrapper">
                <span class="icon loading"></span>
            </div>
        </div>

        <img *ngIf="zoomState == 'zoomIn'" [src]="thumbPage.source" alt="#{{ documentGroupId }}" class="small" digicaClickStopPropagation>
        <img *ngIf="zoomPage.source !== null" [src]="zoomPage.source" alt="#{{ documentGroupId }}" class="big" digicaClickStopPropagation>
    </div>
</div>
