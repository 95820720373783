import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'groupByDay' })
export class GroupByDayPipe implements PipeTransform {

    transform(collection: Array<any>, propertyName: string): Array<{ day: string, value: Array<any> }> {

        if (!collection) {
            return null;
        }

        const groupedCollection = collection.reduce((previous, current) => {
            const date = new Date(current[propertyName]);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);

            const dateString = date.toDateString();

            if (!previous[dateString]) {
                previous[dateString] = [current];
            } else {
                previous[dateString].push(current);
            }

            return previous;
        }, {});

        return Object.keys(groupedCollection).map(day => ({ day, value: groupedCollection[day] }));
    }
}
