import { LogLevel } from '@microsoft/signalr';

export const environment = {
    production: true,
    apiUrl: 'https://proxy.digica.dahliamatic.pl/api',
    defaultIbanCode: 'PL',
    applicationTabName: 'Digica',
    websocketLogLevel: LogLevel.Error,
    googleAnalyticsKey: 'UA-177057875-1'
};
