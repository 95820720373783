<section>
    <div class="description">
        Wprowadź adres email, z którego chcesz aby możliwe było przesyłanie dokumentów do systemu
    </div>

    <form [formGroup]="trustedContactForm" class="trusted-contact-form" #form="ngForm" (ngSubmit)="trustedContactSubmit()">
        <div class="label">Dodaj zaufany adres e-mail</div>
        <mat-form-field 
            floatLabel="never"
            class="custom"
            appearance="standard"
        >
            <input
                type="text"
                matInput
                placeholder="jan.kowalski@asseco.pl"
                formControlName="email"
            />
            <mat-error
                *ngIf="this.trustedContactForm.controls['email'].hasError('required')"
            >
                Wprowadź email
            </mat-error>
            <mat-error
                *ngIf="this.trustedContactForm.controls['email'].hasError('invalidEmail')"
            >
                Wprowadzony email jest niepoprawny
            </mat-error>
        </mat-form-field>

        <button
            mat-raised-button
            color="primary"
            type="submit"
            class="size-normal"
        >
            Dodaj
        </button>
    </form>
</section>

<table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let item">{{ item.email }}</td>
    </ng-container>

    <ng-container matColumnDef="addDateTime">
        <th mat-header-cell *matHeaderCellDef>Data dodania</th>
        <td mat-cell *matCellDef="let item">{{ item.addDateTime | date: 'dd MMMM yyyy' }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item">
            <button mat-icon-button color="warn" (click)="deleteTrustedContact(item.id)">
                <span class="icon remove-row"></span>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="trustedContactColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: trustedContactColumns"></tr>
</table>
