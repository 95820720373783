import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user-service/user.service';
import { filter, take } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ChangePasswordDialogComponent } from '../change-password-dialog/change-password-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
    selector: 'digica-settings-account',
    templateUrl: './settings-account.component.html',
    styleUrls: ['./settings-account.component.scss']
})
export class SettingsAccountComponent implements OnInit {

    public accountForm: FormGroup = new FormGroup({
        nickname: new FormControl('', [Validators.required]),
    });

    public isSaving = false;
    public isSaved = false;
    public isPasswordChanged = false;

    public get nickname() { return this.accountForm.get('nickname') as FormControl; }

    constructor(
        private userService: UserService,
        private dialog: MatDialog,
    ) { }

    public ngOnInit(): void {

        this.userService.userExtract
            .pipe(filter(x => x !== null), take(1))
            .subscribe(userExtract => {
                this.nickname.setValue(userExtract.nickname);
            });
    }

    public submit(): void {

        if (this.accountForm.invalid || this.isSaving) {
            return;
        }

        this.isSaving = true;
        this.isSaved = false;

        const userExtract = this.userService.userExtract.value;
        userExtract.nickname = this.nickname.value;

        this.userService.updateUserSettings(userExtract).subscribe(_ => {

            this.userService.userExtract.next(userExtract);
            this.isSaving = false;
            this.isSaved = true;

            setTimeout(() => { this.isSaved = false; }, 3000);
        });
    }

    public openChangePasswordDialog(): void {

        const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isPasswordChanged = true;
                setTimeout(() => { this.isPasswordChanged = false; }, 3000);
            }
        });
    }
}
