import { Component, OnInit } from '@angular/core';
import { OrganizationService } from 'src/app/services/organization-service/organization.service';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { UserExtract } from 'src/app/contract/user/user-extract';
import { UserRoleEnum } from 'src/app/contract/user/user-role.enum';
import { UserService } from 'src/app/services/user-service/user.service';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { filter, take, map, catchError, switchMap } from 'rxjs/operators';
import { AddOrganizationUserDialogComponent } from '../add-organization-user-dialog/add-organization-user-dialog.component';
import { MatDialog } from '@angular/material';
import { Observable, of, timer } from 'rxjs';

@Component({
    selector: 'digica-settings-organization',
    templateUrl: './settings-organization.component.html',
    styleUrls: ['./settings-organization.component.scss']
})
export class SettingsOrganizationComponent implements OnInit {

    public dataSource: MatTableDataSource<UserExtract> = new MatTableDataSource();
    public trustedContactColumns = ['email', 'name', 'role', 'actions'];

    public UserRoleEnum = UserRoleEnum;
    public userId = 0;
    public mailDomain = '';
    public organizationSuffix = '';
    public userMailPrefix = '';

    public formGroup: FormGroup = new FormGroup({
        emailSuffix: new FormControl('', [
            Validators.required,
            Validators.minLength(3),
            Validators.pattern('^[a-z]+$')
        ], [
            this.emailSuffixValidator()
        ]),
    });

    public get emailSuffix() { return this.formGroup.get('emailSuffix') as FormControl; }

    public isSaving = false;
    public isSaved = false;

    private organizationId: number;

    constructor(
        private organizationService: OrganizationService,
        private authService: AuthService,
        private userService: UserService,
        private dialog: MatDialog,
    ) { }

    public ngOnInit(): void {
        this.refreshOrganizationUsers();

        this.userService.userExtract
            .pipe(filter(x => x !== null), take(1))
            .subscribe(userExtract => {
                this.userId = userExtract.userId;

                this.mailDomain = userExtract.mailDomain;
                this.organizationSuffix = userExtract.organizationSuffix;
                this.userMailPrefix = userExtract.userMailPrefix;

                this.emailSuffix.setValue(this.organizationSuffix);
            });
    }

    private refreshOrganizationUsers(): void {
        const token = this.authService.GetDecodedToken();
        if (token === null) {
            return;
        }

        this.organizationId = token.OrganizationId;
        this.organizationService.GetOrganizationUsers(this.organizationId).subscribe(data => {
            this.dataSource.data = data;
        });
    }

    public changeUserRole(userId: number, role: UserRoleEnum): void {

        this.userService.SetUserRole(userId, role).subscribe(
            _ => {
                const user = this.dataSource.data.find(x => x.userId === userId);
                user.role = role;
            }
        );
    }

    public deleteUser(userId: number): void {
        this.organizationService.DeleteOrganizationUser(this.organizationId, userId).subscribe(_ => {
            this.refreshOrganizationUsers();
        });
    }

    public openAddOrganizationUserDialog(): void {

        const dialogRef = this.dialog.open(AddOrganizationUserDialogComponent, {
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.refreshOrganizationUsers();
            }
        });
    }

    public submit(): void {

        if (this.formGroup.invalid || this.isSaving) {
            return;
        }

        this.isSaving = true;
        this.isSaved = false;

        const emailSuffix = this.emailSuffix.value;
        this.organizationService.ChangeOrganizationEmailSuffix(this.organizationId, emailSuffix).subscribe(_ => {

            this.isSaving = false;
            this.isSaved = true;
            this.userService.reloadUserExtract();

            setTimeout(() => { this.isSaved = false; }, 3000);
        });
    }

    public emailSuffixValidator(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            return timer(500).pipe(switchMap(() => {
                return this.organizationService.CheckIfOrganizationSuffixIsAvailable(control.value).pipe(
                    map(available => available ? null : { emailSuffixTaken: true }),
                    catchError(_ => of({ errorOccurred: true })),
                );
            }));
        };
    }
}
