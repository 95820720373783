import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../services/auth-service/auth.service';

@Injectable({ providedIn: 'root' })
export class LoggedInGuard implements CanActivate {

    constructor(
        private authService: AuthService,
    ) { }

    canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.isAuthenticated()) {
            return true;
        }

        this.authService.redirectToLogin(state.url);
    }
}
