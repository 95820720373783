import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'daysAgo',
    pure: true
})
export class DaysAgoPipe implements PipeTransform {

    transform(value: any, args?: any): any {

        if (!value) {
            return value;
        }

        const timePassed = new Date().getTime() - new Date(value).getTime();
        const daysPassed = Math.floor(timePassed / 86400000);

        if (daysPassed === 0) {
            return 'dziś';
        } else if (daysPassed === 1) {
            return 'wczoraj';
        }

        return `${daysPassed} dni temu`;
    }
}
