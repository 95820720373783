<div class="document-list-container">
    <div class="label">
        Połączone dokumenty
    </div>

    <div class="list-section">
        <div *ngIf="documentConnections.length === 0" class="status-container">
            Ten dokument nie jest połączony z innymi dokumentami.
        </div>

        <ul *ngIf="documentConnections.length > 0">
            <li *ngFor="let documentConnection of documentConnections; let i = index" class="row">
                <ng-template #documentLink>
                    <a color="primary" target="_blank" [routerLink]="['/archive', documentConnection.documentId]"
                    >{{ documentConnection.documentNumber }}</a>
                </ng-template>

                <ng-container *ngIf="documentConnection.documentType === documentModel.documentType; else differentDocumentType">
                    <ng-container *ngIf="documentConnection.isDuplicate">
                        Dokument posiada duplikat o numerze <ng-container *ngTemplateOutlet="documentLink"></ng-container>.
                    </ng-container>
                    <ng-container *ngIf="!documentConnection.isDuplicate">
                        Ten dokument stanowi duplikat dla dokumentu <ng-container *ngTemplateOutlet="documentLink"></ng-container>.
                    </ng-container>
                </ng-container>

                <ng-template #differentDocumentType>
                    <ng-container [ngSwitch]="documentConnection.documentType">
                        <ng-container *ngSwitchCase="DocumentTypeEnum.InvoiceCorrection">
                            Ten doukment posiada korektę o numerze <ng-container *ngTemplateOutlet="documentLink"></ng-container>.
                        </ng-container>
                        <ng-container *ngSwitchCase="DocumentTypeEnum.PurchaseInvoice">
                            Ten doukment stanowi korektę do faktury <ng-container *ngTemplateOutlet="documentLink"></ng-container>.
                        </ng-container>
                    </ng-container>
                </ng-template>

                <button
                    *ngIf="isEditable"
                    mat-icon-button
                    (click)="removeConnection(i)"
                    type="button"
                    matTooltip="Usuń połączenie"
                >
                    <span class="icon close"></span>
                </button>
            </li>
        </ul>

        <div class="list-actions" *ngIf="!documentModel || isEditable">
            <div
                class="button-wrapper"
                matTooltip="Wprowadź NIP kontrahenta aby połączyć inne dokumenty"
                [matTooltipDisabled]="!isCreateNewConnectionDisabled()"
            >
                <button
                    mat-stroked-button
                    color="primary"
                    (click)="createNewConnection()"
                    type="button"
                    [disabled]="isCreateNewConnectionDisabled()"
                >
                    Stwórz nowe połączenie
                </button>
            </div>
        </div>
    </div>
</div>
