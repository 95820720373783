<div class="pages-list">
    <div class="label">Strony dokumentu</div>

    <div class="pages-container white-scrollbar">
        <div
            class="page"
            *ngFor="let page of pagesArray"
            [class.active]="page.num === (currentPage | async)"
            [class.loaded]="page.isLoaded"
            (click)="pageClicked(page.num)"
        >
            <div class="page-sizer">
                <img
                    *ngIf="page.source !== null"
                    class="page-preview page-container"
                    [src]="page.source"
                    alt="Strona {{ page.num }}"
                    (load)="page.isLoaded = true"
                />

                <div class="page-loading page-container">
                    <div class="loading-wrapper">
                        <span class="icon loading"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
