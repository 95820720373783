<div #canvasContainer class="canvas-container">
    <div class="canvas-sizer">
        <canvas #canvas class="canvas"></canvas>

        <div class="loading-container" *ngIf="isLoading">
            <div class="loading-wrapper">
                <span class="icon loading"></span>
            </div>
        </div>
    </div>
</div>

<img [attr.src]="source" #image (load)="onImageLoad()">
