import { filter } from 'rxjs/operators';
import { Component, HostListener, OnInit, ViewChild, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ScanDocumentMarkerTypeEnum } from './scan-document-marker-type.enum';
import { ScanDocumentMarker } from './scan-document-marker';
import { FormControl } from '@angular/forms';
import { SatDatepicker } from 'saturn-datepicker';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ImageService } from 'src/app/services/image-service/image.service';
import { SplitAttachmentZoomComponent } from '../sp-attachment-zoom/sp-attachment-zoom.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { DocumentGroupService } from 'src/app/services/document-group-service/document-group.service';
import { DocumentDeleteDialogComponent } from 'src/app/documents/document-delete-dialog/document-delete-dialog.component';
import { DocumentDeleteDialogData } from 'src/app/documents/document-delete-dialog/document-delete-dialog.data';
import { DocumentGroupChild } from 'src/app/document-group/models/document-group-child.model';
import { DocToAssign } from 'src/app/shared/models/docToAssign';
import { DocumentService } from 'src/app/services/document-service/document.service';
import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y';

export enum KEY_CODE {
    UP_ARROW = 'ArrowUp',
    DOWN_ARROW = 'ArrowDown',
    RIGHT_ARROW = 'ArrowRight',
    LEFT_ARROW = 'ArrowLeft',
    SPACE = ' ',
    BACKSPACE = 'Backspace',
    ENTER = 'Enter',
    ESCAPE = 'Escape',
}

export enum IE_KEY_CODE {
    UP_ARROW = 'Up',
    DOWN_ARROW = 'Down',
    RIGHT_ARROW = 'Right',
    LEFT_ARROW = 'Left',
    SPACE = 'Spacebar',
    ESCAPE = 'Esc',
}

@Component({
    templateUrl: './sp-attachment-dialog.component.html',
    styleUrls: ['./sp-attachment-dialog.component.scss'],
})
export class SplitAttachmentDialogComponent implements OnInit, OnDestroy {

    public pagesCount = 1;
    public currentPage = 1;
    public currentId = 0;
    public saved = false;
    public invoiceCount = 0;
    public isLoading = false;
    public docsArray: Array<DocToAssign>;
    public documentsToDisplay: Array<number>;
    public redirectPath = ['/sp-attachment'];

    public pagesContainerOffset: number;
    public pagesNavigationOffset: number;
    private shouldCallTurnOffEdit = false;
    private turnOnEditSubscription: Subscription;
    private refreshEditStatusInterval: NodeJS.Timer;

    public markers = new Array<ScanDocumentMarker>();
    public ScanDocumentMarkerTypeEnum = ScanDocumentMarkerTypeEnum;

    @ViewChild(SplitAttachmentZoomComponent, { static: true }) zoomComponent: SplitAttachmentZoomComponent;
    @ViewChild(SatDatepicker) picker: SatDatepicker<Date>;
    public receiptDate = new FormControl(moment().format('L'));
    public selectedReceiptDate = new Date();
    public isConfirming = false;
    public isSearchingNext = false;
    public isError = false;

    constructor(
        private router: Router,
        private imageService: ImageService,
        private dialog: MatDialog,
        private titleService: Title,
        private documentService: DocumentService,
        private documentGroupService: DocumentGroupService,
        public dialogRef: MatDialogRef<SplitAttachmentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public id: number,
    ) { }

    public ngOnInit(): void {

        this.titleService.setTitle(`Przypisywanie załączników | ${environment.applicationTabName}`);

        this.clearSubscriptions();
        this.resetSplitData();

        this.enterEditMode();
        this.loadDocumentGroup();
        this.generateDocsArray();
        this.setCurrentPage(1);
    }

    private generateDocsArray(): void {

        this.docsArray = [...Array(this.pagesCount).keys()].map(i => ({
            num: i + 1,
            id: this.documentsToDisplay[i],
            isLoaded: false,
            source: null,
            subscription: null,
        }));
    }

    private resetSplitData(): void {

        this.pagesCount = 1;
        this.currentPage = 1;
        this.currentId = 0;
        this.zoomComponent.zoomOut();
        this.saved = false;
        this.isLoading = false;
        this.shouldCallTurnOffEdit = false;
        this.markers = [];
    }

    public ngOnDestroy(): void {
        this.clearSubscriptions();
    }

    private clearSubscriptions() {

        if (this.refreshEditStatusInterval) {
            clearInterval(this.refreshEditStatusInterval);
        }

        if (this.turnOnEditSubscription) {
            this.turnOnEditSubscription.unsubscribe();
        }

        if (this.shouldCallTurnOffEdit) {
            this.documentGroupService.turnOffEdit(this.id).subscribe();
        }

        if (this.docsArray) {
            for (const doc of this.docsArray) {

                if (doc.subscription) {
                    doc.subscription.unsubscribe();
                }
            }
        }
    }

    private getDocumentIds(documentChildren: Array<DocumentGroupChild>): Array<number> {
        const ids = new Array<number>();
        for (const document of documentChildren) {
            ids.push(document.id);
        }
        return ids;
    }

    public loadDocumentGroup(): void {

        this.isLoading = true;

        this.documentGroupService.getDocumentGroup(this.id).subscribe(
            documentGroup => {
                this.documentsToDisplay = this.getDocumentIds(documentGroup.children);
                this.pagesCount = this.documentsToDisplay.length;
                this.generateDocsArray();
                this.setCurrentPage(1);

                if (documentGroup.receiptDate) {
                    this.receiptDate.setValue(moment(documentGroup.receiptDate).format('L'));
                    this.selectedReceiptDate = moment(documentGroup.receiptDate).toDate();
                }

                this.isLoading = false;
            }
        );
    }

    @HostListener('window:keyup', ['$event'])
    public keyboardEvent(event: KeyboardEvent) {

        if (this.isLoading) {
            return;
        }

        const target = event.target as HTMLElement;
        if (target.nodeName === 'INPUT' || target.nodeName === 'SELECT') {
            return;
        }

        switch (event.key) {
            case KEY_CODE.UP_ARROW:
            case IE_KEY_CODE.UP_ARROW:
                this.markCurrentPage(ScanDocumentMarkerTypeEnum.DOCUMENT);
                break;

            case KEY_CODE.DOWN_ARROW:
            case IE_KEY_CODE.DOWN_ARROW:
                this.markCurrentPage(ScanDocumentMarkerTypeEnum.ATTACHMENT);
                break;

            case KEY_CODE.BACKSPACE:
                this.markCurrentPage(ScanDocumentMarkerTypeEnum.SKIP);
                break;

            case KEY_CODE.RIGHT_ARROW:
            case IE_KEY_CODE.RIGHT_ARROW:
                this.setCurrentPage(this.currentPage + 1);
                break;

            case KEY_CODE.LEFT_ARROW:
            case IE_KEY_CODE.LEFT_ARROW:
                this.setCurrentPage(this.currentPage - 1);
                break;

            case KEY_CODE.SPACE:
            case IE_KEY_CODE.SPACE:
                const doc = this.docsArray.find(x => x.num === this.currentPage);
                this.zoomComponent.toggleZoom(doc);
                break;

            case KEY_CODE.ENTER:
                if (!this.saved) {
                    this.confirm();
                }

                break;

            case KEY_CODE.ESCAPE:
            case IE_KEY_CODE.ESCAPE:
                if (this.saved) {
                    this.navigateToList();
                }
                break;

            default:
                break;
        }

    }

    @HostListener('window:keydown', ['$event'])
    public keyDownEvent(event: KeyboardEvent) {

        switch (event.key) {

            case KEY_CODE.BACKSPACE:

                const target = event.target as HTMLElement;
                if (target.nodeName !== 'INPUT' && target.nodeName !== 'SELECT') {
                    event.preventDefault();
                }
                break;

            default:
                break;
        }
    }

    public confirm(): void {

        if (this.currentPage !== this.pagesCount + 1 || this.isConfirming) {
            return;
        }

        const markers = this.markers.sort((a, b) => a.page - b.page);
        const receiptDate = moment(this.receiptDate.value, 'L').toDate();

        this.isError = false;
        this.isConfirming = true;

        this.documentGroupService.setDocumentMarkers(this.id, receiptDate, markers).subscribe(
            _ => {
                this.isConfirming = false;
                this.saved = true;
                this.setCurrentPage(this.pagesCount + 1);
            },
            _ => {
                this.isConfirming = false;
                this.isError = true;
            }
        );
    }

    public getDocumentNumber(page: number): number {
        const marker = this.markers.find(x => x.page === page);
        return marker ? marker.attachedTo : null;
    }

    private decrementDocumentMarkers(documentMarker: number) {

        this.markers = this.markers.filter(x => x.attachedTo !== documentMarker);

        for (const marker of this.markers) {
            if (marker.attachedTo > documentMarker) {
                marker.attachedTo--;
            }
        }


    }

    public markCurrentPage(markerType: ScanDocumentMarkerTypeEnum) {

        if (this.saved || this.currentPage > this.pagesCount) {
            return;
        }

        const pageMarker = this.markers.find(x => x.page === this.currentPage);
        if (!pageMarker) {

            const marker = new ScanDocumentMarker();
            marker.documentId = this.currentId;
            marker.page = this.currentPage;
            marker.type = markerType;
            if (marker.type === ScanDocumentMarkerTypeEnum.ATTACHMENT) {
                marker.attachedTo = 1;
            } else if (marker.type === ScanDocumentMarkerTypeEnum.DOCUMENT) {
                this.invoiceCount++;
                marker.attachedTo = this.invoiceCount;
            }
            this.markers.push(marker);

        } else if (pageMarker.type === markerType) {

            if (pageMarker.type === ScanDocumentMarkerTypeEnum.ATTACHMENT && pageMarker.attachedTo < this.invoiceCount) {
                pageMarker.attachedTo++;
            } else if (pageMarker.type === ScanDocumentMarkerTypeEnum.DOCUMENT) {
                this.invoiceCount--;
                this.markers = this.markers.filter(x => x.page !== this.currentPage);
                this.decrementDocumentMarkers(pageMarker.attachedTo);
            } else {
                this.markers = this.markers.filter(x => x.page !== this.currentPage);
            }

        } else {

            this.markers = this.markers.filter(x => x.page !== this.currentPage);

            if (pageMarker.type === ScanDocumentMarkerTypeEnum.DOCUMENT) {
                this.decrementDocumentMarkers(pageMarker.attachedTo);
                this.invoiceCount--;
            }

            const marker = new ScanDocumentMarker();
            marker.documentId = this.currentId;
            marker.page = this.currentPage;
            marker.type = markerType;
            if (marker.type === ScanDocumentMarkerTypeEnum.ATTACHMENT) {
                marker.attachedTo = 1;
            } else if (marker.type === ScanDocumentMarkerTypeEnum.DOCUMENT) {
                this.invoiceCount++;
                marker.attachedTo = this.invoiceCount;
            }
            this.markers.push(marker);

        }

    }

    public setCurrentPage(doc: number) {

        if (doc < 1 || doc > this.pagesCount + 1 || this.saved) {
            return;
        }

        this.currentPage = doc;
        this.currentId = this.getDocumentIdByPage(this.currentPage);
        this.zoomComponent.zoomOut();
        this.updateOffsets();

        const startPage = Math.max(doc - 2, 1);
        const endPage = Math.min(doc + 2, this.pagesCount);

        for (let i = startPage; i <= endPage; i += 1) {
            const docObject = this.docsArray[i - 1];
            this.loadPagePreview(docObject);
        }
    }

    private updateOffsets(): void {

        const pageWidth = window.innerWidth / 4;
        this.pagesContainerOffset = (pageWidth * 3 / 2) - ((this.currentPage - 1) * pageWidth);

        const navigationWidth = 47 + 8;
        this.pagesNavigationOffset = (window.innerWidth / 2) - (navigationWidth / 2) - ((this.currentPage - 1) * navigationWidth);
    }

    public getPageMarkerType(page: number): ScanDocumentMarkerTypeEnum {

        const marker = this.markers.find(x => x.page === page);
        return marker ? marker.type : null;
    }

    public getPageTransform(page: number): number {

        const marker = this.getPageMarkerType(page);
        if (marker === null) {
            return 0;
        }

        if (marker === ScanDocumentMarkerTypeEnum.DOCUMENT) {
            return -16;
        } else if (marker === ScanDocumentMarkerTypeEnum.ATTACHMENT) {
            return 16;
        }

        return 0;
    }

    public navigateToList(): void {
        this.dialogRef.close(false);
    }

    public onReceiptDateSelect(date: moment.Moment): void {
        this.receiptDate.setValue(date.format('L'));
        this.selectedReceiptDate = new Date(date.toDate());
    }

    public getMarkersError(): string {

        const markers = this.markers.filter(x => x.type === ScanDocumentMarkerTypeEnum.DOCUMENT).length;
        if (markers === 0) {
            return 'Musisz oznaczyć minimum jeden dokument';
        }

        for (let page = 1; page < this.pagesCount; page++) {
            const marker = this.markers.find(x => x.page === page);
            if (!marker) {
                return `Strona ${page} nie została oznaczona`;
            }

            if (marker.type === ScanDocumentMarkerTypeEnum.DOCUMENT || marker.type === ScanDocumentMarkerTypeEnum.ATTACHMENT) {
                break;
            }
        }

        return null;
    }

    @HostListener('window:resize')
    public onResize() {
        this.updateOffsets();
    }

    private enterEditMode(): void {

        if (this.refreshEditStatusInterval) {
            clearInterval(this.refreshEditStatusInterval);
        }

        this.tryTurnOnEdit();
        this.refreshEditStatusInterval = setInterval(
            () => this.tryTurnOnEdit(), 60000
        );
    }

    private tryTurnOnEdit(): void {

        this.shouldCallTurnOffEdit = true;
        this.turnOnEditSubscription = this.documentGroupService.turnOnEdit(this.id).subscribe(
            _ => { },
            error => {
                if (error.status === 409) {
                    this.shouldCallTurnOffEdit = false;
                    this.dialogRef.close(false);
                }
            }
        );
    }

    private getDocumentIdByPage(page: number): number {
        const docId = this.documentsToDisplay[page - 1];
        return docId;
    }

    public loadPagePreview(
        doc: { num: number, id: number, isLoaded: boolean, source: string | ArrayBuffer, subscription: Subscription }
    ): void {

        if (doc.isLoaded || doc.subscription !== null) {
            return;
        }

        const url = this.documentService.GetDocumentPageImageUrl(doc.id, 1, 'small');
        doc.subscription = this.imageService.getImageSource(url, result => {
            doc.source = result;
        });
    }

    public goToLastPage(): void {
        this.setCurrentPage(this.pagesCount);
    }

    public goToFirstPage(): void {
        this.setCurrentPage(1);
    }

    public deleteDocumentGroup(): void {

        const data: DocumentDeleteDialogData = { documentNumbers: [null] };
        const dialogRef = this.dialog.open(DocumentDeleteDialogComponent, { data, autoFocus: false });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.documentGroupService.delete([this.id]).subscribe(_ => {
                    this.dialogRef.close(false);
                });
            }
        });
    }
}
