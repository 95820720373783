import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ImageService {

    constructor(
        private http: HttpClient,
    ) { }

    public getImageSource(url: string, onSourceLoaded: (result: string | ArrayBuffer) => void): Subscription {

        return this.http.get(url, { responseType: 'blob' }).subscribe(response => {
            const reader = new FileReader();
            reader.readAsDataURL(response);
            reader.onloadend = () => {
                onSourceLoaded(reader.result);
            };
        });
    }
}
