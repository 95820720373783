import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentConfirmDialogData } from './document-confirm-dialog.data';

@Component({
    templateUrl: './document-confirm-dialog.component.html',
    styleUrls: ['./document-confirm-dialog.component.scss']
})
export class DocumentConfirmDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<DocumentConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DocumentConfirmDialogData,
    ) { }
}
