<div class="document-tabular-container">
    <div class="label">
        Potencjalne zdublowane dokumenty
        <span
            class="icon information"
            matTooltip="Duble to zarchiwizione dokumenty, których zestaw cech: numer i typ dokumentu oraz numer NIP kontrahenta jest dokładnie taki sam jak na obecnej fakturze."
        ></span>
    </div>

    <div class="table-section">
        <div class="loading-container" *ngIf="isLoading">
            <div class="loading-wrapper">
                <span class="icon loading"></span>
            </div>
        </div>

        <div *ngIf="!hasLoaded" class="status-container not-loaded">
            <span class="icon status locked"></span>
            Weryfikacja dubli nastąpi po załadowaniu danych dokumentu
        </div>

        <div *ngIf="hasLoaded && isError" class="status-container error">
            <span class="icon status error"></span>
            Wystąpił błąd podczas weryfikacji dubli, spróbuj ponownie później
        </div>

        <div *ngIf="hasLoaded && similarDocuments.length === 0 && !isError" class="status-container success">
            <span class="icon status success"></span>
            Nie znaleziono dubli
        </div>

        <div *ngIf="similarDocuments.length > 0" class="table">
            <div class="head non-sticky">
                <div class="row">
                    <div class="cell label documentNumber">Numer dokumentu</div>
                    <div class="cell label uploadDatetime">Data dodania</div>
                    <div class="cell label uploaderNickname">Dodane przez</div>
                    <div class="cell label actions"></div>
                </div>
            </div>
            <div class="body">
                <div *ngFor="let similarDocument of similarDocuments; trackBy: similarDocumentTrackBy" class="row">
                    <div class="cell documentNumber">{{ similarDocument.documentNumber }}</div>
                    <div class="cell uploadDatetime">{{ similarDocument.uploadDatetime | date: 'short' }}</div>
                    <div class="cell uploaderNickname">
                        <div class="nickname">
                            <div class="ellipsis" [matTooltip]="similarDocument.uploaderNickname">
                                <span>{{ similarDocument.uploaderNickname }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="cell actions">
                        <a color="primary" target="_blank" [routerLink]="['/archive', similarDocument.documentId]">
                            Otwórz w nowej karcie
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>