import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from 'src/app/services/user-service/user.service';
import { filter, take } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public form: FormGroup = new FormGroup({
        email: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
    });
    public isSubmitting = false;

    public get email(): FormControl {
        return this.form.get('email') as FormControl;
    }

    public get password(): FormControl {
        return this.form.get('password') as FormControl;
    }

    constructor(
        private authService: AuthService,
        private userService: UserService,
        private titleService: Title,
    ) { }

    public ngOnInit(): void {

        this.titleService.setTitle(`Logowanie | ${environment.applicationTabName}`);
    }

    submit() {
        if (this.form.valid) {

            this.isSubmitting = true;
            this.authService.logIn(this.form.controls.email.value, this.form.controls.password.value).subscribe(
                _ => {
                    this.isSubmitting = false;
                    this.userService.reloadUserExtract();

                    this.userService.userExtract.pipe(
                        filter(x => x != null),
                        take(1)
                    ).subscribe(userExtract => {
                        this.authService.redirectAfterLogin(userExtract);
                    });
                },
                (error: HttpErrorResponse) => {

                    this.isSubmitting = false;
                    if (error.status === 0) {
                        this.form.setErrors({ apiUnreachable: true });
                    } else if (error.status === 401) {
                        this.form.setErrors({ invalidEmailOrPassword: true });
                    }
                }
            );
        }
    }
}
