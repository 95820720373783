<div class="container">
    <div class="background">
        <div class="section-header no-margin">
            Wprowadzanie dokumentów
        </div>
    </div>
</div>

<div class="container">
    <section>
        <div class="emails-container">
            <div class="note">
                <div class="icon-wrapper">
                    ℹ
                </div>
                <div class="content">
                    <div class="header">Pamiętaj,</div>
                    <div class="text">przed wysłaniem e-maila konieczne jest dodanie własnego adresu w sekcji <strong>Zaufane adresy e-mail</strong></div>
                </div>
            </div>
    
            <div class="setting-wrapper">
                <div class="setting-input">
                    <div class="label">Adres e-mail do dokumentów</div>
                    <mat-form-field
                        floatLabel="never"
                        class="custom"
                        appearance="standard"
                        (click)="copyToClipboard(documentEmailInput)"
                    >
                        <input
                            matInput
                            [value]="documentEmail"
                            #documentEmailInput
                        />
                    </mat-form-field>
                </div>
            </div>
    
            <div class="setting-wrapper">
                <div class="setting-input">
                    <div class="label">Adres e-mail do e-faktur</div>
                    <mat-form-field
                        floatLabel="never"
                        class="custom"
                        appearance="standard"
                        (click)="copyToClipboard(eInvoiceEmailInput)"
                    >
                        <input
                            matInput
                            [value]="eInvoiceEmail"
                            #eInvoiceEmailInput
                        />
                    </mat-form-field>
                </div>
            </div>
        </div>
    </section>
    
    <ng-template #emailCopiedTemplate>
        📩 Adres email został skopiowany do schowka 😁
    </ng-template>
</div>

<div class="container">
    <div class="background">
        <div class="section-header">Zaufane adresy e-mail</div>
    </div>
</div>

<div class="container">
    <digica-settings-trusted-contacts></digica-settings-trusted-contacts>
</div>