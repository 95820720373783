import { Injectable } from '@angular/core';
import { CallService } from '../call-service/call.service';
import { Observable } from 'rxjs';
import { Contractor } from 'src/app/contract/contractor/contractor.model';

@Injectable({
    providedIn: 'root'
})
export class ContractorService {
    private servicePrefix = 'contractor';

    constructor(
        private callService: CallService,
    ) { }

    public GetContractorData(nip: string): Observable<Contractor> {
        return this.callService.get<Contractor>(`${this.servicePrefix}/nip/${nip}`);
    }
}
